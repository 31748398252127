
<mat-grid-list cols="6" rowHeight="12%" >

    <mat-grid-tile [colspan]="4" [rowspan]="1">

        <div style="padding: 50px; width: 100%;">

            <span class="span0"> Datahub. </span> <span class="span1"> Der einfache Weg um Daten zu visualisieren. </span>

        </div>

    </mat-grid-tile>

 

    <mat-grid-tile [colspan]="3" [rowspan]="4" >
        <div class="action-items">
          
            <div class="datahub-tile">
                <div>
                    <span> DATAHUB. </span>
                </div>

            </div>
          

            <div class="datahub-items">
                <div>
                <span class="span2"> blb Lorem Ipsum has been the industryown printer</span>
                </div>

                <div class="datahub-row">
                    <div class="datahub-tile2">
                        <div>
                            <span class="span2"> Synthetische Population. </span>
                        </div>
                    </div>
                    <div class="datahub-tile2">
                        <div>
                            <span class="span2"> Regionalindikatoren </span> <br>
                            <span class="span2">nach Gebietseinheiten. </span> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-grid-tile>

    <mat-grid-tile [colspan]="1" [rowspan]="8">

        <div style="width: 100%; height: 100%;">
            <div class="bilder-tile" >
                <div>
                    <span class="span2"> Bildergalerie. </span>
                </div>
            </div>
        </div>
    </mat-grid-tile>

    <mat-grid-tile [colspan]="3" [rowspan]="1">
        <br>
        <br>

        <div class="text-tile" >
            <div>
                <span class="span2"> text. </span>
            </div>
        </div>
 
    </mat-grid-tile>


</mat-grid-list>
