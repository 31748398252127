<div class="main">
  <div class="card-container">
      <mat-card class="example-card">
          <mat-card-header>
            <mat-card-title>Indikatoren</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <p>Wählen Sie ein Indikator aus:</p>

              <mat-radio-group class="flexColum" [(ngModel)]="selectedIndicator">
                  <mat-radio-button *ngFor="let indi of indicators" [value]="indi">
                      {{indi}}
                  </mat-radio-button>
              </mat-radio-group>
          
          </mat-card-content>

          <mat-divider></mat-divider>
          <mat-card-actions class="flexRow">
              <mat-icon (click)="onIndicatorChange()" class="icon2">play_circle_fill</mat-icon>
              <p>Darstellen</p>
          </mat-card-actions>
          <mat-card-footer>
            <mat-progress-bar  *ngIf="indiSpinner" mode="indeterminate"></mat-progress-bar>
          </mat-card-footer>
        </mat-card>
  
        <mat-card class="chart-card">
          <mat-card-header>
            <mat-card-title> Bevölkerungen</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <p>Wählen Sie eine oder mehrhere Bevölkerungen aus:</p>

            <div class="flexColum" id="populationList">
              <mat-checkbox *ngFor="let pop of populations" [checked]="selectedPopulations.includes(pop)" (change)="onPopulationChange(pop, $event)"> 
                  {{pop}}
              </mat-checkbox>
            </div>

          </mat-card-content>
          <mat-divider></mat-divider>
          <mat-card-actions class="flexRow">
              <mat-icon (click)="changedPopulations()" class="icon2">play_circle_fill</mat-icon>
              <p>Darstellen</p>
          </mat-card-actions>
          <mat-card-footer>
            <mat-progress-bar *ngIf="popSpinner" mode="indeterminate"></mat-progress-bar>
          </mat-card-footer>
        </mat-card>

        <!-- <mat-card class="example-card">
          <mat-card-header>
            <mat-card-title>Indikatoren</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <p>Wählen Sie <span style="font-weight: bold;">eine</span> Bevölkerung aus:</p>

              <mat-radio-group class="flexColum" [(ngModel)]="selectedPopulation">
                  <mat-radio-button *ngFor="let pop of populations" [value]="pop">
                      {{pop}}
                  </mat-radio-button>
              </mat-radio-group>
          
          </mat-card-content>

          <mat-divider></mat-divider>
          <mat-card-actions class="flexRow">
              <mat-icon class="icon2" (click)="renderRadarChart()">play_circle_fill</mat-icon>
              <p>Darstellen</p>
          </mat-card-actions>
          <mat-card-footer>
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </mat-card-footer>
        </mat-card> -->
        <mat-card class="example-card">
          <mat-card-header class="flexRow">
            <mat-card-title class="flexRow">
              <mat-icon class="icon">bar_chart</mat-icon> 
              <mat-card-title>{{chartTitle}}</mat-card-title>
             </mat-card-title>
          </mat-card-header>
          <mat-card-content>
      
            <div class="chart-container">
              <canvas id="myChart"></canvas>
            </div>
          </mat-card-content>
          <mat-card-footer>
            <!-- <mat-progress-bar mode="indeterminate"></mat-progress-bar> -->
          </mat-card-footer>
        </mat-card>
  </div>

<div class="card-container">


  <!-- <mat-card class="example-card">
    <mat-card-header class="flexRow">
      <mat-card-title class="flexRow">
        <mat-icon class="icon">radar_chart</mat-icon> 
        <mat-card-title>Radar Chart</mat-card-title>
       </mat-card-title>
    </mat-card-header>
    <mat-card-content>

      <div class="chart-container-radar">
        <canvas id="myRadarChart"></canvas>
      </div>
    </mat-card-content>
    <mat-card-footer>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </mat-card-footer>
  </mat-card> -->
</div>
</div>