import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { StreetXpertService } from 'src/app/service/streetXpertService/street-xpert.service';
interface Scenario {
  name: string;
  image: string;
  uuid: string;
}

@Component({
  selector: 'app-networks',
  templateUrl: './networks.component.html',
  styleUrls: ['./networks.component.css']
})
export class NetworksComponent {

  scenarios: Scenario[] = [];

  constructor(
    private router:Router,
    private streetXpertService:StreetXpertService
  )
  {}

  ngOnInit(){
    this.streetXpertService.getScenarioInfo().subscribe((res:any)=>{
      this.scenarios=res
    })
  }


  goToMatsimModification(uuid: string) {
    this.router.navigate(['datahub2/infrastructure', uuid]);
  }
  

}
