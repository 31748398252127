

import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Chart } from 'chart.js';
import { ReachConfigComponent } from '../../popup/reach-config/reach-config.component';
import { DatahubService } from 'src/app/service/datahubservice/datahubservice.component';
import { Storageservice } from 'src/app/service/storageservice-component/storageservice-component.component';
import { RoutingserviceComponent } from 'src/app/service/routingservice/routingservice.component';
import { ReachMatrixComponent } from '../reach-matrix/reach-matrix.component';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { FormControl } from '@angular/forms';
import { Observable, Subscribable, Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { DialogShareComponent } from '../../datahub2/data-regio/dashboards/dialog-share/dialog-share.component';
import { MatDialog } from '@angular/material/dialog';
import { SelectIndikatorenModalComponent } from '../../datahub2/data-regio/dashboards/select-indikatoren-modal/select-indikatoren-modal.component';
import { ReportDialogComponent } from '../../datahub2/data-regio/dashboards/report-dialog/report-dialog.component';
import { DialogReportComponent } from '../dialog-report/dialog-report.component';
import * as htmlToImage from 'html-to-image';
import { DialogDataComponent } from '../../datahub2/data-regio/dashboards/dialog-data/dialog-data.component';

@Component({
  selector: 'result-raster-app',
  templateUrl: './result-raster-app.component.html',
  styleUrls: ['./result-raster-app.component.css']
})

export class ResultRasterAppComponent  implements OnChanges{
  pieChart: any;
  locationChange: boolean = false;
  chartImg: string | undefined;
updatePieChart() {

  if(this.pieChart) {this.pieChart.destroy()};
this.createPieChart(this.result); 

}

openDialogShare(){
  const dialogRef =this.dialog.open(DialogShareComponent,{
    width:'600px',
    height:'320px',
  });
  dialogRef.afterClosed().subscribe((result:any) => {
    console.log(result);
  
    if (result) {

    }
  });
}
public loadingN: boolean = false;
private subscriptions: Subscription = new Subscription();


public async showReport(): Promise < void > {
  alert("hello")


this.chartImg = await this.getChartImg('MyChart');
console.warn("chart", this.chartImg)
//onsole.warn("CHART", res)


//his.pieCh = await this.getChartImg('myPieChart');
//console.warn("PIECHART", res)
  //const topic = this.regioMetaData.find((r: any) => r.checked);
  var topic = { data: ''}

  //var mapImg = await this.getMapImg();
  //console.warn("Map", mapImg)
  
  this.showPDF([]); 
  return; 

}

public async showPDF(categories: any[]): Promise < void > {
  alert("he")
  const data: any[] = [
    { name:"Test",
      filterName: "Filter",
      pageType: "chart",
      img: this.chartImg},
      { name:"Test 2",
        filterName: "Filter",
        pageType: "map",
        img: this.reachComponent.mapPNG  },
  
          { name:"Test 3",
            filterName: "Filter",
            pageType: "data",
            data: this.result.Point_Params,
          
        
        }
  ];


  const selectedChart = this.chart;

 
  this.loadingN = true;

  for (var i = 0; i < 1; i++) {
    data.push(await this.getIndikatorReport(categories[i]));
    console.warn("DATA", data)
    if (i === 0) {
      const ReportDialogRef = this.dialog.open(DialogReportComponent, {
        width: '1500px',
        height: 'auto',
        data:  data,
      });

      this.subscriptions.add(ReportDialogRef.afterOpened().subscribe(() => {
        this.loadingN = false;
      }));

      this.subscriptions.add(ReportDialogRef.afterClosed().subscribe(async () => {
        this.loadingN = true;
       // this.selectedChartObject = selectedChart;
       // await this.showChart();
        this.loadingN = false;
      }));
    }
  }
}
  getIndikatorReport(arg0: any): any {
    return this.result
  }

selectedLabel: any;
storeItem() {
//@ts-ignore
document.getElementById("overwrite").showModal();


}

closeOverwrite() {
  //@ts-ignore
document.getElementById("overwrite").close();
}
checkEinrichtungFilter(einrichtung: any): any {
  return true; 

  if(this.chartType == 'bar') {
var filtered = this.chartLegend.filter((el: { label: any; disabled: boolean}) => el.label == einrichtung.Typ && !el.disabled );
  } else {

    return this.selectedLabel == 'All' || einrichtung.Typ ==  this.selectedLabel;
  }
return filtered.length > 0

}


openDialogSave() {
throw new Error('Method not implemented.');
}
async openDialogData() {
//  await this.getIndiYearsAndDataAndUnit2();
  const dialogRef =this.dialog.open(DialogDataComponent,{
    data: {loadingEr:this.loadingN,selectedChartObject:this.chart},
    width:'1000px',
    height:'700px',
  });
}
  myControl!: FormControl<any>;
showSave: any;
showTable: any;
chartType = 'bar'; 
scrollToElement($event: MatAutocompleteSelectedEvent) {
throw new Error('Method not implemented.');
}
chartLegend: any;
filteredOptions: Observable<undefined> | Subscribable<undefined> | Promise<undefined> | undefined;
filterRegion(_t106: any) {
throw new Error('Method not implemented.');
}
selectMode: any;
deselectAll(arg0: boolean) {
this.chartLegend.forEach((label: { disabled: boolean; }) => {
  label.disabled = arg0;
});
this.selectMode = arg0;
}




showShareChart: any;
showDownloadChart: any;

  async changeType(type: string) {

this.chartType = type;


}


private getChartImg(id: string): Promise < string | undefined > {
  return new Promise((resolve, reject) => {
    const domChart = document.getElementById(id);
    if (domChart) {
      htmlToImage.toPng(domChart)
        .then(function (dataUrl) {
          resolve(dataUrl);
        })
        .catch(function (error) {
          console.error('Error:', error);
          reject();
        });
    }
  });

}
selectedChartIndex: any;
minValue: any;
maxValue: any;
  selectedMinYear!: number;
  selectedMaxYear!: number;
  selectedMinVal!: number;
  selectedMaxVal!: number;
maxYear: any;
minYear: any;
changeChartType(arg0: number) {
throw new Error('Method not implemented.');
}
checkForModification(): any {
 



return   this.orgObj?.Meta[0].Einrichtungen != this.selectedEinrichtungen ||
this.orgObj?.Meta[0].Distanz     != this.selectedRadius ||  this.reachComponent.markersObjRefMap.size > 1
}

checkForOverride(){
  if(!this.orgObj) {
    return false; 
  }
  return this.orgObj.Meta[0].Name != this.selectedName &&  
this.orgObj.Meta[0].Einrichtungen == this.selectedEinrichtungen &&
this.orgObj?.Meta[0].Distanz     == this.selectedRadius

}

checkForNew(){
  if(!this.orgObj) {
    return false; 
  }
  return this.orgObj.Meta[0].Name != this.selectedName &&  (
this.orgObj.Meta[0].Einrichtungen != this.selectedEinrichtungen ||
this.orgObj?.Meta[0].Distanz     != this.selectedRadius )

}
  selectedUnit: string = 'm';
addMode = false; 
showName(newMode: boolean) {
  if(this.orgObj?.isTemp && !newMode) {
    alert("Vor Änderung bitte speichern");
    return;
  }


    //@ts-ignore
    document.getElementById("name").showModal();
  }

closeName() {

  if(!this.addMode) {

 this.datahub.renameAnalyseData(this.orgObj.Meta[0].Name, this.selectedName);
 this.orgObj.Meta[0].Name = this.selectedName; 
 
}  else {
 
  this.orgObj.Meta[0].Name = this.selectedName; 
  var newObj = JSON.parse(JSON.stringify(this.orgObj))
  newObj.isTemp = false; 
  this.orgObj.isTemp = false; 
  newObj.Meta[0].Name = this.selectedName;
  this.datahub.addAnalyseData(newObj); 
  this.reachComponent.clickResult(undefined, newObj)

  this.orgObj = newObj;

  
 }

      //@ts-ignore
      document.getElementById("name").close();
    

}

  async addAnalyseData(){
  //store new document
  var t= await this.datahub.addAnalyseData(this.orgObj); 
  console.warn(t)
  //or: replace 

  

  //@ts-ignore
  document.getElementById("overwrite").close();


}
selectedName: any = '' ;
/*
clickEinrichtung(ein: any) {
  this.reachComponent.clickEinrichtung(ein, "Umkreissuche")
} */

clickEinrichtung(ein: any) {
  if(ein == this.selectedEinrichtung) {
  
    this.selectedEinrichtung = undefined; 
    this.reachComponent.zoomOut(); 

    //this.reachComponent.clickRasterResult(this.result, this.orgObj, 0)

  } else {


    this.selectedEinrichtung = ein;
  this.reachComponent.clickEinrichtung(ein, "Umkreissuche")
  }
}



scrollIntoView(id: string) {
  const element = document.getElementById(id);
  element?.scrollIntoView({
    behavior: 'smooth',
    block: 'center'
  });
}

formatLabel(value: number): string {
  return `${value}`;
}

  async overwrite() {
  //delete old object via name
  this.reachComponent.resultsFromDB =  this.reachComponent.resultsFromDB.filter((el: { Meta: { Name: any; }[]; }) => el.Meta[0].Name != this.orgObj?.root?.Meta[0].Name)
 

  await this.datahub.removeAnalyseData(this.orgObj?.root?.Meta[0].Name)
  //reset root from actual object

  this.orgObj.Meta[0].Name = this.orgObj?.root?.Meta[0].Name
  this.orgObj.root = undefined;
  this.orgObj.isTemp = false;
  //add new object

 await this.datahub.addAnalyseData(this.orgObj); 
 this.closeOverwrite()
}
async filter($event: Event, type: string, chart: any) {
 
  //@ts-ignore
  var value = Number($event.target.ariaValueText);
  if (type == 'timeMin')this.selectedMinYear = value;
  if (type == 'timeMax')this.selectedMaxYear = value;
  if (type == 'valMin')this.selectedMinVal = value;
  if (type == 'valMax')this.selectedMaxVal = value;
  await this.updateChartBasedOnIndex(this.selectedChartIndex);
  return;
}
  async updateChartBasedOnIndex(selectedChartIndex: any) {
   //TODO: Implement 
  }

  @Input() result: any;
  @Input() orgObj: any;
  @Input() clickedName: any;
  myChart: any;
  selectedStep: any;
  selectedEinrichtungen: any;
  meta: any;
  selectedNewEinrichtung: any;
  tempObj: any = {};
  umkreisMode: any;
  selectedRadius: any;
  selectedStandorte: any;
  modified: boolean = false;
  chart: any; 
  selectedEinrichtung: any; 





  constructor(    public dialog: MatDialog, private datahub: DatahubService, public datepipe: DatePipe,
    public storageService: Storageservice, public reachComponent:ReachMatrixComponent,  private routingService: RoutingserviceComponent) {


  }
  ngOnChanges(changes: SimpleChanges): void {
    this.reset()
    
  
    console.warn("res", this.result)
  
    
    if(this.result.Error) {

      this.selectedName = this.orgObj?.Meta[0].Name 
      this.selectedRadius = this.orgObj?.Meta[0].Distanz 
      this.selectedUnit = this.orgObj?.Meta[0].Unit 
      this.selectedEinrichtung =  ''
      alert(this.result.Error)
      
      return;
    }

    var chartData = this.convertToChartJsData(this.result.Plot_Params[0]);
    console.warn("chart", chartData)
    if(!chartData) {
      return; 
    }

    if(this.chart) {
      this.chart.destroy(); 
    }

    this.createChart(chartData)

  


  //@ts-ignore
    this.chart.data = chartData.data; 
    this.chartLegend = []
    chartData.data.labels.forEach((label: any) => {
      this.chartLegend.push({
        label: label,
        disabled: false,
      })
      
    });
   // this.chartLegend = chartData.data.labels; 
    this.chart.update();
    this.selectedLabel = "All"; 
    this.createPieChart(this.result)
    this.selectedEinrichtungen = this.orgObj.Meta[0].Einrichtungen 
    this.selectedRadius = this.orgObj?.Meta[0].Distanz     
    this.scrollIntoView(this.clickedName)
   
  }

  convertToChartJsData(plot_params: any) {
    if(!plot_params)
      {
        return;
      }
      const data = {
        type: 'bar',
        data: {
            labels: plot_params.class_plot,
            datasets: [{
                label: 'Share Class (%)',
                data:  plot_params.share_class,
                backgroundColor: 'rgba(54, 162, 235, 0.6)',
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 1
            }]
        },
        options: {
            scales: {
                y: {
                    beginAtZero: true,
                    title: {
                        display: true,
                        text: 'Percentage (%)'
                    }
                },
                x: {
                    title: {
                        display: true,
                        text: 'Time Classes'
                    }
                }
            },
            plugins: {
                title: {
                    display: true,
                    text: 'Distribution of Time Classes'
                },
          
            }
        }
    };


return data
  }
  createChart(chartData: any){


    this.chartLegend = chartData.labels; 

    if(this.chart) {
      this.chart.destroy();
    }

  
    this.chart = new Chart("MyChart", {
      //@ts-ignore
      type: this.chartType, 

      data: chartData,
      options: {
        aspectRatio:2.5
      }
      
    });
  }

  async ngOnInit(): Promise<void> {


    this.meta = await this.datahub.getEinrichtungenMeta();
    console.warn(this.meta);

  }

  reset() {
    this.selectedName = this.orgObj?.Meta[0].Name 
    this.selectedRadius = this.orgObj?.Meta[0].Distanz 
    this.selectedUnit = this.orgObj?.Meta[0].Unit 
    this.selectedEinrichtung =  this.orgObj?.Meta[0].Einrichtungen

  }

  async startAnalyse() {
  //@ts-ignore
  document.getElementById("buffer").showModal();
    
 
    if(this.umkreisMode) {

    var filter = this.umkreisMode == 0 ?  "Distance" : "Time";
    } else {
         filter = this.orgObj.Meta[0].Filter;
    }

    var radius = this.selectedRadius; 
   var positionFormat: { X: any; Y: any; Name: any; Street_Name: any; }[] = [];

   console.warn("ORG", this.orgObj)


    //new markers
    for (const [key, value] of this.reachComponent.markersObjRefMap) {
      console.warn("IT", value.plain.latlng[0])

      positionFormat.push(
        {"X":value.plain.latlng[1], "Y": value.plain.latlng[0], "Name": value.plain.name,"Street_Name": value.plain.address}
 
      )
    }


    this.orgObj?.Results.forEach((startingpoint: any) => {
      startingpoint.Starting_Point.forEach((pos: any) => {
        var filtered = positionFormat.filter(el => el.Name == pos.Name)
        if(filtered.length == 0) {
      
        positionFormat.push(
         {"X":pos.geometry.coordinates[0], "Y": pos.geometry.coordinates[1], "Name": pos.Name,"Street_Name": pos.Street_Name}
  
       ) }
      })
      
    });

    var data = { 
      "Gesuchter_Typ": this.selectedEinrichtungen,
      "Mode": "CAR",
      "Position": positionFormat,
      "Radius": radius,
      "Type": "Umkreissuche",
      "Filter" : filter
    }

    console.log("DATA", data);

    if(this.orgObj?.root) {
      name = this.orgObj.root.Meta[0].Name
      root = this.orgObj.root

    } else {
     //@ts-ignore
    if(this.orgObj?.Meta[0].Name  != this.selectedName){
      var name = this.selectedName
      var root = undefined;
    } else {
         //@ts-ignore
      name = this.orgObj?.Meta[0].Name 
      root = this.orgObj

    }

   }


    var res; 
    try{

   res = await this.routingService.getUmkreisAnalyse(data, name, root, true);
    } catch(e) {
    
    }

  



    console.warn("result", res)
    this.reset()

    this.storageService.addTempResult(res); 
     //@ts-ignore
     this.reachComponent.clickResult(res.Results[0], res)
       //@ts-ignore
  document.getElementById("buffer").close(); 
    }


    createPieChart(dataAll: any) {
      return; 

      var filter = this.selectedLabel; 
  


      var data : any = []
      data.Meta_Einrichtungen = dataAll.Meta_Einrichtungen.filter((el: { Typ: string; }) => el.Typ == filter)
  
      const categories : any = {};
      data.Meta_Einrichtungen.forEach((meta: { data: any[]; }) => {
        meta.data.forEach(entry => {
          if (categories[entry.category]) {
            categories[entry.category] += parseFloat(entry.share);
          } else {
            categories[entry.category] = parseFloat(entry.share);
          }
        });
      });
  
      const labels = Object.keys(categories);
      const values = Object.values(categories);

      if(this.pieChart) {this.pieChart.destroy()};
     
     this.pieChart = new Chart("myPieChart", {
        type: 'pie',
        data: {
          labels: labels,
          datasets: [{
            label: 'Share by Category',
            data: values,
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)'
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)'
            ],
            borderWidth: 1
          }]
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: 'right',
            },
            tooltip: {
              callbacks: {
                label: function(context) {
                  const label = context.label || '';
                  const value = context.raw || '';
                  return `${label}: ${value}%`;
                }
              }
            }
          }
        }
      });
    }

    

  async showEinrichtung() {
    //this.selectedEinrichtungen = JSON.parse(JSON.stringify(this.orgObj?.Meta[0].Einrichtungen))
    if(this.orgObj?.isTemp) {
      alert("Vor Änderung bitte speichern");
      return;
    } else {
    //@ts-ignore
    document.getElementById("einrichtung").showModal();
    }
  }

  async saveEinrichtung() {


  }

  async closeEinrichtung() {
    //@ts-ignore
    document.getElementById("einrichtung").close();
  }


  async showUmkreis() {

    if(this.orgObj?.isTemp) {
      alert("Vor Änderung bitte speichern");
      return;
    }

    if (this.orgObj?.Meta[0].Unit == 'm') {
      this.umkreisMode = 0;
    } else {
      this.umkreisMode = 1;
    }
    //this.selectedRadius = JSON.parse(JSON.stringify(this.orgObj?.Meta[0].Distanz))
    //@ts-ignore
    //document.getElementById("umkreis").showModal();
  }

  saveUmkreis() {

 
    if (this.umkreisMode == 0) {
      this.selectedUnit = 'm'
    } else {
      this.selectedUnit = 'min'
    }

  }

  async closeStandort() {

    //@ts-ignore
    document.getElementById("dialog_mini").close();
  }


  showStandortPopUp() {
      //@ts-ignore
      document.getElementById("dialog_mini").showModal();
  }
  async showStandort() {

    if(this.orgObj?.isTemp) {
      alert("Vor Änderung bitte speichern");
      return;
    }

   // this.locationChange = true; 
    this.reachComponent.clearUmkreisResults();
    this.reachComponent.addMarkerWithMapRef(this.result?.Starting_Point[0].geometry.coordinates[1], this.result?.Starting_Point[0].geometry.coordinates[0], this.result?.Starting_Point[0].Name, this.result?.Starting_Point[0].Street_Name)
    this.reachComponent.addMarker();
    this.reachComponent.newAnalyseMode = false;


  }

  saveStandort() {
    this.selectedStandorte = this.storageService.markers
    this.modified = true; 
  }

  async closeUmkreis() {


    //@ts-ignore
    document.getElementById("umkreis").close();
  }



  setValue($event: Event, arg1: string) {
    //@ts-ignore
    var val = Number($event.target.ariaValueText)

    switch (arg1) {

      case 'radius':
        this.selectedRadius = val;
        break;
      case 'time':
        this.selectedRadius = val;
        break;

    }
  }
}

