import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { GlobalVariable } from 'global';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-llama',
  templateUrl: './llama.component.html',
  styleUrls: ['./llama.component.css']
})
export class LlamaComponent {

  private baseApiUrl = GlobalVariable.LLAMA_URL;

  constructor(private http: HttpClient) {

  }


  async addMessages(messages : any) {

  
     var testBody = {
 
 
       "model": "llama3.1:8b",
       "messages": messages,
       "stream": false
     }
 
     var url = this.baseApiUrl;

     console.warn(testBody)
 
 
     var result = await firstValueFrom(this.http.post(url, testBody ) );
     return result;
 
 
 
   }

  async addChat(search: string) {
   /* const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Basic ' + btoa('bunsenuser:babyschnaps')
      })
    }; */



    var testBody = {


      "model": "llama3.1:8b",
      "messages": [
        {
          "role": "user",
          "content": search

        }


      ],
      "stream": false
    }

    var url = this.baseApiUrl;


    var result = await firstValueFrom(this.http.post(url, testBody ) );

    return result;



  }

}
