<div class="container">
  <mat-card class="example-card custom-shadow">
    <mat-card-header style="display: flex; justify-content:center; align-items:center">
      <mat-card-title>Verfügbare Straßennetze</mat-card-title>
    </mat-card-header>
  </mat-card>

  <div class="flexwrapBox">
    <div *ngFor="let scenario of scenarios" (click)="goToMatsimModification(scenario.uuid)" style="display: flex; flex-direction: row; gap: 200px">
      <mat-card class="example-card custom-shadow" matRipple>
        <mat-card-header>
          <mat-card-title>{{ scenario.name }}</mat-card-title>
        </mat-card-header>
        <img mat-card-image [src]="scenario.image">
      </mat-card>
    </div>
  </div>
</div>
