


import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as XLSX from 'xlsx';
import {LiveAnnouncer} from '@angular/cdk/a11y';
import {MatSort, Sort, MatSortModule} from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';

@Component({
  selector: 'app-dialog-download',
  templateUrl: './dialog-download.component.html',
  styleUrls: ['./dialog-download.component.css']
})
export class DialogDownloadComponent {




  constructor(
    public dialogRef: MatDialogRef<DialogDownloadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {loadingEr:boolean,title:any,einrichtungen: any, dataIndiTableValues:any,dataIndiTable:any; subtitle: any},
  ) {}

  
  @ViewChild(MatSort) sort!: MatSort 
  @ViewChild(MatPaginator) paginator!: MatPaginator;


  ngAfterViewInit() {
    this.data.dataIndiTable = new MatTableDataSource(this.data.dataIndiTable);
    this.data.dataIndiTable.sort = this.sort;
    this.data.dataIndiTable.paginator = this.paginator;
  }

  downloadExcel(): void {
    //console.warn("datatatta",this.data.dataIndiTable)
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.data.einrichtungen);
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    const data: Blob = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',});
    let url = window.URL.createObjectURL(data);
    let a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    a.href = url;
    a.download = 'data.xlsx';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  downloadCSV(): void {
    let csvData = this.convertToCSV(this.data.einrichtungen);
    // UTF-8 BOM hinzufügen
    const BOM = '\uFEFF';
    let blob = new Blob([BOM + csvData], { type: 'text/csv;charset=utf-8' });
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    a.href = url;
    a.download = 'data.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
  
  convertToCSV(objArray: any[]): string {
    const array = objArray instanceof Array ? objArray : [objArray];
    let keys = Object.keys(array[0]);
    let result = '';
    result += keys.join(',') + '\n';
    array.forEach((obj) => {
      keys.forEach((k, ix) => {
        if (ix) result += ',';
        let data = obj[k] === null || obj[k] === undefined ? '' : obj[k].toString();
        if (data.includes(',') || data.includes('"') || data.includes('\n')) {
          data = '"' + data.replace(/"/g, '""') + '"';
        }
        result += data;
      });
      result += '\n';
    });
    return result;
  }
  

  downloadJSON(): void {
    const dataStr = JSON.stringify(this.data.einrichtungen);
    let dataUri ='data:application/json;charset=utf-8,' + encodeURIComponent(dataStr);
    let exportFileDefaultName = 'data.json';
    let linkElement = document.createElement('a');
    linkElement.setAttribute('href', dataUri);
    linkElement.setAttribute('download', exportFileDefaultName);
    linkElement.click();
  }

  downloadTXT(): void {
    let txtContent = '';
    this.data.einrichtungen.forEach((row: any) => {
      txtContent += `${row.Name}\t${row.Typ}\t${row.Distanz}\t${row.Unit}\n`;
    });
    const blob = new Blob([txtContent], { type: 'text/plain;charset=utf-8' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'data.txt';
    a.click();
  }

  closeAll(): void {
    this.dialogRef.close();
  }

}



