
  
  <div style="padding-top:40px">
    <div class="folder-sticker">
      <span class="mainDatahub"> Prognose. Nachfrage nach medizinischer Infrastruktur.</span>
    </div>
    

    
    <div style="display: flex; flex-direction: column; justify-content: space-between; ;" >
      

    

    
    
    
    
   <div class="auswahl" >

    <div  id="second-page" *ngIf="start && selectedItem ">

      <app-nachfrageberechnung-item [item]="selectedItem"> </app-nachfrageberechnung-item>

      
  </div>
       
    <div style="height: 55vh; overflow-y: scroll; display: flex; flex-direction: row; " id="first-page" *ngIf="start" > 
    
    
      <div style="display: flex; justify-content:center; align-items:center;flex-direction:column;gap:10px; border-right:1px solid #ccc; padding-right:10px"> 
        <button  mat-fab extended color="primary" (click)="start = false"> <mat-icon>add</mat-icon> Analyse hinzufügen </button>

        <div>
          <input id="adress2" [(ngModel)]="searchBox" />
      </div>
  
    
      </div>

    
      <div id="left-container">
       

        <div style="padding: 1%; display: flex;" > 

          <div *ngFor="let item of storedObject" (click)="clickItem(item)" id="tile-background" [style.background-color]="selectedItem  == item ? 'lightgray' : 'white'"> 
            <div class="pad2" *ngIf="filterItem(item)">
                  
             <h1> {{item.name }} </h1>
             <h3> Bevölkerung:  {{ item.selectedPop }} </h3>

             <h3> Szenarien:  {{ item.selectedScenarios }} </h3>
       
            </div>

          </div>



        </div>

 

    
    
    
        </div>

 
        </div>

        <div  id="second-page" *ngIf="!start && !selectedItem ">

            <app-nachfrageberechnung-select> </app-nachfrageberechnung-select>

            
        </div>

  
          
    
        </div>
    
        
    
   
    
    
    
      </div>
    

    
    

  </div>
  
