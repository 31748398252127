

<div class="main-container" *ngIf="mode == 0">

    <div class="row">
        <br>
        <br>
        <div>
            <span class="span0 "> Mobilitätsnetzwerke </span>
        </div>
        <br>
        <br>
    </div>


    <div class="row2">
        
            <div class="bottomTri">
                <div class="datahub-tile2" (click)="clickTile(1)">
                    <div>
                        <span class="span2"> Straßenetzwerke </span>
                    </div>
                </div>
            </div>
    </div>

</div>
