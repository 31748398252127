import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-selection-region',
  templateUrl: './dialog-selection-region.component.html',
  styleUrls: ['./dialog-selection-region.component.css']
})
export class DialogSelectionRegionComponent {
  localData:any
  constructor(
    public dialogRef: MatDialogRef<DialogSelectionRegionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {chartLegendMap: any,selectMode:boolean},
  ) {  
    this.localData = {
    chartLegendMap: this.data.chartLegendMap.map((item:any) => ({ ...item })),
    selectMode: this.data.selectMode
  };
  }

  toggleSelection(item: any) {
    const foundItem = this.localData.chartLegendMap.find((i: any) => i.label === item.label);
    if (foundItem) {
      foundItem.disabled = !foundItem.disabled;
    }
  }

  setAllSelection(selected: boolean) {
    this.localData.chartLegendMap.forEach((item: any) => item.disabled = !selected);
  }

  apply() {
    this.data.chartLegendMap = [...this.localData.chartLegendMap];
    this.data.selectMode = this.localData.selectMode;
    this.dialogRef.close(this.data);
  }

  close() {
    this.dialogRef.close();
  }
}
