<div  id="Dialog">
  <mat-form-field>
    <mat-label>Version auswählen</mat-label>
    <mat-select [(value)]="selectedVersion" (selectionChange)="onSelectionChange($event)">
      <mat-option *ngFor="let version of versionArray" [value]="version">
        Version {{ version }}
      </mat-option>
    </mat-select>
</mat-form-field>
</div>
  
<div class="container"  class="DashboardHistory">
  <h2>Dashboard Historie</h2>
  <div *ngIf="isLoading" class="loading">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    <p>Loading data, please wait...</p>
  </div>

  <div *ngIf="!isLoading ">
    <h3>Version 1 </h3>
    <table mat-table [dataSource]="version1Data" class="mat-elevation-z8">
      <ng-container matColumnDef="userId">
        <th mat-header-cell *matHeaderCellDef> Ersteller </th>
        <td mat-cell *matCellDef="let element"> {{userNames[element.userId] || 'Lädt...'}}   </td>
      </ng-container>

      <ng-container matColumnDef="created_At">
        <th mat-header-cell *matHeaderCellDef> erstellt am</th>
        <td mat-cell *matCellDef="let element"> {{formatToGermanDate(element.created_At)}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="['userId', 'created_At']"></tr>
      <tr mat-row *matRowDef="let row; columns: ['userId', 'created_At']"></tr>
    </table>

    <div *ngIf="otherVersionsData.length!==0">
      <h3>Weitere Versionen</h3>
      <table mat-table [dataSource]="otherVersionsData" class="mat-elevation-z8">
        <ng-container matColumnDef="version">
          <th mat-header-cell *matHeaderCellDef> Version </th>
          <td mat-cell *matCellDef="let element"> {{element.version}} </td>
        </ng-container>
  
        <ng-container matColumnDef="modified_By">
          <th mat-header-cell *matHeaderCellDef> modifiziert von </th>
          <td mat-cell *matCellDef="let element"> {{userNames[element.modified_By] || 'Lädt...'}} </td>
        </ng-container>
  
        <ng-container matColumnDef="modified_At">
          <th mat-header-cell *matHeaderCellDef> modifiziert am </th>
          <td mat-cell *matCellDef="let element"> {{formatToGermanDate(element.modified_At)}} </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="['version', 'modified_By', 'modified_At']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['version', 'modified_By', 'modified_At']"></tr>
      </table>
    </div>
  </div>
</div>
