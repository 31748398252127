<div id="mapDialog">

    <div class="section">
      <h3> Verteilung der Breaks</h3>
      <mat-button-toggle-group name="fontStyle" aria-label="Font Style">
        <mat-button-toggle (click)="data.chosenBreaks=0">Gleichmäßig</mat-button-toggle>
        <mat-button-toggle (click)="data.chosenBreaks=1;fillArray();">Benutzerdefiniert</mat-button-toggle>
        <mat-button-toggle (click)="data.chosenBreaks=2">Jenks-Optimierung</mat-button-toggle>
        <mat-button-toggle (click)="data.chosenBreaks=3">Quantile</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    
    <div *ngIf="data.chosenBreaks === 0 || data.chosenBreaks === 3" class="section" >
      <h3>Wählen Sie die Anzahl der Klassen, nach denen die Daten kategorisiert werden sollen(max. 8)</h3>
      <mat-form-field >
        <input matInput [(ngModel)]="data.breaksAmount" >
      </mat-form-field>
    </div>
    
    <div *ngIf="data.chosenBreaks === 2" class="section">
      <h3 *ngIf="data.valuesStored.length === 1">Bitte beachten Sie, dass in diesem Fall die Verwendung der Jenks-Verteilung nicht anwendbar ist</h3>
      <h3 *ngIf="data.valuesStored.length <= data.breaksAmount && data.valuesStored.length !== 1">Bitte beachten Sie, dass aufgrund der Datenlage und angewandten Filter höchstens {{setMax()}} Klassen verwendet werden können.</h3>
      <h3 *ngIf="data.valuesStored.length > data.breaksAmount">Bitte beachten Sie, dass aufgrund der Datenlage und angewandten Filter höchstens {{setMax()}} Klassen verwendet werden können.</h3>
      <mat-form-field>
        <input matInput [(ngModel)]="data.breaksAmount">
      </mat-form-field>
    </div>
    

    <div *ngIf="data.chosenBreaks === 1" class="section">
      <h3 style="margin: 25px;">max. 8 Klassen. Einheit: {{data.selectedChartObject.unit}}</h3>
      
      <div *ngFor="let inp of data.valuesArray; let i = index" style="width: 650px;display: flex;justify-content: space-between;align-items: center;">
          <button  style="background-color: #10684E;color: white" mat-mini-fab (click)="add(i,data.valuesBreaks[i].from)">
            <mat-icon>exposure_plus_1</mat-icon>
          </button>
          <button  *ngIf="i!=0"  style="background-color: #10684E;color: white" mat-mini-fab (click)="remove(i,data.valuesBreaks[i].to)" >
            <mat-icon>exposure_minus_1</mat-icon>
          </button>
          <button  *ngIf="i==0"  style="background-color: grey;color: white" mat-mini-fab>
            <mat-icon>exposure_minus_1</mat-icon>
          </button>
    
    
          <mat-form-field >
            <input matInput [(ngModel)]="data.valuesBreaks[i].from" disabled type="number" >
          </mat-form-field>
    
          <div style="height: 2px; width: 50px; background-color: black;"></div>
    
          <mat-form-field >
            <input matInput [(ngModel)]="data.valuesBreaks[i].to" (keyup)="fillNext(i,data.valuesBreaks[i].to)" type="number" [disabled]="i === data.valuesArray.length - 1" (blur)="checkRange(i,data.valuesBreaks[i].from)">
          </mat-form-field>
      </div>
    </div>
    
      <div class="diaFooter">
        <button mat-raised-button color="primary" (click)="closeDialog()" 
        [disabled]="data.chosenBreaks === 2 && data.breaksAmount> (this.data.valuesStored.length - 1) || data.chosenBreaks === 5 ">Anwenden</button>

        <button  (click)="closeAll()"  mat-raised-button color="warn" >Schließen</button>
      </div>
    
    </div>