<div class="user-list">
    <div class="user-list-inner">
      <ng-container *ngFor="let shape of resMul" [(ngModel)] = "selectedShapes">
    
        <mat-checkbox (change)="toggleShape(shape, $event.checked)">
          {{ shape.Object_Nam }}
        </mat-checkbox>
      </ng-container>
    </div>
  </div>

<button (click)="close()"> Close </button>
<button (click)="close()"> Apply </button>