import { Component } from '@angular/core';

@Component({
  selector: 'app-data-synth-pop',
  templateUrl: './data-synth-pop.component.html',
  styleUrls: ['./data-synth-pop.component.css']
})
export class DataSynthPopComponent {

}
