import { Component } from '@angular/core';
import L from 'leaflet';
import { Layer, Map, latLng, tileLayer } from 'leaflet';
//import { OSMBuildings } from 'osmbuildings';

@Component({
  selector: 'app-hitzesimulation',
  templateUrl: './hitzesimulation.component.html',
  styleUrls: ['./hitzesimulation.component.css']
})
export class HitzesimulationComponent {

  private map!: L.Map;
  date: number;
  osmb: any; 
  showShadow = false; 

  constructor() { 
    const Jan1 = new Date(this.now.getFullYear(), 0, 1);
    this.date = Math.ceil((this.now.getTime() - Jan1.getTime()) / 86400000); // Day of the year
  }

  ngOnInit(): void {
    this.changeDate();
    this.loadScript('https://cdn.osmbuildings.org/classic/0.2.2b/OSMBuildings-Leaflet.js')
      .then(() => this.initMap())
      .catch(err => console.error('OSMBuildings script loading error:', err));
  }

  private initMap(): void {
    this.map = L.map('map').setView([51.143577, 11.814725], 15);

    L.tileLayer('https://tile-a.openstreetmap.fr/hot/{z}/{x}/{y}.png', {
      attribution: '© Data <a href="https://openstreetmap.org">OpenStreetMap</a>',
      maxZoom: 18,
      maxNativeZoom: 20
    }).addTo(this.map);

    // @ts-ignore
    this.osmb = new OSMBuildings(this.map).load('https://{s}.data.osmbuildings.org/0.2/59fcc2e8/tile/{z}/{x}/{y}.json');
  }

  private loadScript(url: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = url;
      script.onload = () => resolve();
      script.onerror = () => reject(new Error(`Script load error for ${url}`));
      document.body.appendChild(script);
    });
  }

  now: Date = new Date();
  time: number = this.now.getHours();

  timeRangeLabel: string = '';
  dateRangeLabel: string = '';




  changeDate(): void {
    const Y = this.now.getFullYear(),
          M = this.now.getMonth(),
          D = this.now.getDate(),
          h = this.now.getHours(),
          m = 0;

    this.timeRangeLabel = this.pad(h) + ':' + this.pad(m);
    this.dateRangeLabel = Y + '-' + this.pad(M + 1) + '-' + this.pad(D);

    // Hier wird angenommen, dass `osmb.date()` eine Methode ist, die du in deiner App verwenden möchtest.
    if(this.osmb)
     this.osmb.date(new Date(Y, M, D, h, m));

  // alert(this.osmb)
  }

  onTimeChange(): void {
    console.warn("time", this.time)
    this.now.setHours(this.time);
    this.now.setMinutes(0);
    this.changeDate();
  }

  onDateChange(): void {
    const Jan1 = new Date(this.now.getFullYear(), 0, 1);
    this.now.setMonth(0);
    this.now.setDate(this.date);
    this.changeDate();
  }

  pad(v: number): string {
    return (v < 10 ? '0' : '') + v;
  }
}