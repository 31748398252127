<div class="main">
  <mat-card *ngIf="!selectedScenario">
    <mat-card-title>Scenarios</mat-card-title>
    <mat-list>
      <mat-list-item *ngFor="let scenario of scenarios" (click)="selectScenario(scenario)">
        {{ scenario.name| titlecase }}
      </mat-list-item>
    </mat-list>
  </mat-card>

  <mat-card *ngIf="selectedScenario">
    <mat-card-title>Simulations for {{ selectedScenario.name }}</mat-card-title>
    <mat-list>
      <mat-list-item *ngFor="let simulation of simulations" (click)="goToResult(simulation.uuid)">
        {{ simulation.title }}
      </mat-list-item>
      <mat-divider></mat-divider>
    </mat-list>
    <button mat-raised-button color="primary" (click)="selectedScenario = null">Back to Scenarios</button>
  </mat-card>
</div>