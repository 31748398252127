import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StreetXpertService {

  private apiUrl 
  
  constructor(private http:HttpClient,) {
    const useLocal = false;
    this.apiUrl = useLocal ? 'http://localhost:3000' : 'https://streetxpert.cephlabs.de';
   }

  getGeoJson(uuid: string) {
    const timestamp = new Date().getTime();
    return this.http.get(`${this.apiUrl}/getGeoJson/${uuid}?timestamp=${timestamp}`);
  }
  
  getGeoJsonUpdated(uuid: string ,simulationUUID: string){
    return this.http.get(this.apiUrl + '/getUpdatedNetwork/'+ uuid + '/' + simulationUUID)
  }

  getChartStreet(uuid: string ,simulationUUID: string){
    return firstValueFrom(this.http.get(this.apiUrl + '/getChartStreet/'+ uuid + '/' + simulationUUID))
  }

  createGeoJsonUpdated(uuid: string,simulationUUID: string){
    return firstValueFrom(this.http.get(this.apiUrl + '/createGeoJsonUpdated/'+ uuid + '/' + simulationUUID))
  }

  getMultiLineString(street: any,uuid: string) {
    const timestamp = new Date().getTime();
    return this.http.get(`${this.apiUrl}/getMultiLineString/${uuid}/${street}?timestamp=${timestamp}`);
  }

  postGeoJson(dataj: any, uuid: string) {
    return this.http.post(this.apiUrl + `/saveGeoJson/${uuid}`, dataj, { responseType: 'text' });
  }
  
  postNetwork(uuid: string, title: string, description: string, globalChanges: any) {
    return this.http.post(this.apiUrl + `/saveNetwork/${uuid}`, { title, description, globalChanges });
  }
  
  startSimulation(uuid: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/start-simulation/${uuid}`);
  }
  
  getIterations(uuid:string){
    const timestamp = new Date().getTime();
    return this.http.get(`${this.apiUrl}/get-iteration/${uuid}?timestamp=${timestamp}`);
  }

  createLinkstats(uuid:string){
    return firstValueFrom(this.http.get(this.apiUrl + '/process-linkstats/'+uuid))
  }

  getIterationTime(){
    return this.http.get(this.apiUrl + '/get-iteration-time')
  }

  getScenarioInfo(){
    return this.http.get(this.apiUrl + '/getScenarioInfo')
  }

  getScenarios(): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/scenarios'); 
  }

  getSimulations(scenarioUUID: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/simulations/${scenarioUUID}`);
  }

  getTravelTime(uuid: string ,simulationUUID: string){
    return firstValueFrom(this.http.get(this.apiUrl + '/getTravelTime/'+ uuid + '/' + simulationUUID))
  }

  getOriginalGeojsonWithHRS(uuid: string ,simulationUUID: string){
    return firstValueFrom(this.http.get(this.apiUrl + '/getOriginalGeojsonWithHRS/'+ uuid + '/' + simulationUUID))
  }

}
