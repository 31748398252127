<div id="mapDialogRegion">
    <mat-checkbox *ngFor="let l of localData.chartLegendMap" [checked]="!l.disabled" (change)="toggleSelection(l)">
      {{ l.label }}
    </mat-checkbox>
  
    <div style="margin-top: 20px;">
      <mat-checkbox [checked]="localData.selectMode" (change)="setAllSelection($event.checked)">Select All</mat-checkbox>
    </div>
  
    <div class="diaFooter" style="margin-top: 20px;">
      <button (click)="apply()" mat-raised-button color="primary">Anwenden</button>
      <button (click)="close()" mat-raised-button color="warn">Schließen</button>
    </div>
  </div>
  