<div id="dataDialog">
    

    <div *ngIf="!data.loadingEr"  style="width: 100%; display: flex; justify-content: center; align-items: center; flex-direction: column;">
      <mat-spinner></mat-spinner>
      <p>Loading...</p>
    </div>

    <div *ngIf="data.loadingEr" style="width: 100%; display: flex; justify-content: flex-start; align-items: center; flex-direction: column; gap: 50px;">
    
    
      <div  style="display: flex; justify-content: center; align-items: center;">
        <span class="span1"> {{data.selectedChartObject.Name}}</span>
      </div>

      <div style="display: flex; justify-content: space-evenly;align-items: center; width: 800px;">
        <button mat-raised-button color="primary">
           Zeitraum: {{data.dataIndiTableValues.MinYear}} -  {{data.dataIndiTableValues.MaxYear}}
        </button>

        <button mat-raised-button color="primary">
           Wertebereich : {{data.dataIndiTableValues.MinData}} - {{data.dataIndiTableValues.MaxData}}
        </button>

        <button mat-raised-button color="primary">
          Mittelwert: {{data.dataIndiTableValues.AverageData}}
        </button>
      </div>

      <h5 style="margin-block-start: 0em; margin-block-end: 0em;margin: -32px;margin-left: -560px;">
        Quelle:
        <a href="https://www.wegweiser-kommune.de/" target="_blank" > https://www.wegweiser-kommune.de/</a>
      </h5>

        <div style=" max-height: 400px;
        min-height: 200px;
        overflow-y: scroll;
        width: 800px;
        border: 2px solid #ccc;">
          <table mat-table [dataSource]="data.dataIndiTable" class="mat-elevation-z8" matSort >
            <ng-container matColumnDef="gebietsname">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">Gebietsname</th>
              <td mat-cell *matCellDef="let element">{{element.gebietsname}}</td>
            </ng-container>
          
            <ng-container matColumnDef="year">
              <th mat-header-cell *matHeaderCellDef>Year</th>
              <td mat-cell *matCellDef="let element">{{element.year}}</td>
            </ng-container>
          
            <ng-container matColumnDef="data">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by number">Data</th>
              <td mat-cell *matCellDef="let element">{{element.data}}</td>
            </ng-container>
  
            <ng-container matColumnDef="einheit">
              <th mat-header-cell *matHeaderCellDef>Einheit</th>
              <td mat-cell *matCellDef="let element">{{element.einheit}}</td>
            </ng-container>
          
            <tr mat-header-row *matHeaderRowDef="['gebietsname', 'year', 'data' , 'einheit' ]"></tr>
            <tr mat-row *matRowDef="let row; columns: ['gebietsname', 'year', 'data' , 'einheit'];"></tr>
          </table>
          <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
          </mat-paginator>
        </div>
<!-- 
        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons style=" margin-right:145px">
        </mat-paginator> -->

        <div style="width: 100%; display: flex; justify-content: space-between;align-items: center;">
            <button mat-raised-button (click)="downloadExcel()">  <mat-icon>file_download</mat-icon> Download Excel</button>
            <button mat-raised-button (click)="downloadCSV()">  <mat-icon>file_download</mat-icon> Download CSV</button>
            <button mat-raised-button (click)="downloadJSON()">  <mat-icon>file_download</mat-icon> Download JSON</button>
            <button mat-raised-button (click)="downloadTXT()">  <mat-icon>file_download</mat-icon> Download TXT</button>
            <button mat-raised-button color="warn" (click)="closeAll()">schließen</button>
        </div>
      </div>




  </div>
