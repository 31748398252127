import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RadarService } from 'src/app/service/radarService/radar.service';
import { RegioService } from 'src/app/service/regioservice/regioservice.component';
import { Storageservice } from 'src/app/service/storageservice-component/storageservice-component.component';

@Component({
  selector: 'app-dialog-radar',
  templateUrl: './dialog-radar.component.html',
  styleUrls: ['./dialog-radar.component.css']
})
export class DialogRadarComponent {
  localData:any
  maxSelectable = 5;
  selectedMaxYearRadar: number =this.data.maxYear;
  selectedMinYearRadar: number =this.data.minYear;
  selectedTheme: any = null;
  selectedIndicators: any[] = [{
    Name:"test",
    years:[1,2]
  }];
  disableUncheckedCheckboxes = false;
  radarChartIndex:number=0;
  combinedArray:any=[];
  yearsArray:any[]=[];
  firstColoring=false;

  // indiReady=false;
  // e:any
  constructor(
    private radarService: RadarService,
    public dialogRef: MatDialogRef<DialogRadarComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {chartLegend:any,maxYear:number,minYear:number,selectedChartIndex:number,regioMetaData:any,indicators:any,yearsArray:any,selectedYear:number,selectedLevel:any},
  ) {    
    this.combinedArray=[]
    this.localData = {
      chartLegend: this.data.chartLegend.map((item:any) => ({ ...item,disabled: true })),
      }
  }  

  toggleSelection(item: any) {    
    const foundItem = this.localData.chartLegend.find((i: any) => i.label === item.label);
    if (foundItem) {
      foundItem.disabled = !foundItem.disabled;
  
      const enabledCount = this.localData.chartLegend.reduce((count: number, current: any) => {
        return count + (!current.disabled ? 1 : 0);
      }, 0);
  
      this.localData.chartLegend.forEach((i: any) => {
        if (enabledCount >= this.maxSelectable) {
          if (i.disabled) {
            i.forceDisabled = true; 
          }
        } else {
          delete i.forceDisabled;
        }
      });
    }
  }

  onStepChange(event: any) {
    this.radarService.loadIndicators().then((res:any)=>{
      this.selectedIndicators =res
      this.selectedIndicators.forEach((item:any) => {
        if(item.years ) item.clickedYear= item.years[(item.years).length-1]
      });
    });
  }

  setClickedYear(indi:any,year:number){
    indi.clickedYear=year
  }

  apply() {
    this.data.chartLegend = [...this.localData.chartLegend];
    this.data.selectedYear= this.selectedMaxYearRadar ;
    this.data.minYear = this.selectedMinYearRadar ;
    this.data.indicators = this.selectedIndicators ;
    //this.data.yearsArray= this.yearsArray
    //this.data.yearsArray= [2018,2019,2020,2021]
    this.dialogRef.close(this.data);
  }

}
