<div style="margin: 1% 5% 1% 5%"> 
    <div style="display: flex; justify-content: center; align-items: center;">
        <br>
        <br><div>
        <span class="span0 "> SIMULATION </span>
    </div>
    </div>
    
    <div style="width: 90%">
        <br>
        <br>
    
        <mat-expansion-panel >
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span class="span2"> 
                        „Simulation“ bietet Ihnen einen georeferenzierten Rahmen für die Analyse von Erreichbarkeiten zwischen Bevölkerung und lokaler Infrastruktur.
                     </span>
                  </mat-panel-title>
      
            </mat-expansion-panel-header>
            <span class="span2" > 
                Nutzer haben die Möglichkeit, bedarfsgerechte Messungen hinsichtlich der Mobilitätsanbindung der Bevölkerung zu fahren sowie sich verfügbare Infrastrukturtypen auf der Karte anzeigen zu lassen.
              
             </span>
    
          </mat-expansion-panel>
    </div>


<br>
<br>
<div class="row2">

    <div class="datahub-tile2" (click) = clickTile(5)>
        <div>
            <span class="span2" style="font-weight: 500;"> Hitzesimulation </span>
        </div>
    </div>
  <div class="datahub-tile2" (click) = clickTile(1)>
      <div>
          <span class="span2" style="font-weight: 500;"> Virusinfektionen </span>
      </div>
  </div>

<div class="datahub-tile2" (click) = clickTile(4)>
    <div>
        <span class="span2" style="font-weight: 500;"> Mobilität </span> 
    </div>
</div>

<div class="datahub-tile2" (click) = clickTile(2)>
    <div>
        <span class="span2" style="font-weight: 500; color: gray;"> Katastrophenschutz </span> 
    </div>
</div>
<div class="datahub-tile2" (click) = clickTile(3)>
  <div>
      <span class="span2" style="font-weight: 500;color: gray"> Energie </span> 
  </div>
</div>





</div>

</div>