<div class="row2">
    <div class="datahub-tile2" (click)="clickTile(1)">
        <div style="display: flex; justify-content: center; align-items: center;flex-direction: column;">
            <span class="span2" style="text-indent: 0;"> Synthetische Population </span> <br>
            <span class="span2" style="text-indent: 0;"> Burgenlandkreis </span>
        </div>
    </div>

    <div class="datahub-tile2" (click)="clickTile(2)">
        
        <div>
            <span class="span2">  Entwicklungsprognose starten</span>
        </div>
    </div>

</div>