<div id="Dialog">
    <mat-progress-bar *ngIf="load" id="bar" mode="indeterminate"></mat-progress-bar>
    <h2>Dashboards</h2>

        <table  mat-table [dataSource]="dashboards" class="mat-elevation-z8">

            <ng-container matColumnDef="savedName">
            <th mat-header-cell *matHeaderCellDef> Dashboard Name </th>
            <td (click)="selectDashboard(dashboard)"  mat-cell *matCellDef="let dashboard" mat-raised-button color="primary"> <mat-icon>document_scanner</mat-icon> {{dashboard.savedName}} </td>
            </ng-container>

            <ng-container matColumnDef="created_At">
            <th mat-header-cell *matHeaderCellDef> Erstellt am </th>
            <td mat-cell *matCellDef="let dashboard" mat-raised-button color="primary"> <mat-icon>calendar_month</mat-icon> {{formatToGermanDate(dashboard.created_At)}} </td>
            </ng-container>

            <ng-container matColumnDef="userId">
              <th mat-header-cell *matHeaderCellDef> Erstellt von </th>
              <td mat-cell *matCellDef="let dashboard" mat-raised-button color="primary"> <mat-icon>person_outline</mat-icon>{{userNames[dashboard.userId] || 'Lädt...'}}</td>
            </ng-container>

            <ng-container matColumnDef="version">
              <th mat-header-cell *matHeaderCellDef>Version</th>
              <td mat-cell *matCellDef="let dashboard" mat-raised-button color="primary" style="display: flex;justify-content:center ;align-items: center;">
                  <mat-icon>manage_history</mat-icon>
                  <mat-form-field style="width: 100px ; margin-top: 20px;">
                      <mat-select [value]="dashboard.version" (selectionChange)="updateDashboardVersion(dashboard, $event.value)">
                          <mat-option *ngFor="let version of dashboard.availableVersions" [value]="version">{{version}}</mat-option>
                      </mat-select>
                  </mat-form-field>
              </td>
          </ng-container>
        

            <tr  mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        
        </table>

      <div class="diaFooter">
        <button  (click)="closeAll()"  mat-raised-button color="warn" >schließen</button>
      </div>


</div>
