import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RadarService {
  private apiUrl
  savedIndiForRadar:any;

  constructor(private http:HttpClient,) {
    const useLocal = false;
    this.apiUrl = useLocal ? 'http://localhost:3001/radar' : 'https://dashboardservice.cephlabs.de/radar';
   }

   async rangeEachRegion(radraArray:any){
    let url = this.apiUrl + '/rangeEachRegion'
    let body ={datasets:radraArray}
    return  await firstValueFrom(this.http.post(url, body)); 
   }

   async saveIndicators(indiArray:any){
    this.savedIndiForRadar =indiArray
   }
   
   async loadIndicators(){    
     return this.savedIndiForRadar; 
   }
 
}