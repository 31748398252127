

<div style="display: flex; flex-direction:column;  justify-content: center; align-items: center;">
    <div>
        <br>
        <br>
        <span class="span0 "> PROGNOSEN </span>
    </div>
    
    <div style="width: 90%">
        <br>
        <br>
    
        <mat-expansion-panel >
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span class="span2"> Mit dem Tool "Prognosen" können Sie die Bedarfe der lokalen Bevölkerung analysieren und auf die Zukunft prognostizieren.
                     </span>
                  </mat-panel-title>
      
            </mat-expansion-panel-header>
            <span class="span2" > 
            Nutzer können die Bedarfe der hiesigen Bevölkerung hinsichtlich der medizinischen und notfalltechnischen Infrastruktur skizzieren und räumlich analysieren. Zusätzlich stehen Vorschläge hinsichtlich der optimalen Verteilung von Notfallwachen zur Verfügung.
              
             </span>
    
          </mat-expansion-panel>
    </div>

 <div style="margin-left: 5vw; width: 95vw">

<div class="row2">
    <div class="datahub-tile2" (click)="clickTile(1)">
        <div style="display: flex; justify-content: center;align-items: center; flex-direction: column;">
            <span class="spanA" style="font-weight: 500;"> Nachfrageberechnung </span> 
            <br>
            <span class="spanA" style="font-weight: 500;" > Medizinische Infrastruktur </span>
        </div>
    </div>
    <div class="datahub-tile2" (click)="clickTile(2)">
        <div>
            <span class="span2" style="font-weight: 500; color: gray;"> Hitzehotspots </span>
        </div>
    </div>
    <div class="datahub-tile2" (click)="clickTile(3)">
        <div>
            <span class="span2" style="font-weight: 500; color: gray;"> Ärztebewegungen </span>
        </div>
    </div>
</div>

</div>

