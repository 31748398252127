import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import * as L from 'leaflet';
import { Geojson } from '../../infrastuktur/Model/Geojson';
import { StreetXpertService } from 'src/app/service/streetXpertService/street-xpert.service';
import { ActivatedRoute } from '@angular/router';
import { Chart } from 'chart.js';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';

@Component({
  selector: 'app-matsim-results',
  templateUrl: './matsim-results.component.html',
  styleUrls: ['./matsim-results.component.css']
})
export class MatsimResultsComponent implements OnInit, AfterViewInit {
  private map1: any;
  private map2: any;
  public geoJsonFeatureCollection = new Geojson();
  public geoJsonFeatureCollectionOriginal = new Geojson();
  public selectedLayer: any;
  showSpinnerWhole = false;
  private layerGroups1: any = {};
  private layerGroups2: any = {};
  private selectedProperty1: string = 'HRS0-24avg_original';
  private selectedProperty2: string = 'HRS0-24avg_updated';
  private controlLayers1: any;
  private controlLayers2: any;
  uuid: any;
  combinedBounds: any;
  bounds1: any;
  bounds2: any;
  simulationUIID: any;
  changeMessages: any[] = [];
  deleteMessages: any[] = [];
  chartData:any;
  charts: { [key: string]: Chart } = {};
  colorArray=['#023436','red','#0062ec','#fa632a'];
  legendCluster1: L.Control | undefined;
  legendCluster2: L.Control | undefined;
  travelTimeData:any={};
  originalStats: any = {};
  updatedStats: any = {};
  diffStats: any = {};
  timeCharts: { [key: string]: Chart } = {};
  @ViewChild('tabGroup1') tabGroup1!: MatTabGroup;
  @ViewChild('tabGroup2') tabGroup2!: MatTabGroup;
  
  constructor(
    private streetXpert: StreetXpertService,
    private route: ActivatedRoute
  ) {
    this.uuid = this.route.snapshot.paramMap.get('uuid');
    this.simulationUIID = this.route.snapshot.paramMap.get('simulationUIID');
  }

  async ngOnInit(): Promise<void> {
    this.geoJsonFeatureCollectionOriginal= await this.streetXpert.getOriginalGeojsonWithHRS(this.uuid, this.simulationUIID)
    console.log(this.geoJsonFeatureCollectionOriginal);
    
    this.initialization();
  }

  ngAfterViewInit(): void {
    this.initMap();
  }

  onTabChange(event: MatTabChangeEvent): void {
    if (event.index === 1) {
      this.createBarChart('barChart1', this.chartData.top_original, 'Original Network');
      this.createBarChart('barChart2', this.chartData.top_updated, 'Updated Network');
      //this.createBarChart('barChart3', this.chartData.top_diff, 'Difference');
    }
    else if (event.index === 2) {
      this.createChart('originalChart', 'Original Travel Times', this.originalStats, 'rgba(75, 192, 192, 0.2)', 'rgba(75, 192, 192, 1)');
      this.createChart('updatedChart', 'Updated Travel Times', this.updatedStats, 'rgba(255, 159, 64, 0.2)', 'rgba(255, 159, 64, 1)');
      this.createChart('diffChart', 'Difference in Travel Times', this.diffStats, 'rgba(153, 102, 255, 0.2)', 'rgba(153, 102, 255, 1)', true);
    }
  }

  initialization() {
    this.streetXpert.getGeoJsonUpdated(this.uuid, this.simulationUIID).subscribe(async (geoJsonFeatureCollection: any) => {
      this.geoJsonFeatureCollection = geoJsonFeatureCollection.geojson as Geojson;
      console.log(geoJsonFeatureCollection);
      
      this.changeMessages = geoJsonFeatureCollection.metadata.globalChanges.propertyChanges;
      this.deleteMessages = geoJsonFeatureCollection.metadata.globalChanges.deletions;

      this.chartData= await this.streetXpert.getChartStreet(this.uuid, this.simulationUIID)

      this.travelTimeData= await this.streetXpert.getTravelTime(this.uuid, this.simulationUIID)
      this.originalStats = this.travelTimeData.original_stats;
      this.updatedStats = this.travelTimeData.updated_stats;
      this.diffStats = this.travelTimeData.diff_stats;

      this.initGeoJsonLayer(this.geoJsonFeatureCollectionOriginal,this.map1, this.layerGroups1, this.controlLayers1, this.selectedProperty1);
      this.initGeoJsonLayer(this.geoJsonFeatureCollection,this.map2, this.layerGroups2, this.controlLayers2, this.selectedProperty2);
      this.setBoundaries();
    });
  }

  ////////////////////////////////////first tab/////////////////////////////
  private initMap(): void {
    const tiles = L.tileLayer(
      'https://m.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png',
      {
        maxZoom: 18,
        minZoom: 3,
        attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      }
    );

    const tiles2 = L.tileLayer(
      'https://m.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png',
      {
        maxZoom: 18,
        minZoom: 3,
        attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      }
    );

    this.map1 = L.map('map1', {
      center: [51.1548898, 11.81000],
      zoom: 13,
      layers: [tiles]
    });

    this.map2 = L.map('map2', {
      center: [51.1548898, 11.81000],
      zoom: 13,
      layers: [tiles2]
    });

    this.controlLayers1 = L.control.layers({}, {}).addTo(this.map1);
    this.controlLayers2 = L.control.layers({}, {}).addTo(this.map2);
  }

  private setBoundaries() {
    const layer1 = L.geoJSON(this.geoJsonFeatureCollectionOriginal as any, {});
    const layer2 = L.geoJSON(this.geoJsonFeatureCollection as any, {});
    this.bounds1 = layer1.getBounds();
    this.bounds2 = layer2.getBounds();
    this.map1.fitBounds(this.bounds1);
    this.map2.fitBounds(this.bounds2);
  }

  private initGeoJsonLayer(geoJson:any,map: any, layerGroups: any, controlLayers: any, selectedProperty: string) {
    Object.values(layerGroups).forEach(layerGroup => map.removeLayer(layerGroup));
    if (controlLayers) controlLayers.remove();
  
    layerGroups.highDiff = L.layerGroup();
    layerGroups.moderateDiff = L.layerGroup();
    layerGroups.lowDiff = L.layerGroup();
    layerGroups.noDiff = L.layerGroup();
  
    //const allValues = geoJson.features!.map((feature: any) => feature.properties[selectedProperty]); // for changing the legend every property switch
    const allValues = this.geoJsonFeatureCollectionOriginal.features!.map((feature: any) => feature.properties['HRS0-24avg_original']);
    console.log(allValues);
    
    const breaksAmount = 4

    const avoidUndefined = breaksAmount + 1
    allValues.sort((a: number, b: number) => a - b);
  
    const breaks: number[] = [];
    for (let i = 0; i < avoidUndefined; i++) {
      const breakPoint = allValues[Math.floor((i / avoidUndefined) * (allValues.length - 1))];
      breaks.push(breakPoint);
    }

    this.addFeaturesToLayerGroups(geoJson,layerGroups, selectedProperty,breaks);
  
    Object.values(layerGroups).forEach((layerGroup: any) => layerGroup.addTo(map));
  
    controlLayers = L.control.layers({}, {
      'Hohes Verkehrsvolumen': layerGroups.highDiff,
      'Mittleres Verkehrsvolumen': layerGroups.moderateDiff,
      'Geringes Verkehrsvolumen': layerGroups.lowDiff,
      'Sehr geringes Verkehrsvolumen': layerGroups.noDiff
    }).addTo(map);
  

    if (map === this.map1) {
      this.controlLayers1 = controlLayers;
      this.createLegend(map, breaksAmount, this.colorArray,breaks,this.legendCluster1);
    } else if (map === this.map2) {
      this.controlLayers2 = controlLayers;
      this.createLegend(map, breaksAmount, this.colorArray,breaks,this.legendCluster2);
    }
  }
  
  private addFeaturesToLayerGroups(geoJson:any,layerGroups: any, selectedProperty: string,breaks:any) {
    L.geoJSON(geoJson as any, {
        pointToLayer: (feature, latlng) => {
            const geojsonMarkerOptions: any = {
                radius: 1,
                fillColor: "#ff7800",
                color: "#000",
                weight: 1,
                opacity: 1,
                fillOpacity: 0.8
            };
            return L.circleMarker(latlng, geojsonMarkerOptions);
        },
        style: (feature: any) => {
            const value = feature.properties[selectedProperty];
            const color = this.getColor(value,breaks);
            return { color: color };
        },
        onEachFeature: (feature, layer) => {
            const value = feature.properties[selectedProperty];

            layer.bindTooltip(`Value: ${value}`, {
                permanent: false,
                direction: 'top',
                opacity: 0.7,
                className: 'feature-tooltip' // can be customised
            });

            layer.on('click', (e: any) => {
                this.selectedLayer = layer;
            });

            if (value > breaks[3]) {
                layerGroups.highDiff.addLayer(layer);
            } else if (value > breaks[2]) {
                layerGroups.moderateDiff.addLayer(layer);
            } else if (value > breaks[1]) {
                layerGroups.lowDiff.addLayer(layer);
            } else {
                layerGroups.noDiff.addLayer(layer);
            }
        }
    });
}


  private getColor(value: number, breaks:any): string {
    if (value > breaks[3]) {
      return '#fa632a';
    } else if (value > breaks[2]) {
      return '#0062ec';
    } else if (value > breaks[1]) {
      return 'red';
    } else {
      return '#023436';
    }
  }

  switchProperty(property: string, mapId: string) {
    if (mapId === 'map1') {
      console.log(property);
      
      this.selectedProperty1 = property;
      this.initGeoJsonLayer(this.geoJsonFeatureCollectionOriginal,this.map1, this.layerGroups1, this.controlLayers1, this.selectedProperty1);
    } else if (mapId === 'map2') {
      this.selectedProperty2 = property;
      this.initGeoJsonLayer(this.geoJsonFeatureCollection,this.map2, this.layerGroups2, this.controlLayers2, this.selectedProperty2);
    }
  }

  onPropertyChange(event: Event, mapId: string) {
    const selectElement = event.target as HTMLSelectElement;
    this.switchProperty(selectElement.value, mapId);
  }
  
  createLegend(map: any, breaksAmount: any, colorArray: any, breaks: any, legendCluster: L.Control | undefined) {
    if (legendCluster) {
      map.removeControl(legendCluster);   
    }
  
    legendCluster = new L.Control({ position: 'bottomleft' });
    legendCluster.onAdd = () => {
      const div = L.DomUtil.create('div', 'info legend');
      const labels = ['<div><b>Vehicle/hour</b></div>'];
  
      for (let i = 0; i < breaksAmount; i++) {
        labels.push(
          '<div style="display: flex; gap: 15px;">' +
            '<div style="background-color:' + colorArray[i] +
            '; width: 40px; height: 20px;"></div>' +
            '<div><b> [' +
            breaks[i].toFixed(2) + ' , ' + (breaks[i + 1] !== undefined ? breaks[i + 1].toFixed(2) : '+∞') + ') </b></div>' +
          '</div>'
        );
      }
  
      div.innerHTML = labels.join('<br>');
      div.style.backgroundColor = 'rgba(255, 255, 255, 0.7)';
      div.style.padding = '5px';
      div.style.width = '170px';
      div.style.border = '1px solid #00695C';
      div.style.borderRadius = '8px';
      return div;
    };
  
    legendCluster.addTo(map);
  
    if (map === this.map1) {
      this.legendCluster1 = legendCluster;
    } else if (map === this.map2) {
      this.legendCluster2 = legendCluster;
    }
  }

///////////////////////// second tab /////////////////////////////////
createBarChart(chartId: string, data: any[], label: string): void {
  const ctx:any = (document.getElementById(chartId) as HTMLCanvasElement)?.getContext('2d');
  if (this.charts[chartId]) this.charts[chartId].destroy();

  this.charts[chartId] = new Chart(ctx, {
    type: 'bar',
    data: {
      labels: data.map(d => d.street_name),
      datasets: [{
        label: label,
        data: data.map(d => d.value),
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1
      }]
    },
    options: {
      scales: {
        y: {
          beginAtZero: true
        }
      }
    }
  });
}

////////////////////////////// third tab ///////////////////////////

timeStringToMinutes(timeString: string): number {
  const isNegative = timeString.startsWith('-');
  const parts = timeString.replace('-', '').split(':');
  const hours = parseInt(parts[0], 10);
  const minutes = parseInt(parts[1], 10);
  const seconds = parseInt(parts[2], 10);
  let totalMinutes = hours * 60 + minutes + seconds / 60;

  return isNegative ? -totalMinutes : totalMinutes;
}

formatTimeTooltip(minutes: number): string {
  const isNegative = minutes < 0;
  const absMinutes = Math.abs(minutes);
  const hours = Math.floor(absMinutes / 60);
  const remainingMinutes = Math.floor(absMinutes % 60);
  const seconds = Math.floor((absMinutes % 1) * 60);

  const timeString = `${hours.toString().padStart(2, '0')}:${remainingMinutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  
  return isNegative ? `-${timeString}` : timeString;
}


  createChart(canvasId: string, label: string, stats: any, backgroundColor: string, borderColor: string, isDiffChart = false) {
    const ctx:any = (document.getElementById(canvasId) as HTMLCanvasElement)?.getContext('2d');
    if (this.timeCharts[canvasId]) this.timeCharts[canvasId].destroy();

    const data = isDiffChart ? {
      avgToWork: this.timeStringToMinutes(stats.diff_average_ToWork),
      avgFromWork: this.timeStringToMinutes(stats.diff_average_FromWork),
      medianToWork: this.timeStringToMinutes(stats.diff_median_ToWork),
      medianFromWork: this.timeStringToMinutes(stats.diff_median_FromWork),
    } : {
      avgToWork: this.timeStringToMinutes(stats.average_ToWork),
      avgFromWork: this.timeStringToMinutes(stats.average_FromWork),
      medianToWork: this.timeStringToMinutes(stats.median_ToWork),
      medianFromWork: this.timeStringToMinutes(stats.median_FromWork),
    };
  
    this.timeCharts[canvasId]=new Chart(ctx, {
      type: 'bar',
      data: {
        labels: ['Avg to Work', 'Avg from Work', 'Median to Work', 'Median from Work'],
        datasets: [{
          label: `${label} (Minutes)`,
          data: [
            data.avgToWork,
            data.avgFromWork,
            data.medianToWork,
            data.medianFromWork
          ],
          backgroundColor: backgroundColor,
          borderColor: borderColor,
          borderWidth: 1
        }]
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
            title: {
              display: true,
              text: 'Minutes'
            }
          }
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: (tooltipItem) => {
                const minutes:any = tooltipItem.raw;
                return `Time: ${this.formatTimeTooltip(minutes)}`;
              }
            }
          }
        }
      }
    });
  }

  //////////////////////////////////4th tab////////////////////////////////////
  flyToCoordinates(coords: { lat: number, lng: number },tabIndex: number) {
    if (this.map1) {
      this.map1.setView([coords.lat, coords.lng], 20); 
    }
    if (this.map2) {
      this.map2.setView([coords.lat, coords.lng], 20); 
    }
    this.tabGroup1.selectedIndex = tabIndex;
  }
   
}
