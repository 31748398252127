

<div id="Dialog" *ngIf="createNewDashboard">
    <mat-progress-bar *ngIf="load" id="bar" mode="indeterminate"></mat-progress-bar>

    <h3>wollen Sie diesen Status speichern?</h3>

    <div>
        <mat-form-field appearance="outline" style="width: 400px;">
            <mat-label>Bezeichnung des Dashboards speichern</mat-label>
            <input matInput placeholder="Geben Sie den Dashboard-Namen ein..." [(ngModel)]="savedName" name="name">            
        </mat-form-field>
    </div>

   

    <div class="diaFooter">
        <button mat-raised-button color="primary" (click)="CreatFirstVersion()">Speichern</button>
        <button  (click)="closeAll()"  mat-raised-button color="warn" >schließen</button>
      </div>
</div>


<div id="Dialog" *ngIf="!createNewDashboard">
  <mat-progress-bar *ngIf="load" id="bar" mode="indeterminate"></mat-progress-bar>
    <h4>wollen Sie eine neue Version erstellen oder ein neues Dashboard:</h4>

<mat-tab-group>
  <mat-tab label="Neues Dashboard">
    <div id="Dialog">

      <div>
        <mat-form-field appearance="outline" style="width: 400px;">
          <mat-label>Bezeichnung des Dashboards speichern</mat-label>
          <input matInput placeholder="Geben Sie den Dashboard-Namen ein..." [(ngModel)]="savedName" name="name">
        </mat-form-field>
      </div>

      <div class="diaFooter">
        <button mat-raised-button color="primary" (click)="CreatFirstVersion()">Speichern</button>
        <button (click)="closeAll()" mat-raised-button color="warn">Schließen</button>
      </div>
    </div>
  </mat-tab>
  
  <mat-tab label="Neue Version">
    <div id="Dialog">


      <div class="diaFooter">
        <button mat-raised-button color="primary" (click)="createNewVersion()">Neue Version speichern</button>
        <button (click)="closeAll()" mat-raised-button color="warn">Schließen</button>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>

</div>
