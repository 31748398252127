

<div class="main-container">

    <div class="row">

        <div>
            <br>
            <br>
            <span class="span0 "> REGIONALINDIKATOREN NACH GEBIETSEINHEITEN </span>
        </div>

    </div>


    <div class="row2">
        
            <div class="bottomTri">
                <div class="datahub-tile2" (click)="clickTile('/dashboards')">
                    <div>
                        <span class="span2">Dashboards </span>
                    </div>
                </div>

                <div class="datahub-tile2" (click)="clickTile('/datahub-resourcen-modifizieren')">
                    <div>
                        <span class="span2" style="text-indent: 0;"> Datahub-ressourcen</span> <br>
                        <span class="span2" style="text-indent: 0;">modifizieren</span>
                    </div>
                </div>
            </div>


    </div>

</div>
