import { ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-data-regio',
  templateUrl: './data-regio.component.html',
  styleUrls: ['./data-regio.component.css'],
})
export class DataRegioComponent {
  constructor(private router: Router,private cdr: ChangeDetectorRef) {}

  clickTile(viewMode: string){
    this.router.navigateByUrl(viewMode); 
  }

}