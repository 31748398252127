import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SynthService {
  private apiUrl 

  constructor(private http:HttpClient,) {
    const useLocal = false;
    this.apiUrl = useLocal ? 'http://localhost:3000/synth' : 'https://dashboardservice.cephlabs.de/synth';
   }

   getPopuationNames(){
    const url = this.apiUrl + '/getPopuationNames';
    return firstValueFrom(this.http.get(url))
   }

   getInitialChartData(indicator: string, populationsArray: string[]){
    const url = `${this.apiUrl}/getInitialChartData`;
    return firstValueFrom(this.http.post(url, { indicator, populationsArray }))
  }

  getRadarChartData(population: string) {
    const url = `${this.apiUrl}/getRadarChartData`;
    return firstValueFrom(this.http.post(url, {population}))
  }

}
