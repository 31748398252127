<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
  <mat-tree-node
    *matTreeNodeDef="let node"
    matTreeNodePadding
    (click)="onNodeClick(node)"
    [ngClass]="{ 'selected-node': isSelected(node) }"
    class="mat-tree-node-hover"
  >
    <button mat-icon-button disabled></button>
    {{ node.name }}
  </mat-tree-node>
  <mat-tree-node
    *matTreeNodeDef="let node; when: hasChild"
    matTreeNodePadding
    [ngClass]="{ 'selected-node': isSelected(node) }"
    class="mat-tree-node-hover"
    (click)="onNodeClick(node)"
  >
    <button
      mat-icon-button
      matTreeNodeToggle
      [attr.aria-label]="'toggle ' + node.name"
    >
      <mat-icon>
        {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
      </mat-icon>
    </button>
    {{ node.name }}
  </mat-tree-node>
</mat-tree>
