import { Component } from '@angular/core';

@Component({
  selector: 'app-datahub-ressourcen-modifizieren',
  templateUrl: './datahub-ressourcen-modifizieren.component.html',
  styleUrls: ['./datahub-ressourcen-modifizieren.component.css']
})
export class DatahubRessourcenModifizierenComponent {

}
