<!--<div id="cesium"></div>-->
<!-- step SA -->
<!-- <div >
    <div>
    </div>

    <div style="display: flex; flex-direction: column; justify-content: center;">

        <div class="info">
            <span class="span0"> Digitaler Zwilling. </span> <span class="span1"> Wähle deinen Landkreis. </span>
            <br>
            <br>
        </div>


        <div style="display: flex; flex-direction: row; justify-content: center; align-items: center; width: 95vw;">
            <div class="map-cont">
                <div class="map" leaflet [leafletOptions]="options" [leafletLayers]="layers"
                    (leafletMapReady)="onMapReady($event)"></div>
                <div style="display: flex; align-items: center;">
                    <h3 style="margin-left: 48%"> Sachsen-Anhalt </h3>
                </div>
            </div>


            <div   id="infoLK" style="width: 30%">

   

                <div   style=" height: 80%; width: 100%;">

                    <div id="logoLK" style="display: flex; align-items: center; margin-bottom: 8%;" > 

                        
                    <img  src="../../../assets/Wappen_von_Unstruttal.png" width="50px" />

               
                <div style="margin-left: 5%;"> 
                    <span class="span0"> Unstruttal </span> <br>
                </div>
                    

                </div>

                    <div id="infoOneLK">



                           

                            <span class="span2">  Bevölkerung: 15234 Einwohner </span> <br>
                                <span class="span2"> Bodenfläche: 199 km²</span> <br>
                                    <span class="span2"> Durchschnittsalter:  46,95 Jahre</span> <br>



                    </div>

                </div>



                <br>
                <br>


                <button mat-raised-button style="background-color: #D4E5C6; color: black" (click)="test()"
                    > Verbandsgemeinde festlegen </button>

            </div>

        </div>

    </div>
</div> -->



<!-- step BLK -->

<!-- <div >
    <div>
    </div>

    <div style="display: flex; flex-direction: column; justify-content: center;">

        <div class="info">
            <span class="span0"> Digitaler Zwilling. </span> <span class="span1">Interkommunales Projektkonsortium</span>
            <br>
            <br>
        </div>


        <div style="display: flex; flex-direction: row; justify-content: center; align-items: center; width: 95vw;">
            <div class="map-cont">
                <div class="map" leaflet [leafletOptions]="options" [leafletLayers]="layers"
                    (leafletMapReady)="onMapReady($event)"></div>
                <div style="display: flex; align-items: center;">
                    <h3 style="margin-left: 48%"> Burgenlandkreis </h3>
                </div>
            </div>


            <div   id="infoLK" style="width: 40%">

   

                <div   style=" height: 80%; width: 100%;">

 

                    <div id="logoLK" style="display: flex; align-items: center;  justify-content: center; margin-bottom: 8%; gap: 40px;" > 
                            <div style="display: flex; align-items: center;  justify-content: center; flex-direction: column;">
                                <img  src="../../../assets/Führt_kein_Wappen.svg.png" width="50px" />
                                <div > 
                                    <span class="span0"> Gleina </span> <br>
                                </div>
                            </div>

                            <div style="display: flex; align-items: center;  justify-content: center; flex-direction: column;"> 
                                <img  src="../../../assets/Führt_kein_Wappen.svg.png" width="50px" />
                                <div > 
                                    <span class="span0"> Steigra </span> <br>
                                </div>
                            </div>

                            <div style="display: flex; align-items: center;  justify-content: center; flex-direction: column;"> 
                                <img  src="../../../assets/Wappen_Freyburg_(Unstrut).png" width="50px" />
                                <div > 
                                    <span class="span0"> Freyburg (Unstrut)</span> <br>
                                </div>
                            </div>
                    </div>



                                
                    <div id="infoOneLK">



                           <h1>Interkommunales Projektkonsortium</h1>

                            <span class="span2">  Bevölkerung: 6886 Einwohner </span> <br>
                                <span class="span2"> Bodenfläche: 105,95 km² </span> <br>
                                    <span class="span2"> Durchschnittsalter:  48,55 Jahre </span> <br>



                    </div>

                </div>



                <br>
                <br>


                <button mat-raised-button style="background-color: #D4E5C6; color: black" (click)="test()"
                    > Gemeinden festlegen </button>

            </div>

        </div>

    </div>
</div> -->


<!--<div id="cesium"></div>-->




<div >
    <div>
    </div>

    <div style="display: flex; flex-direction: column; justify-content: center;">

        <div class="info">
            <span class="span0"> Digitaler Zwilling. </span> <span class="span1"> Wähle deinen Landkreis. </span>
            <br>
            <br>
        </div>


        <div style="display: flex; flex-direction: row; justify-content: center; align-items: center; width: 95vw;">
            <div class="map-cont">
                <div class="map" leaflet [leafletOptions]="options" [leafletLayers]="layers"
                    (leafletMapReady)="onMapReady($event)"></div>
                <div style="display: flex; align-items: center;">
                    <h3 style="margin-left: 48%"> Sachsen-Anhalt </h3>
                </div>
            </div>


            <div  *ngIf="selectedObject" id="infoLK" style="width: 30%">

   

                <div *ngIf="selectedObject"  style=" height: 80%; width: 100%;">

                    <div id="logoLK" style="display: flex; align-items: center; margin-bottom: 8%;" > 

                        
                    <img *ngIf="selectedObject.Logo_URL" src="{{ selectedObject.Logo_URL }}" width="50px" />

               
                <div style="margin-left: 5%;"> 
                    <span class="span0"> {{ selectedObject.Object_Name}}</span> <br>
                </div>
                    

                </div>

                    <div id="infoOneLK">



                           

                            <span class="span2">  Bevölkerung: {{ selectedObject["Bevoelkerung gesamt"]}} Einwohner </span> <br>
                                <span class="span2"> Bodenfläche: {{ selectedObject["Bodenflaeche gesamt qkm"]}} km²</span> <br>
                                    <span class="span2"> Durchschnittsalter: {{ selectedObject["Durchschnittsalter der Bevoelkerung"]}} Jahre</span> <br>



                    </div>

                </div>



                <br>
                <br>


                <button mat-raised-button style="background-color: #D4E5C6; color: black" (click)="test()"
                    *ngIf="selectedObject"> Landkreis festlegen </button>

            </div>

        </div>

    </div>
</div>


<br>

<br>
