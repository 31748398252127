import { Component, OnInit } from '@angular/core';
import { DatahubService } from 'src/app/service/datahubservice/datahubservice.component';
import { KeycloakOperationService } from 'src/app/service/keycloakService/keycloak-operation.service';

@Component({
  selector: 'app-nachfrageberechnung-new',
  templateUrl: './nachfrageberechnung-new.component.html',
  styleUrls: ['./nachfrageberechnung-new.component.css']
})
export class NachfrageberechnungNewComponent implements OnInit{
selectedItem: any;
clickItem(item: any) {
  if(this.selectedItem == item) {
    this.selectedItem = undefined;
    return; 
  }

    this.selectedItem = item;

}


filterItem(item: any): any {
if(!this.searchBox) {
  return true
}


  if (item.name.toLowerCase().includes(this.searchBox.toLowerCase())) {
    return true; 
  }

}
storedObject: any[] = []
  currentUser: any;
  async ngOnInit(): Promise<void> {




}
async getStoredItems() {
  //@ts-ignore
var storedObjectAll =  await this.datahubService.getNachfrageData();
//@ts-ignore
storedObjectAll.forEach((element: { owner: any; visibility: string; canRead: string | any[]; canWrite: string | any[]; }) => {

  
  if(this.currentUser && element.owner  == this.currentUser.id) {
    this.storedObject.push(element);
  } else {
    if(element.visibility == 'public') {
      this.storedObject.push(element);
    } else {
      if( this.currentUser &&  element.visibility == 'protected' &&(element.canRead.indexOf(this.currentUser.id) > -1 || element.canWrite.indexOf(this.currentUser.id) > -1 )) {
        this.storedObject.push(element);

      }
    }

  }
  
});

}


constructor(private datahubService: DatahubService, private keyCloakService: KeycloakOperationService,) {
  this.keyCloakService.getUsersList().subscribe((users: any) => {
    console.log(users);
    //this.userList=users
  });

  this.keyCloakService.getUserProfile().then(async (data: any) => {
    this.currentUser= data;
    console.warn("DATA", data)
    await this.getStoredItems(); 

  });

  

}
searchBox: any;
public start: any = true;



}
