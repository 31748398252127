import { Component, ViewChild } from '@angular/core';
import { Storageservice } from './service/storageservice-component/storageservice-component.component';
import { KeycloakOperationService } from './service/keycloakService/keycloak-operation.service';
import { ChatService } from './service/chatService/chat.service';
import { NotifierService } from 'angular-notifier';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'hongkong';
  userProfile: any | null = null;
  private notifier: NotifierService;
  users:any;
  showButton = false;
  @ViewChild('drawer') drawer!: any;

  constructor(private storageService: Storageservice,
              private keyCloakService: KeycloakOperationService,
              private chatService: ChatService,
              private notifierService: NotifierService
  ){
    this.storageService.deleteObject(); 
    this.notifier = this.notifierService;
  }

  ngOnInit(): void {
    
    this.keyCloakService.getUsersList().subscribe((users: any) => {
      this.users=users
    });

    this.keyCloakService.getRolesMap().subscribe((users: any) => {
    });

    this.keyCloakService.getRoles().subscribe((users: any) => {
    });

    // this is imortant to see what custom roles are assigned like normaluser
    // disabled for now...
    // may be usefull in the fututre
    // this.keyCloakService.getRole2().subscribe((users: any) => {
    //   console.log(users);
    // });
    
    this.keyCloakService.getUserProfile().then((data: any) => {
      this.userProfile = data;
      this.chatService.connectUser(this.userProfile.id);
      this.chatService.notifyUser(this.userProfile.id);
    });

    this.chatService.getNotificationUpdates()?.subscribe((response) => {
      const notificationType = response.notified;
      const notificationsCount = response.notifications;
      this.chatService.updateNotificationBadge(response.receiver, notificationsCount , this.users); 
      let text:string='';

      if (!window.location.href.includes('nachrichten')){  
        switch (notificationType) {
          case 'sendPrivateMessage':
            text = 'Sie haben eine neue Privat Nachricht bekommen.'
            this.showNotification('success', text);
            break;
          case 'sendGroupMessage':
            text = 'Sie haben eine neue Gruppen Nachricht bekommen.'
            this.showNotification('warning', text);
            break;
          case 'setUserInGroup':
            text = 'Sie sind in einer neuen Gruppe hinzugefügt.'
            this.showNotification('info',text);
            break;
          case 'dont Show':
            console.log('reset');
            break;
          default:
              this.showNotification('success', 'Sie haben eine neue Privat Nachricht bekommen.');
            break;
        }
      }

    });

  }

  public showNotification( type: string, message: string ): void {
		this.notifier.notify( type, message );
	}

  toggleDrawer() {
    this.drawer.toggle();
  }

}
