


<div style="margin: 1% 5% 1% 5%"> 
    <div style="display: flex; justify-content: center; align-items: center;">
        <br>
        <br><div>
        <span class="span0 ">SIMULATION. VIRUSINFEKTION</span>
    </div>
    </div>
    



<br>
<br>
<div class="row2">
  <div class="datahub-tile2" (click) = clickTile(1)>
      <div>
          <span class="span2" style="font-weight: 500;"> Covid-19</span>
      </div>
  </div>
  <div class="datahub-tile2" (click) = clickTile(2)>
      <div>
          <span class="span2" style="font-weight: 500;color: gray"> Influenza </span> 
      </div>
  </div>





</div>

    <button mat-mini-fab aria-label="Example icon button with a home icon" style="background-color: #10684E; color: white;position: absolute; bottom: 30px;"
    (click) = clickTile(3)>
      <mat-icon>arrow_back_ios_new</mat-icon>
    </button>

</div>