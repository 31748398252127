import { Component } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RegioService } from 'src/app/service/regioservice/regioservice.component';
import { Storageservice } from 'src/app/service/storageservice-component/storageservice-component.component';

@Component({
  selector: 'app-gebiete-dialog',
  templateUrl: './gebiete-dialog.component.html',
  styleUrls: ['./gebiete-dialog.component.css'],
})
export class GebieteDialogComponent {
  selectedObject: any;
  resMul: any[] = [];
  selectedShapes: any[] = [];

  constructor(
    public dialogRef: MatDialogRef<GebieteDialogComponent>,
    private regioService: RegioService,
    public storageService: Storageservice
  ) {
    this.selectedObject = this.storageService.getObject();
    this.initLayer();
  }

  toggleShape(shape: any, isChecked: boolean) {
    if (isChecked) this.selectedShapes.push(shape);
  }

  close() {
    console.warn('shapes', this.selectedShapes);
    this.dialogRef.close({ res: this.selectedShapes });
  }

  async initLayer() {
    console.log(this.selectedObject);
    
    var res: any[] = await this.regioService.getShapefileByIdAndLevel(this.selectedObject.Object_Key,4);
    console.log(res);
    
    var res2: any[] = await this.regioService.getShapefileByIdAndLevel(this.selectedObject.Object_Key,3);
    console.log(res2);
    this.resMul = res[0];
    this.resMul.push(res2[0][0]);
  }

}
