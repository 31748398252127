<br>
<br>

<div class="content">


    <div class="datahub-tile0" (click)="push(0)">


        <div>
            <span class="span2"> {{ shownContent[0].title }}</span> 
        </div>
    </div>


    <div class="datahub-tile1" (click)="push(1)">
        <div>
            <span class="span2"> {{ shownContent[1].title }}</span> 
        </div>
    </div>
    
    <div class="datahub-tile2" (click)="push(2)">
        

        <div>
            <span class="span2"> {{ shownContent[2].title }}</span> 
        </div>
        
    </div>

    <div class="datahub-tile3" (click)="push(3)">
        <div>
            <span class="span2"> {{ shownContent[3].title }} </span> <br>
            
        </div>
    </div>


    <div class="datahub-tile4" (click)="push(4)">
        <div>
            <span class="span2"> {{ shownContent[4].title }} </span> 
        </div>
    </div>

  



</div>
