<div class="main">
  
  <div class="inner-main">
  
    <div class="header">
      <h1>Interactive MATSim Network Modification on the Map</h1>
    </div>
    <!-- This one has no width -->
    <div
    *ngIf="isStarted"
    class="iteration-info"
    style="font-size: 18px; font-weight: bold"
    >
    <ng-container [ngSwitch]="true">
      <ng-container *ngSwitchCase="isNaNconfigIterationNumber()">
        The simulation completed <span style="color: red">{{ newCount }}</span
        >/0 Iteration
      </ng-container>
      <ng-container *ngSwitchCase="!isNaNconfigIterationNumber()">
        The simulation completed <span style="color: red">{{ newCount }}</span
        >/{{ configIterationNumber }} Iterations ({{getProgress()}}%)
      </ng-container>
    </ng-container>
    </div>
    
    <div *ngIf="isStarted" class="progress">
      <mat-progress-bar 
      mode="determinate" 
      [value]="getProgress()">

    </mat-progress-bar>
    
 
    
    
    </div>
    
    
        <!-- <div class="clock-container">
    <div *ngIf="isStarted" class="clock-box">
      <div class="clock-time">
        Estimated Simulated Time: {{ sumHours | number: '2.0' }}:{{ sumMinutes | number: '2.0' }}:{{ sumSeconds | number: '2.0' }}
      </div>
    </div>
    
    <div *ngIf="isStarted" class="clock-box">
      <div class="clock-time">
        Estimated Remaining Time:
        <ng-container [ngSwitch]="true">
          <ng-container
            *ngSwitchCase="
              (multipliedSumHours > 0 ||
                multipliedSumMinutes > 0 ||
                multipliedSumSeconds > 0) &&
              newCount != configIterationNumber
            "
          >
            {{ multipliedSumHours | number : "2.0" }}:{{
              multipliedSumMinutes | number : "2.0"
            }}:{{ multipliedSumSeconds | number : "2.0" }}
          </ng-container>
          <ng-container *ngSwitchDefault> 00:00:00 </ng-container>
        </ng-container>
      </div>
    </div>
    </div> -->
    
    
    
    
    
    
    
    <div class="file-upload">
    
      <div class="button-container">
        <!-- <div class="progress-container">
    
          <button class="btn btn-primary loading" type="button" disabled *ngIf="matsimFinished">
            <span class="spinner-border spinner-border-sm" role="status" ></span>
            Loading...
          </button>
    
        </div> -->
    
    
    
        <button id="map-btn" [disabled]="!matsimFinished" (click)="goToResult()">zu Ergebnis Network</button>
        <button id="matsim-btn" (click)="startSimulation()" [disabled]="isSimulation">Start MATSim Simulation</button>
        <!-- <button id="matsim-btn" (click)="triggerLinkStats()" >linkstats</button> -->
    
    
        <!-- <div class="progress-container">
    
          <button class="btn btn-primary loading" type="button" disabled *ngIf="isSimulation">
            <span class="spinner-border spinner-border-sm" role="status" ></span>
            Loading...
          </button>
    
        </div> -->
    
        <div class="success-overlay" *ngIf="!visibleSpinnerSim && !isSimulation&& !isfailed">
          <div class="icon-container">
            <i class="bi bi-check2-all done"></i>
            <span class="upload-success"> MATSim Simulation completed successfully.</span>
    
          </div>
        </div>
        <div class="success-overlay" *ngIf="isfailed && !isSimulation">
          <div class="icon-container">
            <i class="bi bi-x-lg matsimx"></i>
          </div>
          <div class="message-container">
            <span class="upload-success">MATSim Simulation failed.</span>
    
          </div>
        </div>
      </div>
    
    </div>
  
  </div>
  
  
  
  </div>