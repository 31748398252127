import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ChatService } from 'src/app/service/chatService/chat.service';
import { KeycloakOperationService } from 'src/app/service/keycloakService/keycloak-operation.service';
import { MatDialog } from '@angular/material/dialog';
import { AddProjectDialogComponent } from './add-project-dialog/add-project-dialog.component';
import { UserListDialogComponent } from './user-list-dialog/user-list-dialog.component';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent  implements OnInit, OnDestroy{

  users!: any[];
  messages: any[]=[];
  messageContent: string='';
  currentUser!: any;
  selectedUser!: any;
  showEmojiPicker = false;
  filteredUsers: any[] = [];
  searchQuery: string = '';
  projects:any=[]
  isGroupChat=false;
  group:any;
  isGoldMode: boolean = false; 
  starredUsers: any[] = [];
  //notificationsCount: number=10;
  //notificationsCount

  constructor(
    private keyCloakService: KeycloakOperationService,
    private chatService: ChatService,
    private sanitizer: DomSanitizer,
    public dialog: MatDialog,
    ) 
    {}
  
  ngOnInit(){
    
    this.keyCloakService.getUserProfile().then((data: any) => {
      this.currentUser = data;
      this.chatService.connectUser(this.currentUser.id);

      this.chatService.getGroups().subscribe(groups => {
        this.projects = groups.filter(group => 
          group.users.some((user:any) => user.id === this.currentUser.id)
        );
      });

      this.keyCloakService.getUsersList().subscribe((users: any) => {
        this.chatService.loadNotifications().subscribe((notificationsContUserObject: any) => {  
      
          this.users = users.map((user: any) => {
            const notification = notificationsContUserObject.find((notification: any) => notification.sender === user.id);
            return {
              ...user,
              notificationsCount: notification ? notification.count[this.currentUser.id] ?? 0 : 0
            };
          });
          
          this.filteredUsers = this.users;
          this.updateFilteredUsers();
      
          this.chatService.getOnlineUsers().subscribe(onlineUsers => {
            this.users.forEach(user => {
              user.isOnline = !!onlineUsers[user.id];
            });
      
            this.chatService.getUserStatusUpdates().subscribe(statusUpdate => {
              const user = this.users.find(u => u.id === statusUpdate.userId);
              if (user) {
                user.isOnline = statusUpdate.isOnline;
              }
            });
          });
        });
      });
      
    });

    this.chatService.getMessageUpdates().subscribe(message => {
      this.messages.push(message);
      console.log(this.messages);
    });

    this.chatService.getMessageUpdatesGroup().subscribe(message => {
      this.messages.push(message);
      console.log(this.messages);
    });

    this.chatService.getGroupeUpdates().subscribe(response => {
      const newGroupe={
        projectName:response.group.projectName,
        users:response.group.users,
        _id:response.groupId
      }
      this.projects.push(newGroupe);
    });

    this.chatService.setUniqueUSerNotification().subscribe((users: any) => {
      console.log(users);
      
      let UserWithUpdatedNotification = this.chatService.getUserWithUpdatedNotification();
      let existingUser = this.users.find((u: any) => u.id === UserWithUpdatedNotification.id);
      if (existingUser) {
        existingUser.notificationsCount = UserWithUpdatedNotification.notificationsCount;
      }

      if(users){
        // this.users = users.map((user: any) => {
        //   //user.notificationsCount??= 0; 
        //   return user;
        // });        
        this.filteredUsers = this.users;
        this.updateFilteredUsers(); 
        console.log(users);
      }
  
    });
    

    
    // this.chatService.getNotificationUpdates().subscribe(notification => {
    //   if (notification.type === 'message') {
    //     const user = this.users.find(u => u.id === notification.message.from);
    //     if (user) {
    //       user.notificationCount += 1;
    //     }
    //   } 
      // else if (notification.type === 'groupMessage') {
      //   notification.message.to.users.forEach((user: any) => {
      //     if (user.id !== this.currentUser.id) {
      //       const notifiedUser = this.users.find(u => u.id === user.id);
      //       if (notifiedUser) {
      //         notifiedUser.notificationCount += 1;
      //       }
      //     }
      //   });
      // }
    //});
    
  }

  ngOnDestroy(){
  }

  onSelectUser(receiver: any) {
    this.isGroupChat=false;
    this.selectedUser = receiver;
    this.selectedUser.notificationsCount = 0; 
    this.chatService.clearNotif(this.selectedUser.id,this.currentUser.id).subscribe((res:any)=>{
      console.log(res);  
    })
    this.chatService.joinRoom(this.currentUser.id, this.selectedUser.id);
    this.chatService.loadMessages(this.currentUser.id, this.selectedUser.id).subscribe((result: any) => {
        this.messages = result;
    });
  }
  
  sendMessage() {   
    if (this.messageContent.trim()) {
          const message = {
              from: this.currentUser.id,
              to: this.isGroupChat? this.group._id:this.selectedUser.id,
              content: this.messageContent,
              //notification: this.notificationsCount,
              timestamp: new Date()  
          };
          this.isGroupChat?
          this.chatService.saveMessageGroupe(message).subscribe((result)=> {
              console.log(result);
              this.messageContent = '';
              const userIds= this.group.users.map((user:any)=>{ return user.id}).filter((id: any) => id !== this.currentUser.id);
              console.log(userIds)
              this.chatService.getNotificationUpdatesGroupe(userIds) 
          })
          :
          this.chatService.saveMessage(message).subscribe(result => {
            console.log(result);
            this.messageContent = '';
        });
      }
  }

  toggleEmojiPicker() {
    this.showEmojiPicker = !this.showEmojiPicker;
  }

  addEmoji(event: any) {
    const { emoji } = event;
    this.messageContent += emoji.native;
    this.showEmojiPicker = false; 
  }

  filterUsers() {
    if (this.searchQuery.trim() === '') {
        this.filteredUsers = this.users;
    } else {
        this.filteredUsers = this.users.filter(user =>
            user.firstName.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            user.lastName.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
    }
    this.updateFilteredUsers();
  }

  toggleGoldMode() {
      this.isGoldMode = !this.isGoldMode;
      this.updateFilteredUsers();
  }

  toggleStar(user:any) {
      user.isGolden = !user.isGolden;
      this.updateFilteredUsers();
  }

  updateFilteredUsers() {
    let allUsers = [...this.starredUsers, ...this.users.filter(user => !this.starredUsers.includes(user))];
    if (this.searchQuery.trim() !== '') {
        allUsers = allUsers.filter(user =>
            user.firstName.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            user.lastName.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
    }
    allUsers.sort((a, b) => {
        if (a.isGolden && !b.isGolden) return -1;
        if (!a.isGolden && b.isGolden) return 1;
        return 0;
    });

    this.filteredUsers = allUsers;
}

  openProjectDialog(){
    const dialogRef =this.dialog.open(AddProjectDialogComponent,{
      data: {users:this.users},
      width:'800px',
      height:'500px',
    });
    dialogRef.afterClosed().subscribe((result:any) => {
      console.log(result);
      if (result) {
        const groupData = {
          users:result.users,
          projectName: result.projectName,
          creater: this.currentUser.id
        };
        this.chatService.createGroup(groupData).subscribe((response: any) => {
          console.log(response);
      });
      }
    });
  }

  openChatProject(group:any){
    this.isGroupChat=true;
    console.log(group);
    this.group= group
    const groupUsers = group.users;
      groupUsers.forEach((user: any) => {
      this.chatService.joinGroup(user.id, group._id);
    });
    this.chatService.loadGroupMessages(this.currentUser.id,group._id).subscribe((result: any) => {
        console.log(result);
        this.messages = result;
    });

  }

  getUserNameById(id: string): string {
    const user = this.users.find(user => user.id === id);
    return user.firstName;
  }

  openDialog(): void {
    this.dialog.open(UserListDialogComponent, {
      data: {users: this.group.users}
    });
  }

}