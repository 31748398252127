import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-year-value',
  templateUrl: './dialog-year-value.component.html',
  styleUrls: ['./dialog-year-value.component.css']
})
export class DialogYearValueComponent {
  selectedMaxYearFromMap: number =this.data.selectedMaxYearMap;
  selectedMinYearFromMap: number =this.data.selectedMinYearMap;
  selectedMinValFromMap: number = this.data.selectedMinValMap;
  selectedMaxValFromMap: number = this.data.selectedMaxValMap;

  constructor(
    public dialogRef: MatDialogRef<DialogYearValueComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {maxValue:number,minValue:number,maxYear:number,minYear:number,selectedChartIndex:number
      selectedMaxYearMap:number,selectedMinYearMap :number,selectedMinValMap:number,selectedMaxValMap:number
    },
  ) {

    if(this.data.selectedMaxYearMap === undefined) this.selectedMaxYearFromMap = this.data.maxYear
    if(this.data.selectedMinYearMap === undefined) this.selectedMinYearFromMap = this.data.minYear
    if(this.data.selectedMinValMap === undefined) this.selectedMinValFromMap = this.data.minValue
    if(this.data.selectedMaxValMap === undefined) this.selectedMaxValFromMap = this.data.maxValue
  }

  formatLabel(value: number): string {
    return `${value}`;
  }

  manageYearAndValueMap() {
    return {
      selectedMinVal: this.selectedMinValFromMap,
      selectedMaxVal: this.selectedMaxValFromMap,
      selectedMinYear: this.data.selectedChartIndex < 2 ? 0 : this.selectedMinYearFromMap,
      selectedMaxYear: this.selectedMaxYearFromMap,
    };
  }
  

  closeDialogYear(): void {
    this.dialogRef.close(this.manageYearAndValueMap());
  }

  closeAll(): void {
    this.dialogRef.close();
  }
}
