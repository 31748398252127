import { Properties } from "./Properties";
import { Geometry } from "./Geometry";
import { Feature } from './Feature';

export class Geojson {
  public type?: string;
  public features?: Array<Feature>;


}



