<div class="map-container">
  <div class="map-frame">
    <mat-tab-group #tabGroup1 (selectedTabChange)="onTabChange($event)">
      <mat-tab label="Netzwerk" class="innerFrame">
        <div id="map1">
          <div class="property-container propertyLayer1">
            <label for="property-select-map1" class="material-label">Originales Netzwerk:</label>
            <select id="property-select-map1" class="material-select" (change)="onPropertyChange($event, 'map1')">
              <!-- <option value="HRS0-24avg_diff">Unterschied zwischen Original und Modifiziert</option> -->
              <option value="HRS0-24avg_original">Originalnetzwerk</option>
              <!-- <option value="HRS0-24avg_updated">Modifiziertes Netzwerk</option> -->
            </select>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Meistbefahrene Straßen" class="innerFrame">
        <div class="innerFrame">
          <canvas id="barChart1" width="400" height="200"></canvas>
        </div>
      </mat-tab>
      <mat-tab label="Fahrtzeit">
        <div class="travel-times">
          <h2>Original Zeiten</h2>
          <canvas id="originalChart"></canvas>
        </div>
        
    </mat-tab>
    <mat-tab label="Emissionskarte">
      
    </mat-tab>
    </mat-tab-group>
  </div>

  <div class="map-frame">
    <mat-tab-group (selectedTabChange)="onTabChange($event)">
      <mat-tab label="Netzwerk">
        <div id="map2" class="innerFrame">
          <div class="property-container propertyLayer2">
            <label for="property-select-map2" class="material-label">Netzwerk auswählen:</label>
            <select id="property-select-map2" class="material-select" (change)="onPropertyChange($event, 'map2')">
              <option value="HRS0-24avg_updated">Modifiziertes Netzwerk</option>
              <option value="HRS0-24avg_diff">Unterschied zwischen Original und Modifiziert</option>
              <!-- <option value="HRS0-24avg_original">Originalnetzwerk</option> -->
            </select>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Meistbefahrene Straßen">
        <div class="innerFrame">
          <canvas id="barChart2" width="400" height="200"></canvas>
        </div>
      </mat-tab>
      <mat-tab label="Fahrtzeit">
        <div class="travel-times">
        
          <h2>Aktualisierte Zeiten</h2>
          <canvas id="updatedChart" width="400" height="120"></canvas>
        
          <h2>Differenz Zeiten</h2>
          <canvas id="diffChart" width="400" height="120"></canvas>
        </div>
      </mat-tab>

      <mat-tab label="Emissionskarte">
      
      </mat-tab>

      <mat-tab label="Änderungen">
        <div class="innerFrameCol">
          <h4>Change:</h4>
          <ul>
            <li *ngFor="let message of changeMessages" (click)="flyToCoordinates(message.coordinates,0)">{{ message.message }}</li>
          </ul>
          <h4>Delete:</h4>
          <ul>
            <li *ngFor="let message of deleteMessages" (click)="flyToCoordinates(message.coordinates,0)">{{ message.message }}</li>
          </ul>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
