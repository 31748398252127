<div style="margin: 1% 5% 1% 5%"> 
    <div style="display: flex; justify-content: center; align-items: center;">
        <br>
        <br><div>
        <span class="span0 "> SIMULATION. VIRUSINFEKTION. COVID-19 </span>
    </div>
    </div>



<br>
<br>
<div class="row2">
    <div class="datahub-tile2" (click) = clickTile(1)>
        <div>
            <span class="span2" style="font-weight: 500;"> Basis Szenario </span> 
        </div>
    </div>
  <div class="datahub-tile2" (click) = clickTile(1)>
      <div>
          <span class="span2" style="font-weight: 500; color: grey;"> Laissez-Faire </span>
      </div>
  </div>
  <div class="datahub-tile2" (click) = clickTile(2)>
      <div>
          <span class="span2" style="font-weight: 500; color: grey;"> Maskenpflicht </span> 
      </div>
  </div>
  <div class="datahub-tile2" (click) = clickTile(3)>
    <div>
        <span class="span2" style="font-weight: 500; color: grey;"> Vakzinierung </span> 
    </div>
</div>



<div class="datahub-tile2" (click) = clickTile(4)>
    <div>
        <span class="span2" style="font-weight: 500;"> Eigenes Szenario erstellen </span> 
    </div>
</div>





</div>


<button mat-mini-fab aria-label="Example icon button with a home icon" style="background-color: #10684E; color: white;position: absolute; bottom: 30px;"
(click) = clickTile(5)>
  <mat-icon>arrow_back_ios_new</mat-icon>
</button>

</div>