<div class="main-container">

    <div class="row">

        <div>
            <br>
            <br>
            <span class="span0 "> DATAHUB </span>
        </div>

        <div style="width: 70%">
            <br>
            <br>

            <span class="span2"> “Lorem ipsum dolor sit amet, consectetur adipisici elit, sed eiusmod tempor incidunt ut
                labore et
                dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                aliquid ex ea commodi consequat </span>
        </div>

    </div>




    <div class="row2">
        <div class="datahub-tile2">
            <div>
                <span class="span2"> Synthetische Population. </span>
            </div>
        </div>
        <div class="datahub-tile2">
            <div>
                <span class="span2"> Regionalindikatoren </span> <br>
                <span class="span2">nach Gebietseinheiten. </span>
            </div>
        </div>





    </div>

    <div class="row">

        <app-my-carousel> </app-my-carousel>




    </div>




</div>