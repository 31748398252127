<div *ngIf="needDialog" id="errorDialog">

  <div id="dialogMessage">
    <div id="dialogTileMsg">
      Anfrageergebnis 
    </div>
    <div>Ihre gewählte Eingabekombination hat leider keine Ergebnisse erbracht. Wir empfehlen, eine alternative Eingabe zu versuchen.</div>
    <div id="iconDialog">!</div>
    <button id="confirmDialog" (click)="needDialog=false;">OK</button>
   <img  id="imgDialog" src =  "https://upload.wikimedia.org/wikipedia/commons/5/53/Wappen_Sachsen-Anhalt.svg" width="30px">
  </div>
  
  

</div>

<div style="padding-top:40px">
  <div class="folder-sticker">
    <span class="mainDatahub"> Räumliche Analyse.Erreichbarkeitsanalyse.</span>
  </div>
  
  
  <div style="display: flex; justify-content: center; justify-content: space-around;">
  
  <div style="display: flex; flex-direction: column; justify-content: space-between; height: 77vh;" [ngStyle]="{'width': !selectedResult ? '90%' : '50%'}" >
    
      <div class="map-container" style="height: 70vh;" >
        <div class="map" id='map' leaflet [leafletOptions]="options" [leafletLayers]="layers"
          (leafletMapReady)="onMapReady($event)"></div>
      </div>
  
  
      <div class="textbox" *ngIf="false"  >
        <span class="span3"> Willkommen bei den Erreichbarkeitsanalysen! Um die gewünschten Gebiete auf verschiedene
          Einrichtungstypen zu
          erkunden, klicken Sie einfach auf das + um somit unter Ihrer persönlichen Auswahl die Karte zu analysieren
        </span>
  
      </div>
  
  
  
  
      <div class="auswahl" >
     
  <div style="height: 30vh; overflow-y: scroll; display: flex; flex-direction: row;"> 
  
  
    <div style="display: flex; justify-content:center; align-items:center;flex-direction:column;gap:10px; border-right:1px solid #ccc; padding-right:10px"> 
      <div  style="display: flex; justify-content:space-around; align-items:center;width: 100%;">
        <mat-icon  class="area-arrow" (click) = "isRaster = !isRaster;   clearMap(false); setLayer(); selectedResult = undefined " >keyboard_double_arrow_left</mat-icon>
        <button mat-fab extended color="primary" *ngIf="!isRaster" > Umkreisanalyse</button>
        <button mat-fab extended color="primary" *ngIf="isRaster"[disabled]='false' > Rasteranalyse</button>
    
        <mat-icon class="area-arrow" (click) = "isRaster = !isRaster;   clearMap(false) ;  setLayer(); selectedResult = undefined " >keyboard_double_arrow_right</mat-icon>
      </div>
      <button (click)="showModal(true)" mat-fab extended color="primary" > <mat-icon>add</mat-icon> Analyse hinzufügen </button>
      <button (click)="showAIModal(true)" mat-fab extended color="primary" > <mat-icon>add</mat-icon> AI </button>
      <input id="adress2" [(ngModel)]="searchBox" />
  
    </div>
    <ng-container *ngIf="isRaster">
      <ng-container  *ngFor="let res of storageService.getTempResult();let i = index">
  
        <div *ngIf = "searchFilter(res)"class="resultTile" [ngStyle]="{'background-color':  orgObj == res ? '#cedec0' : 'white'}"  > 
          <div style="display: flex; justify-content: end;"> 
          <div class="pad2">
              
            <mat-icon> grid_4x4 </mat-icon> {{ res.Meta[0].Pop }} {{ res.Meta[0].Year }}   <br>
   
        </div>
  
      </div>
     
      <div style="display: flex; align-items: center; justify-content: space-between;">   <h2> Raster <mat-icon> save </mat-icon> <mat-icon (click)="delete(res)"> delete </mat-icon>  </h2>  
             
       </div>
   
  
      <div style="display: flex; flex-direction: column; align-items: center; justify-content: space-evenly; height: 8vh; overflow-y: auto; "> 
        <div class="pad3"[ngStyle]="{'background-color': selectedResult == startpoint ? '#10684E' : '#D4E5C6' ,
        'border': selectedResult == startpoint ? '3px solid #10684E' : '3px solid #b5c9a4' }"*ngFor="let startpoint of res.Results; let j = index" (click)="clickResult(startpoint, res)" >
              
          <mat-icon> location_on </mat-icon> {{ res.Meta[0].Einrichtungen[j] }}
  
      </div>
     
  
  </div>
  
      </div>
          
          
          
  
   
      
  
         
        </ng-container>
        
        <ng-container  *ngFor="let res of resultsFromDB;let i = index">
  
          <div *ngIf = "searchFilter(res)"class="resultTile" [ngStyle]="{'background-color':  orgObj == res ? '#cedec0' : 'white'}"  > 
            <div style="display: flex; justify-content: end;"> 
            <div class="pad2">
                
              <mat-icon> grid_4x4 </mat-icon> {{ res.Meta[0].Pop }} {{ res.Meta[0].Year }}   <br>
     
          </div>
  
        </div>
       
        <div style="display: flex; align-items: center; justify-content: space-between;">   <h2> {{ res.Meta[0].Name }} <mat-icon> save </mat-icon> <mat-icon (click)="delete(res)"> delete </mat-icon>  </h2>  
               
         </div>
     
   
        <div style="display: flex; flex-direction: column; align-items: center; justify-content: space-evenly; height: 8vh; overflow-y: auto; "> 
          <div class="pad3"[ngStyle]="{'background-color': selectedResult == startpoint ? '#10684E' : '#D4E5C6' ,
          'border': selectedResult == startpoint ? '3px solid #10684E' : '3px solid #b5c9a4' }"*ngFor="let startpoint of res.Results; let j = index" (click)="clickRasterResult(startpoint, res, j)" >
                
            <mat-icon> location_on </mat-icon> {{ res.Meta[0].Einrichtungen[j] }}
    
        </div>
       
  
    </div>
  
        </div>
          
          
          
  
   
      
  
         
        </ng-container>
  
      </ng-container>
  
    <ng-container *ngIf="!isRaster">
  
  
  
        <ng-container  *ngFor="let res of storageService.getTempResult(); let i = index">
    
          <div *ngIf = "searchFilter(res)" class="resultTile" [ngStyle]="{'background-color':  orgObj == res ? '#7EB854' : 'white'}" > 
            <div style="display: flex; justify-content: end;"   (click)="clickResult(undefined, res)"> 
            <div class="pad2">
                
              <mat-icon> track_changes</mat-icon> 
              <mat-icon *ngIf="res.Meta[0].Transport_Mode == 'CAR'"> directions_car</mat-icon> 
              <mat-icon *ngIf="res.Meta[0].Transport_Mode  == 'TRANSIT'"> train</mat-icon> 
              <mat-icon *ngIf="res.Meta[0].Transport_Mode  == 'BICYCLE'"> directions_bike</mat-icon> 
              <mat-icon *ngIf="res.Meta[0].Transport_Mode  ==  'WALK'"> directions_walk</mat-icon> 
       
              
              {{ res.Meta[0].Distanz }}{{ res.Meta[0].Unit }}   <br>
             
     
          </div>
  
        </div>
       
              <div style="display: flex; align-items: center; justify-content: space-between;">   <h2> {{ res.Meta[0].Name }}<mat-icon (click)="delete(res)"> delete </mat-icon>  </h2>  
               
               <div style="display: flex;"> <div *ngFor="let typ of res.Meta[0].Einrichtungen;let i = index">
                  <img *ngIf = "i < 5"src='{{ getLogo(typ) }}' width="20px" height="20px">
                  <span *ngIf = "i == 5"> .. </span>
                </div>
              </div> 
              </div>
              
     
            
          
  
  
        <div style="display: flex; flex-direction: column; align-items: center; justify-content: space-evenly; height: 8vh; overflow-y: auto; "> 
          <div class="pad3"[ngStyle]="{'background-color': selectedResult == startpoint ? '#10684E' : '#D4E5C6' ,
          'border': selectedResult == startpoint ? '3px solid #10684E' : '3px solid #b5c9a4' }" *ngFor="let startpoint of res.Results; let j = index"  (click)="clickResult(startpoint, res)" >
                
            <mat-icon> location_on </mat-icon> {{startpoint.Starting_Point[0].Name}}
    
        </div>
       
  
    </div>
  
        </div>
          
          
          
  
   
      
  
         
        </ng-container>
        
        <ng-container  *ngFor="let res of resultsFromDB; let i = index">
    
          <div *ngIf = "searchFilter(res)" class="resultTile" [ngStyle]="{'background-color':  orgObj == res ? '#7EB854' : 'white'}" > 
            <div style="display: flex; justify-content: end;"   (click)="clickResult(undefined, res)"> 
           

              <div class="pad2" *ngIf="res?.Meta.length>0">
                
                <mat-icon> track_changes</mat-icon> 
                <mat-icon *ngIf="res.Meta[0]?.Transport_Mode == 'CAR'"> directions_car</mat-icon> 
                <mat-icon *ngIf="res.Meta[0]?.Transport_Mode  == 'TRANSIT'"> train</mat-icon> 
                <mat-icon *ngIf="res.Meta[0]?.Transport_Mode  == 'BICYCLE'"> directions_bike</mat-icon> 
                <mat-icon *ngIf="res.Meta[0]?.Transport_Mode  ==  'WALK'"> directions_walk</mat-icon> 
         
                
                {{ res.Meta[0].Distanz }}{{ res.Meta[0].Unit }}   <br>
               
       
            
             
     
          </div>
  
        </div>
       
              <div style="display: flex; align-items: center; justify-content: space-between;">   <h2> {{ res.Meta[0].Name }} <mat-icon> save </mat-icon> <mat-icon (click)="delete(res)"> delete </mat-icon>  </h2>  
               
               <div style="display: flex;"> <div *ngFor="let typ of res.Meta[0].Einrichtungen;let i = index">
                  <img *ngIf = "i < 5"src='{{ getLogo(typ) }}' width="20px" height="20px">
                  <span *ngIf = "i == 5"> .. </span>
                </div>
              </div> 
              </div>
              
     
            
          
  
  
        <div style="display: flex; flex-direction: column; align-items: center; justify-content: space-evenly; height: 8vh; overflow-y: auto; "> 
          <div class="pad3"[ngStyle]="{'background-color': selectedResult == startpoint ? '#10684E' : '#D4E5C6' ,
          'border': selectedResult == startpoint ? '3px solid #10684E' : '3px solid #b5c9a4' }" *ngFor="let startpoint of res.Results; let j = index"  (click)="clickResult(startpoint, res)" >
                
            <mat-icon> location_on </mat-icon> {{startpoint.Starting_Point[0].Name}}
    
        </div>
       
  
    </div>
  
        </div>
          
          
          
  
   
      
  
         
        </ng-container>
  
      </ng-container>
      </div>
        
  
      </div>
  
      
  
  
  
  
    </div>
  
    <div class="result-container" [hidden]="!selectedResult" *ngIf="selectedResult">
  
     <result-app *ngIf="!isRaster" [hidden]="isRaster" [result]="selectedResult" [orgObj]="orgObj" [clickedName]="clickedName"> </result-app>
  
      <result-raster-app [hidden]="!isRaster" *ngIf="isRaster" [result]="selectedResult" [orgObj]="orgObj" [clickedName]="clickedName"> </result-raster-app>
  
    </div>
  
  
  
  </div>
</div>


  <dialog id="dialog">
    <div style="width: 1200px; ">
      <app-reach-config [step]="selectedStep"[singleMode]="singleMode" [selectedMode]="selectedMode"> </app-reach-config>
    </div>
  </dialog>
  <dialog id="dialog-AI">
    <h1> AI BOTBOTBOT</h1>
    <h3> Bitte formuliere deine Anfrage hier rein</h3>

    <textarea [(ngModel)]="aitext" > </textarea>

    <br>


    <button (click)="startAI()" mat-fab extended color="primary" > <mat-icon>add</mat-icon> GOOO </button>




   
  </dialog>

  <dialog id="mini-dialog">
    <div style="width: 1200px; ">
      <location-app> </location-app>

      <button (click)="closeLocation()"> Close </button>
    </div>
  </dialog>


  <dialog id="dialog2">
    <div style="width: 100px; display: flex; align-items: center; ">
      <mat-spinner *ngIf="spinner2"></mat-spinner>
    </div>
  </dialog>


  <div class="addMarkerContainer" *ngIf="addMarkerMode" > 
  

  <div >
    <b> Bitte klicken Sie auf die Karte</b>
   


    <div class="markerList"> 
    <div *ngFor="let marker of markersObjRefMap.values(); let i = index">
      <button style="margin: 2px; "mat-flat-button (click) = 'clickOnMarker(marker)'> {{ marker.plain.name }} | {{ marker.plain.address.substring(0,15) }} </button>
    </div>
    </div>
    <br> 
  </div>
    <button mat-flat-button color="primary" (click)="saveLocation(false)">Auswahl übernehmen</button>

    <button  *ngIf="!newAnalyseMode" mat-flat-button color="primary" (click)="showMiniModal()">Erweiterte Einstellung</button>
   
  </div>


  <dialog id="delete">
    <div style="width: 900px;">
   <h3> Achtung, Löschen ist unwiderruflich </h3> 
   <button mat-flat-button color="primary" (click)="closeDeleteDialog()">Abbrechen</button>
   <button mat-flat-button color="danger" (click)="deleteFinally()">Löschen</button>


    </div>
  </dialog>