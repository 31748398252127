import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { Observable, map } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class KeycloakOperationService {

  private baseUrl = 'https://keycloak.cephlabs.de/admin/realms/DigitalTwin';

  constructor(private readonly keycloak: KeycloakService,
    private http: HttpClient
  ) {}

  isLoggedIn(): any {
    return this.keycloak.isLoggedIn();
  }

  logout(): void {
    this.keycloak.logout();
  }

  getUserProfile(): any {
    return this.keycloak.loadUserProfile();
  }




  getUsersList(): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseUrl}/users`, {
      headers: {
        Authorization: `Bearer ${this.keycloak.getToken()}`
      }
    })
  }

  getRolesMap(): Observable<any> {
    return this.http.get(`${this.baseUrl}/users/862233ca-4af4-4391-8bd4-be7d26e4f202/role-mappings/`, {
      headers: {
        Authorization: `Bearer ${this.keycloak.getToken()}`
      }
    });
  }

 getRoles(): Observable<any> {
    return this.http.get(`${this.baseUrl}/users/862233ca-4af4-4391-8bd4-be7d26e4f202/role-mappings/clients/1d959aa2-de13-4985-a593-16cb3433aab3`, {
      headers: { Authorization: `Bearer ${this.keycloak.getToken()}` },
    });
  }


    roles=[
      {
        "id": "862233ca-4af4-4391-8bd4-be7d26e4f202",
        "name": "normalRole"
      }
    ]

    //// the rest needs to be verified ////

  addRole(): Observable<any> {
    return this.http.post(`${this.baseUrl}/clients/1d959aa2-de13-4985-a593-16cb3433aab3/roles/`,{name:'new-role-name'},
    {
      headers: { Authorization: `Bearer ${this.keycloak.getToken()}` }
    });
  }

  getRole2(): Observable<any> {
    return this.http.get(`${this.baseUrl}/clients/1d959aa2-de13-4985-a593-16cb3433aab3/roles/`,
    {
      headers: { Authorization: `Bearer ${this.keycloak.getToken()}` }
    });
  }


  updateRoles(): Observable<any> {

    const body = [{ name: 'normalUser' }];
    return this.http.post(`${this.baseUrl}/users/f0af5a98-b274-4c6c-a23d-d117bf9ef35a/role-mappings/clients/1d959aa2-de13-4985-a593-16cb3433aab3`,
    body,
     {
      headers: { Authorization: `Bearer ${this.keycloak.getToken()}` },
    });
  }


}
