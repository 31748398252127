import { Component, } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { StreetXpertService } from '../../../../service/streetXpertService/street-xpert.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-matsim-simulation',
  templateUrl: './matsim-simulation.component.html',
  styleUrls: ['./matsim-simulation.component.css']
})
export class MatsimSimulationComponent {

  uuid:any;
  simulationUIID:any;

  constructor(
    private http: HttpClient,
    private streetXpertService:StreetXpertService,
    private router:Router,
    private route:ActivatedRoute
  ) {
    this.uuid = this.route.snapshot.paramMap.get('uuid');
    console.log('Scenario UUID:', this.uuid);
    this.simulationUIID = this.route.snapshot.paramMap.get('simulationUIID');
    console.log('Simulation UUID:', this.simulationUIID);
  }

  storage: any = 8;
  isSimulation: boolean = false;
  visibleSpinnerSim: boolean = true;
  isfailed: boolean = false;
  matsimFinished: boolean =false;
  progress:any =0;

  startSimulation() {
    console.log('start');
    
    this.isSimulation = true;
    this.trackIterations();
    //this.doTime();

    this.streetXpertService.startSimulation(this.uuid).subscribe(
        (response) => {
          console.log('MATSim simulation started:', response);
        },
        (error) => {
          console.error('MATSim simulation failed:', error);
          this.isSimulation = false;
          this.isfailed = true;
        },
        async () => {
          //verry important
          await this.streetXpertService.createLinkstats(this.uuid);
          await this.streetXpertService.createGeoJsonUpdated(this.uuid,this.simulationUIID)
          console.log('MATSim simulation completed.');
          this.isfailed = false;
          this.isSimulation = false;
          this.visibleSpinnerSim = false;
          this.matsimFinished=true;
        }
      );
  }

  goToResult(){
    this.router.navigate(['/result-network', this.uuid , this.simulationUIID]);
  }







  pythonDash() {
    return this.http.get('http://localhost:3000/dashboard').subscribe(
      (response) => {
        console.log('started:', response);
      },
      (error) => {
        console.error('failed:', error);

      },
      () => {
        console.log('python completed.');

      }
    );
  }


  iterNumber: number | undefined;
  newCount: any = 0;
  isStarted = false;
  configIterationNumber:number=0;

  trackIterations() {
    this.isStarted = true;
    let intervalId: any;

    intervalId = setInterval(() => {
      this.streetXpertService.getIterations(this.uuid).subscribe((response: any) => {
        console.log(response)
        this.newCount = response.iterNumber;
        this.configIterationNumber =Number( response.configIterationNumber)+1


        console.log(this.isNaNconfigIterationNumber())
        if (this.newCount === this.configIterationNumber) {
          clearInterval(intervalId);
        }
      });
    }, 1000); 
  }

  getProgress(): number {
    if (this.newCount === 0) {
      return 0;
    } else {
      this.progress =Math.round((this.newCount / this.configIterationNumber) * 100);
      return Math.round((this.newCount / this.configIterationNumber) * 100);
    }
  }

  isNaNconfigIterationNumber(){
    return Number.isNaN(this.configIterationNumber)
  }

  get style() {
    return 'width:' + this.getProgress() + '%';
  }

  timeFirstIteration: any;
  timeSecondIteration: any;
  sumHours: any=0;
  sumMinutes: any=0;
  sumSeconds: any=0;
  multipliedSumHours: any=0;
  multipliedSumMinutes: any=0;
  multipliedSumSeconds: any=0;
  sum: any;
  multipliedSum: any;
  linesLength:any;

  // doTime() {
  //   const intervalEstimation = setInterval(() => {
  //     this.streetXpertService.getIterationTime().subscribe((response: any) => {
  //       this.linesLength = Number(response.linesLength);

  //       if (this.linesLength > 2) {
  //         this.timeFirstIteration = response.secondValue;
  //         this.timeSecondIteration = response.thirdValue;

  //         const duration1 = moment.duration(this.timeFirstIteration);
  //         const duration2 = moment.duration(this.timeSecondIteration);

  //         // Add the durations together
  //         this.sum = moment.duration(duration1).add(duration2);
  //         // Convert the sum to the desired format (e.g., hours, minutes, seconds)
  //         this.sumHours = this.sum.hours();
  //         this.sumMinutes = this.sum.minutes();
  //         this.sumSeconds = this.sum.seconds();

  //         const calcIteration = Math.round((this.configIterationNumber - 2) / 2);

  //         this.multipliedSum = moment.duration(this.sum.asMilliseconds() * calcIteration);
  //         // Convert the multiplied sum to the desired format (e.g., hours, minutes, seconds)
  //         this.multipliedSumHours = this.multipliedSum.hours();
  //         this.multipliedSumMinutes = this.multipliedSum.minutes();
  //         this.multipliedSumSeconds = this.multipliedSum.seconds();

  //         clearInterval(intervalEstimation); // Stop the interval once linesLength is greater than or equal to 2

  //         const intervalClock=setInterval(() => {
  //           this.sum.add(1, 'seconds');
  //           this.multipliedSum.subtract(1, 'seconds');
  //           this.sumHours = this.sum.hours();
  //           this.sumMinutes = this.sum.minutes();
  //           this.sumSeconds = this.sum.seconds();
  //           this.multipliedSumHours = this.multipliedSum.hours();
  //           this.multipliedSumMinutes = this.multipliedSum.minutes();
  //           this.multipliedSumSeconds = this.multipliedSum.seconds();
  //           if (this.newCount === this.configIterationNumber) {
  //             clearInterval(intervalClock);
  //           }

  //         }, 1000);
  //       }
  //     });
  //   }, 1000);
  // }

}
