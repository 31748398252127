<div id="Dialog">
  <mat-progress-bar *ngIf="loading" id="bar" mode="indeterminate"></mat-progress-bar>


  <p *ngIf="loading">Dieser Vorgang kann bis zu {{remainingTime}} Sekunden dauern...</p>

  <div class="changebox" *ngIf="changeMessages.length > 0 || deleteMessages.length > 0 ">
    <h4>Änderungsnachrichten:</h4>
    <ul *ngIf="changeMessages.length > 0">
      <li *ngFor="let message of changeMessages">{{ message.message }}</li>
    </ul>
    <ul *ngIf="deleteMessages.length > 0">
      <li *ngFor="let message of deleteMessages">{{ message.message }}</li>
    </ul>
  </div>


    <div *ngIf="step==1">
      <h3 style="text-align: center;">Vergeben Sie einen Titel und eine Beschreibung für das neu erstellte Netzwerk.</h3>
      <div class="flexBox">
        <mat-form-field appearance="fill">
          <mat-label>Network Title</mat-label>
          <input matInput [(ngModel)]="networkTitle" placeholder="Enter network title">
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Network Description</mat-label>
          <textarea matInput [(ngModel)]="networkDescription" placeholder="Enter network description"></textarea>
        </mat-form-field>
      </div>

        <div class="diaFooter" style=" justify-content: flex-end;">
          <button mat-raised-button color="primary" (click)="step=2">Weiter</button>
      </div>
    </div>

    <div *ngIf="step==2">
      <h3 style="text-align: center;">Möchten Sie das neu erstellte Straßennetzwerk nutzen um eine Mobilitätssimulation durchzuführen?</h3>


      <div class="diaFooter">
        <button mat-raised-button color="primary" (click)="CreatNetworkWithMatsim()">Ja</button>
        <button (click)="closeAll()" mat-raised-button color="warn">Nein</button>
    </div>
    </div>
</div>
