<h1 mat-dialog-title>Neue Projektgruppe</h1>
<div mat-dialog-content>
<div class="inputs">
    <mat-form-field class="input-style" appearance="outline">
        <mat-label>Projektname</mat-label>
        <input matInput [(ngModel)]="projectName">
      </mat-form-field>
    
      <mat-form-field class="input-style" appearance="outline">
        <mat-label>Benutzer hinzufügen</mat-label>
        <input type="text" matInput [matAutocomplete]="auto" [(ngModel)]="userSearch" (input)="filterUsers()">
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let user of filteredUsers" [value]="user">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="toggleUserSelection(user, $event.checked)">
              {{ user.firstName }} {{ user.lastName }}
            </mat-checkbox>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
</div>

  <div class="names">
    <h3>Ausgewählte Benutzer:</h3>
    <ul  [hidden]="!(selectedUsers.length > 0)" >
      <li *ngFor="let user of selectedUsers">{{ user.firstName }} {{ user.lastName }}</li>
    </ul>
  </div>
</div>
<div mat-dialog-actions class="diaFooter">
  <button mat-raised-button color="primary" (click)="onCancel()">Abbrechen</button>
  <button mat-raised-button color="warn" (click)="onSave()" cdkFocusInitial>Speichern</button>
</div>
