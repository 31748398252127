import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { GlobalVariable } from '../../../../global';

@Component({
  selector: 'app-datahubservice',
  templateUrl: './datahubservice.component.html',
  styleUrls: ['./datahubservice.component.css']
})
export class DatahubService {
  async getMedNachfrageResult2(body: { admin_level: number; name_supply_scenario: any[]; indicator: number; pop_name: any[]; typ: string; filterKeys: never[]; focusedKey: string; objectKey: string; parameters_calculation: any[]; }) {
    var url = 'https://api3.cephlabs.de/calculation_daseinsvorsorge/calculate_metrics';
  
    var result = await firstValueFrom(this.http.post(url, body)); 
    return result;
  }
  getMedNachfrageResult(body: { admin_level: number; name_supply_scenario: any[]; indicator: number; pop_name: any[]; typ: string; filterKeys: never[]; focusedKey: string; objectKey: string; parameters_calculation: any[]; }): any {
   return {
    "Absolute Nachfrage": {
      "chartMeta": {
        "minChartValue": 752832.7011,
        "maxChartValue": 1629958.3598,
        "minChartYear": "2024",
        "maxChartYear": "2033",
        "title": "Absolute Nachfrage (Allgemeinmedizin)"
      },
      "chartData": {
        "y_max_value": 1673814.6427,
        "y_min_value": 708976.4182,
        "unit": "N",
        "label_category": "Synthetische Bevoelkerung",
        "labels": ["2024", "2025", "2026", "2027", "2028", "2029", "2030", "2031", "2032", "2033"],
        "datasets": [
          {
            "Object_Key": "15084",
            "label": "average",
            "data": [977975.0159, 969745.532, 964342.8692, 796961.2167, 789356.146, 781670.015, 774407.444, 767966.0354, 760990.09, 752832.7011],
            "label_category": "Synthetische Bevoelkerung",
            "backgroundColor": "#000000",
            "borderColor": "#000000",
            "pointBackgroundColor": "#000000",
            "pointBorderColor": "#000000"
          },
          {
            "Object_Key": "15084",
            "label": "erste",
            "data": [814979.1799, 808121.2767, 803619.0577, 796961.2167, 789356.146],
            "label_category": "Synthetische Bevoelkerung",
            "backgroundColor": "#696969",
            "borderColor": "#696969",
            "pointBackgroundColor": "#696969",
            "pointBorderColor": "#696969"
          },
          {
            "Object_Key": "15084",
            "label": "forthPR",
            "data": [814979.1799, 808121.2767, 803619.0577],
            "label_category": "Synthetische Bevoelkerung",
            "backgroundColor": "#8b4513",
            "borderColor": "#8b4513",
            "pointBackgroundColor": "#8b4513",
            "pointBorderColor": "#8b4513"
          },
          {
            "Object_Key": "15084",
            "label": "hannover_pop",
            "data": [814979.1799, 808121.2767, 803619.0577, 796961.2167],
            "label_category": "Synthetische Bevoelkerung",
            "backgroundColor": "#2e8b57",
            "borderColor": "#2e8b57",
            "pointBackgroundColor": "#2e8b57",
            "pointBorderColor": "#2e8b57"
          },
          {
            "Object_Key": "15084",
            "label": "thirdPRog",
            "data": [1629958.3598, 1616242.5534, 1607238.1154],
            "label_category": "Synthetische Bevoelkerung",
            "backgroundColor": "#808000",
            "borderColor": "#808000",
            "pointBackgroundColor": "#808000",
            "pointBorderColor": "#808000"
          },
          {
            "Object_Key": "15084",
            "label": "zweiteProg",
            "data": [814979.1799, 808121.2767, 803619.0577, 796961.2167, 789356.146, 781670.015, 774407.444, 767966.0354, 760990.09, 752832.7011],
            "label_category": "Synthetische Bevoelkerung",
            "backgroundColor": "#000080",
            "borderColor": "#000080",
            "pointBackgroundColor": "#000080",
            "pointBorderColor": "#000080"
          }
        ]
      }
    },
    "Nachfrage je Einwohner": {
      "chartMeta": {
        "minChartValue": 4.6464,
        "maxChartValue": 4.6683,
        "minChartYear": "2024",
        "maxChartYear": "2033",
        "title": "Nachfrage je Einwohner (Allgemeinmedizin)"
      },
      "chartData": {
        "y_max_value": 4.6694,
        "y_min_value": 4.6453,
        "unit": "N/Einwohner",
        "label_category": "Synthetische Bevoelkerung",
        "labels": ["2024", "2025", "2026", "2027", "2028", "2029", "2030", "2031", "2032", "2033"],
        "datasets": [
          {
            "Object_Key": "15084",
            "label": "average",
            "data": [4.6464, 4.6464, 4.6605, 4.666, 4.665, 4.6633, 4.6616, 4.668, 4.6683, 4.66],
            "label_category": "Synthetische Bevoelkerung",
            "backgroundColor": "#000000",
            "borderColor": "#000000",
            "pointBackgroundColor": "#000000",
            "pointBorderColor": "#000000"
          },
          {
            "Object_Key": "15084",
            "label": "erste",
            "data": [4.6464, 4.6464, 4.6605, 4.666, 4.665],
            "label_category": "Synthetische Bevoelkerung",
            "backgroundColor": "#696969",
            "borderColor": "#696969",
            "pointBackgroundColor": "#696969",
            "pointBorderColor": "#696969"
          },
          {
            "Object_Key": "15084",
            "label": "forthPR",
            "data": [4.6464, 4.6464, 4.6605],
            "label_category": "Synthetische Bevoelkerung",
            "backgroundColor": "#8b4513",
            "borderColor": "#8b4513",
            "pointBackgroundColor": "#8b4513",
            "pointBorderColor": "#8b4513"
          },
          {
            "Object_Key": "15084",
            "label": "hannover_pop",
            "data": [4.6464, 4.6464, 4.6605, 4.666],
            "label_category": "Synthetische Bevoelkerung",
            "backgroundColor": "#2e8b57",
            "borderColor": "#2e8b57",
            "pointBackgroundColor": "#2e8b57",
            "pointBorderColor": "#2e8b57"
          },
          {
            "Object_Key": "15084",
            "label": "thirdPRog",
            "data": [4.6464, 4.6464, 4.6605],
            "label_category": "Synthetische Bevoelkerung",
            "backgroundColor": "#808000",
            "borderColor": "#808000",
            "pointBackgroundColor": "#808000",
            "pointBorderColor": "#808000"
          },
          {
            "Object_Key": "15084",
            "label": "zweiteProg",
            "data": [4.6464, 4.6464, 4.6605, 4.666, 4.665, 4.6633, 4.6616, 4.668, 4.6683, 4.66],
            "label_category": "Synthetische Bevoelkerung",
            "backgroundColor": "#000080",
            "borderColor": "#000080",
            "pointBackgroundColor": "#000080",
            "pointBorderColor": "#000080"
          }
        ]
      }
    },
    "Nachfrage je lokale Einrichtung": {
      "chartMeta": {
        "minChartValue": 5746.8145,
        "maxChartValue": 12442.4302,
        "minChartYear": "2024",
        "maxChartYear": "2033",
        "title": "Nachfrage je lokale Einrichtung (Allgemeinmedizin)"
      },
      "chartData": {
        "y_max_value": 12777.211,
        "y_min_value": 5412.0337,
        "unit": "N/lokale Einrichtung",
        "label_category": "Synthetische Bevoelkerung",
        "labels": ["2024", "2025", "2026", "2027", "2028", "2029", "2030", "2031", "2032", "2033"],
        "datasets": [
          {
            "Object_Key": "15084",
            "label": "average",
            "data": [7465.4581, 7402.6376, 7361.3959, 6083.6734, 6025.6194, 5966.9467, 5911.5072, 5862.3361, 5809.0847, 5746.8145],
            "label_category": "Synthetische Bevoelkerung",
            "backgroundColor": "#000000",
            "borderColor": "#000000",
            "pointBackgroundColor": "#000000",
            "pointBorderColor": "#000000"
          },
          {
            "Object_Key": "15084",
            "label": "erste",
            "data": [6221.2151, 6168.8647, 6134.4966, 6083.6734, 6025.6194],
            "label_category": "Synthetische Bevoelkerung",
            "backgroundColor": "#696969",
            "borderColor": "#696969",
            "pointBackgroundColor": "#696969",
            "pointBorderColor": "#696969"
          },
          {
            "Object_Key": "15084",
            "label": "forthPR",
            "data": [6221.2151, 6168.8647, 6134.4966],
            "label_category": "Synthetische Bevoelkerung",
            "backgroundColor": "#8b4513",
            "borderColor": "#8b4513",
            "pointBackgroundColor": "#8b4513",
            "pointBorderColor": "#8b4513"
          },
          {
            "Object_Key": "15084",
            "label": "hannover_pop",
            "data": [6221.2151, 6168.8647, 6134.4966, 6083.6734],
            "label_category": "Synthetische Bevoelkerung",
            "backgroundColor": "#2e8b57",
            "borderColor": "#2e8b57",
            "pointBackgroundColor": "#2e8b57",
            "pointBorderColor": "#2e8b57"
          },
          {
            "Object_Key": "15084",
            "label": "thirdPRog",
            "data": [12442.4302, 12337.7294, 12268.9932],
            "label_category": "Synthetische Bevoelkerung",
            "backgroundColor": "#808000",
            "borderColor": "#808000",
            "pointBackgroundColor": "#808000",
            "pointBorderColor": "#808000"
          },
          {
            "Object_Key": "15084",
            "label": "zweiteProg",
            "data": [6221.2151, 6168.8647, 6134.4966, 6083.6734, 6025.6194, 5966.9467, 5911.5072, 5862.3361, 5809.0847, 5746.8145],
            "label_category": "Synthetische Bevoelkerung",
            "backgroundColor": "#000080",
            "borderColor": "#000080",
            "pointBackgroundColor": "#000080",
            "pointBorderColor": "#000080"
          }
        ]
      }
    },
    "Einwohner je lokale Einrichtung": {
      "chartMeta": {
        "minChartValue": 1233.2137,
        "maxChartValue": 2677.8931,
        "minChartYear": "2024",
        "maxChartYear": "2033",
        "title": "Einwohner je lokale Einrichtung (Allgemeinmedizin)"
      },
      "chartData": {
        "y_max_value": 2750.1271,
        "y_min_value": 1160.9798,
        "unit": "N/lokale Einrichtung",
        "label_category": "Synthetische Bevoelkerung",
        "labels": ["2024", "2025", "2026", "2027", "2028", "2029", "2030", "2031", "2032", "2033"],
        "datasets": [
          {
            "Object_Key": "15084",
            "label": "average",
            "data": [1606.7359, 1593.1878, 1579.5389, 1303.8397, 1291.6641, 1279.542, 1268.1298, 1255.8473, 1244.374, 1233.2137],
            "label_category": "Synthetische Bevoelkerung",
            "backgroundColor": "#000000",
            "borderColor": "#000000",
            "pointBackgroundColor": "#000000",
            "pointBorderColor": "#000000"
          },
          {
            "Object_Key": "15084",
            "label": "erste",
            "data": [1338.9466, 1327.6565, 1316.2824, 1303.8397, 1291.6641],
            "label_category": "Synthetische Bevoelkerung",
            "backgroundColor": "#696969",
            "borderColor": "#696969",
            "pointBackgroundColor": "#696969",
            "pointBorderColor": "#696969"
          },
          {
            "Object_Key": "15084",
            "label": "forthPR",
            "data": [1338.9466, 1327.6565, 1316.2824],
            "label_category": "Synthetische Bevoelkerung",
            "backgroundColor": "#8b4513",
            "borderColor": "#8b4513",
            "pointBackgroundColor": "#8b4513",
            "pointBorderColor": "#8b4513"
          },
          {
            "Object_Key": "15084",
            "label": "hannover_pop",
            "data": [1338.9466, 1327.6565, 1316.2824, 1303.8397],
            "label_category": "Synthetische Bevoelkerung",
            "backgroundColor": "#2e8b57",
            "borderColor": "#2e8b57",
            "pointBackgroundColor": "#2e8b57",
            "pointBorderColor": "#2e8b57"
          },
          {
            "Object_Key": "15084",
            "label": "thirdPRog",
            "data": [2677.8931, 2655.313, 2632.5649],
            "label_category": "Synthetische Bevoelkerung",
            "backgroundColor": "#808000",
            "borderColor": "#808000",
            "pointBackgroundColor": "#808000",
            "pointBorderColor": "#808000"
          },
          {
            "Object_Key": "15084",
            "label": "zweiteProg",
            "data": [1338.9466, 1327.6565, 1316.2824, 1303.8397, 1291.6641, 1279.542, 1268.1298, 1255.8473, 1244.374, 1233.2137],
            "label_category": "Synthetische Bevoelkerung",
            "backgroundColor": "#000080",
            "borderColor": "#000080",
            "pointBackgroundColor": "#000080",
            "pointBorderColor": "#000080"
          }
        ]
      }
    },
    "Arztdichte": {
      "chartMeta": {
        "minChartValue": 37.3428,
        "maxChartValue": 81.0889,
        "minChartYear": "2024",
        "maxChartYear": "2033",
        "title": "Arztdichte (Allgemeinmedizin)"
      },
      "chartData": {
        "y_max_value": 83.2763,
        "y_min_value": 35.1555,
        "unit": "Aerzte je 100.000 EW",
        "label_category": "Synthetische Bevoelkerung",
        "labels": ["2024", "2025", "2026", "2027", "2028", "2029", "2030", "2031", "2032", "2033"],
        "datasets": [
          {
            "Object_Key": "15084",
            "label": "average",
            "data": [67.217, 67.7886, 68.3744, 76.6965, 77.4195, 78.153, 78.8563, 79.6275, 80.3617, 81.0889],
            "label_category": "Synthetische Bevoelkerung",
            "backgroundColor": "#000000",
            "borderColor": "#000000",
            "pointBackgroundColor": "#000000",
            "pointBorderColor": "#000000"
          },
          {
            "Object_Key": "15084",
            "label": "erste",
            "data": [74.6856, 75.3207, 75.9715, 76.6965, 77.4195],
            "label_category": "Synthetische Bevoelkerung",
            "backgroundColor": "#696969",
            "borderColor": "#696969",
            "pointBackgroundColor": "#696969",
            "pointBorderColor": "#696969"
          },
          {
            "Object_Key": "15084",
            "label": "forthPR",
            "data": [74.6856, 75.3207, 75.9715],
            "label_category": "Synthetische Bevoelkerung",
            "backgroundColor": "#8b4513",
            "borderColor": "#8b4513",
            "pointBackgroundColor": "#8b4513",
            "pointBorderColor": "#8b4513"
          },
          {
            "Object_Key": "15084",
            "label": "hannover_pop",
            "data": [74.6856, 75.3207, 75.9715, 76.6965],
            "label_category": "Synthetische Bevoelkerung",
            "backgroundColor": "#2e8b57",
            "borderColor": "#2e8b57",
            "pointBackgroundColor": "#2e8b57",
            "pointBorderColor": "#2e8b57"
          },
          {
            "Object_Key": "15084",
            "label": "thirdPRog",
            "data": [37.3428, 37.6603, 37.9858],
            "label_category": "Synthetische Bevoelkerung",
            "backgroundColor": "#808000",
            "borderColor": "#808000",
            "pointBackgroundColor": "#808000",
            "pointBorderColor": "#808000"
          },
          {
            "Object_Key": "15084",
            "label": "zweiteProg",
            "data": [74.6856, 75.3207, 75.9715, 76.6965, 77.4195, 78.153, 78.8563, 79.6275, 80.3617, 81.0889],
            "label_category": "Synthetische Bevoelkerung",
            "backgroundColor": "#000080",
            "borderColor": "#000080",
            "pointBackgroundColor": "#000080",
            "pointBorderColor": "#000080"
          }
        ]
      }
    }
  }
  
  }
  async postPrognose(prognoseObj: any) {
   
    var url = 'https://api5.cephlabs.de/projection/project_pop';
  
    var result = await firstValueFrom(this.http.post(url, prognoseObj)); 
    return result;
  }

  async postGetPopInfos(prognoseObj: any) {
   
    var url = 'https://api4.cephlabs.de/projected_pop_stats/get_initial_pop_stats_dedicated_pops';
  prognoseObj  = 
    {"selected_pops": prognoseObj}
  
    var result = await firstValueFrom(this.http.post(url, prognoseObj)); 
    return result;
  }



  async getCoordinatesForAdress(search: any) {

    var url = 'https://pelias.cephlabs.de/v1/search?text=' + search;
    var result = await firstValueFrom(this.http.get(url)); 
    return result; 
   }

   async getAdressForCoordinates(lat: number, lng: number ) {


    var url = 'https://pelias.cephlabs.de/v1/reverse?point.lat=' + lat + '&point.lon=' + lng + '&layers=address';
    var result = await firstValueFrom(this.http.get(url)); 
    return result; 
   }

   async getAnalyseData( ) {
    var url = this.baseApiUrl + '/getAnalyseData';
    var result = await firstValueFrom(this.http.get(url)); 
    return result; 
   }


   async getNachfrageData( ) {
    var url = this.baseApiUrl + '/getNachfrageData';
    var result = await firstValueFrom(this.http.get(url)); 
    return result; 
   }

   async renameAnalyseData(oldName: any, newName: any ) {
    var url = this.baseApiUrl + '/renameAnalyseData';
    var body = {
      oldName: oldName,
      newName: newName
    }
    var result = await firstValueFrom(this.http.post(url, body)); 

    return result; 
   }


  
   async addPrognoseData(data: any ) {

    var url = this.baseApiUrl + '/addPrognoseData';
    var result = await firstValueFrom(this.http.post(url, data)); 
    return result; 
   }


   async getPrognoseData( ) {
    var url = this.baseApiUrl + '/getPrognoseData';
    var result = await firstValueFrom(this.http.get(url)); 
    return result; 
   }


   async addAnalyseData(data: any ) {
    data.isTemp = false; 
    var url = this.baseApiUrl + '/addAnalyseData';
    var result = await firstValueFrom(this.http.post(url, data)); 
    return result; 
   }

   async addNachfrageData(data: any ) {

    var url = this.baseApiUrl + '/addNachfrageData';
    var result = await firstValueFrom(this.http.post(url, data)); 
    return result; 
   }

   async deleteNachfrageData(data: any ) {

    var url = this.baseApiUrl + '/deleteNachfrageData';

    var result = await firstValueFrom(this.http.post(url, data)); 
    return result; 
   }


   async deletePrognoseData(data: any ) {

    var url = this.baseApiUrl + '/deletePrognoseData';

    var result = await firstValueFrom(this.http.post(url, data)); 
    return result; 
   }




   async updateCanRead(documentId: string, userIdsToAdd: string[], owner: string) {
    const body = {
      documentId: documentId,
      userIdsToAdd: userIdsToAdd,
      createdBy: owner, 
    };

    var url = this.baseApiUrl + '/updateCanReadAnalyseData';

    try{

    var result = await firstValueFrom(this.http.put(url, body)); 
    return result; 

    } catch(error) {
    
      return error; 
    }

   // return result
  }





   async removeAnalyseData(name: any ) {


    var url = this.baseApiUrl + '/removeAnalyseData';

    var result = await firstValueFrom(this.http.post(url, {
      oldName: name
    })); 
    return result; 
   }

   async getAutocompleteList(search: any) {


   // var url = 'https://pelias.cephlabs.de/v1/autocomplete?text=' + search + '&layers=address'; //world wide search
   var url = 'https://pelias.cephlabs.de/v1/autocomplete?county=Burgenlandkreis&text=' + search + '&layers=address';
    var result = await firstValueFrom(this.http.get(url)); 
    return result; 
   }





   async addDocData(data: any) {

    var url = this.baseApiUrl + '/addDocData';
   
  
    var result = await firstValueFrom(this.http.post(url, data)); 
    return result;

  }
  async getDocData(userId: string) {
    const headers = new HttpHeaders({
      'Cache-Control': 'no-store, no-cache, must-revalidate, proxy-revalidate',
      'Pragma': 'no-cache',
      'Expires': '0'
    });


    var url = this.baseApiUrl + '/getDocData/' + userId;
   
    console.warn(url); 
    var result = await firstValueFrom(this.http.get(url, { headers })); 
    return result;

  }

  async getMigChartPrognose(prognoseObj: any) {
   
    var url = 'https://api4.cephlabs.de/pop_parameter_helper/get_aggregated_migration_chart';
  
  
    var result = await firstValueFrom(this.http.post(url, prognoseObj)); 
    return result;
  }




  getPrognoseStats(): Promise<any> {
    const url = `https://api4.cephlabs.de/projected_pop_stats/get_initial_pop_stats?timestamp=${new Date().getTime()}`;
    return firstValueFrom(this.http.get(url, { headers: { 'Cache-Control': 'no-cache' } }));

  }

  

  private baseApiUrl = GlobalVariable.DATAHUB_URL;

  async getRaumData(place: string[]) {
   

    var url = this.baseApiUrl + '/getRaumData';
    var body = {
      "in": place,
  }
  
    var result = await firstValueFrom(this.http.post(url, body)); 
    return result;
  }

  async getMigration(obj_key: string) {

  var url = this.baseApiUrl + '/getMigration/' + obj_key;
 
  
  var result = await firstValueFrom(this.http.get(url)); 
  return result;

  }


  async getInitialNotfalleinrichtung(obj_key: string){


    var url =  this.baseApiUrl + '/getNotfallEinrichtungen/' + obj_key;
    var res = await firstValueFrom(this.http.get(url));
    return res; 
   
  }

  async getNotfalleinrichtungAll(){


    var url =  this.baseApiUrl + '/getNotfallEinrichtungen/'; 
    var res = await firstValueFrom(this.http.get(url));
    return res; 
   
  }

  async getPopMeta(popname: string) {

    var url = this.baseApiUrl + '/getSynthMeta/' + popname;
   
    
    var result = await firstValueFrom(this.http.get(url)); 
    return result;
  
    }


  async getFertility(obj_key: string) {


   var mockJson =  [
      {
          "_id": "650193540382cf8103076ac1",
          "Object_Key": "15084",
          "Variante": "Anstieg",
          "data": [
              {
                  "Jahr": 2023,
                  "Fertilitätsrate": 1.678
              },
              {
                  "Jahr": 2024,
                  "Fertilitätsrate": 1.7075
              },
              {
                  "Jahr": 2025,
                  "Fertilitätsrate": 1.737
              },
              {
                  "Jahr": 2026,
                  "Fertilitätsrate": 1.769
              },
              {
                  "Jahr": 2027,
                  "Fertilitätsrate": 1.801
              },
              {
                  "Jahr": 2028,
                  "Fertilitätsrate": 1.833
              },
              {
                  "Jahr": 2029,
                  "Fertilitätsrate": 1.865
              },
              {
                  "Jahr": 2030,
                  "Fertilitätsrate": 1.897
              },
              {
                  "Jahr": 2031,
                  "Fertilitätsrate": 1.881
              },
              {
                  "Jahr": 2032,
                  "Fertilitätsrate": 1.873
              },
              {
                  "Jahr": 2033,
                  "Fertilitätsrate": 1.86532
              },
              {
                  "Jahr": 2034,
                  "Fertilitätsrate": 1.8618
              },
              {
                  "Jahr": 2035,
                  "Fertilitätsrate": 1.897
              }
          ]
      },
      {
          "_id": "650193540382cf8103076ac2",
          "Object_Key": "15084",
          "Variante": "Kontinuität",
          "data": [
              {
                  "Jahr": 2023,
                  "Fertilitätsrate": 1.678
              },
              {
                  "Jahr": 2024,
                  "Fertilitätsrate": 1.7075
              },
              {
                  "Jahr": 2025,
                  "Fertilitätsrate": 1.737
              },
              {
                  "Jahr": 2026,
                  "Fertilitätsrate": 1.7356
              },
              {
                  "Jahr": 2027,
                  "Fertilitätsrate": 1.7342
              },
              {
                  "Jahr": 2028,
                  "Fertilitätsrate": 1.7328
              },
              {
                  "Jahr": 2029,
                  "Fertilitätsrate": 1.7314
              },
              {
                  "Jahr": 2030,
                  "Fertilitätsrate": 1.73
              },
              {
                  "Jahr": 2031,
                  "Fertilitätsrate": 1.7286
              },
              {
                  "Jahr": 2032,
                  "Fertilitätsrate": 1.7272
              },
              {
                  "Jahr": 2033,
                  "Fertilitätsrate": 1.7258
              },
              {
                  "Jahr": 2034,
                  "Fertilitätsrate": 1.7244
              },
              {
                  "Jahr": 2035,
                  "Fertilitätsrate": 1.723
              }
          ]
      },
      {
          "_id": "650193540382cf8103076ac3",
          "Object_Key": "15084",
          "Variante": "Rückgang",
          "data": [
              {
                  "Jahr": 2023,
                  "Fertilitätsrate": 1.678
              },
              {
                  "Jahr": 2024,
                  "Fertilitätsrate": 1.7075
              },
              {
                  "Jahr": 2025,
                  "Fertilitätsrate": 1.737
              },
              {
                  "Jahr": 2026,
                  "Fertilitätsrate": 1.719
              },
              {
                  "Jahr": 2027,
                  "Fertilitätsrate": 1.701
              },
              {
                  "Jahr": 2028,
                  "Fertilitätsrate": 1.683
              },
              {
                  "Jahr": 2029,
                  "Fertilitätsrate": 1.665
              },
              {
                  "Jahr": 2030,
                  "Fertilitätsrate": 1.647
              },
              {
                  "Jahr": 2031,
                  "Fertilitätsrate": 1.629
              },
              {
                  "Jahr": 2032,
                  "Fertilitätsrate": 1.611
              },
              {
                  "Jahr": 2033,
                  "Fertilitätsrate": 1.593
              },
              {
                  "Jahr": 2034,
                  "Fertilitätsrate": 1.575
              },
              {
                  "Jahr": 2035,
                  "Fertilitätsrate": 1.557
              }
          ]
      }
  ]
 
return mockJson; 
    var url = this.baseApiUrl + '/getFertility/' + obj_key;
 
  
    var result = await firstValueFrom(this.http.get(url)); 
    return result;
  }


  async getDeathRate() {
    var url = this.baseApiUrl + '/getDeathRate/' ;
  
    var result = await firstValueFrom(this.http.get(url)); 
    return result;
  }

  async getDeathRateNew() {
    var url = 'https://api4.cephlabs.de/pop_parameter_helper/get_death_prob_chart_table' ;
  
    var result = await firstValueFrom(this.http.get(url)); 
    return result;
  }



  

  


  async getUmkreissuche(req : any) {
    var einrichtungString = '';

    req.einrichtung.forEach((einrichtung: string) => {
      einrichtungString = einrichtungString + "&gesuchter_typ=" + einrichtung + "&";       
    });




    var url = "https://api.cephlabs.de/get_umkreis_einrichtungen_distance?coordinates=" + req.position.lng +
   "&coordinates=" + req.position.lat  + "&max_distance="+ req.radius_meter + einrichtungString + "only_burgenlandkreis=" + req.within_flg; 

   console.log(url); 

   // url = this.baseApiUrl + '/getPerimeter/' + req.position.lng + '/' + req.position.lat +'/5000';
   


   // url = "https://api.cephlabs.de/get_umkreis_einrichtungen_distance?coordinates=12.1179&coordinates=51.0733&max_distance=5000&gesuchter_typ=Supermarkt&gesuchter_typ=Kindertagesstaette&only_burgenlandkreis=true"
    var result = await firstValueFrom(this.http.get(url)); 
    return result;
  }

  
  async getEinrichtungenMeta() {
    var url = this.baseApiUrl + '/getEinrichtungenMeta';  
    var result = await firstValueFrom(this.http.get(url)); 

    return result;
  }

  async getShapefileByIdAndLevel(place: string, selected: number) {
    var results = [];
    place = this.createShortenedKeyFromLevel(place, selected);



    var url = this.baseApiUrl + '/getShapeFileByLevel/' + place + '/' + selected; 
  
    try{

    var result = await firstValueFrom(this.http.get(url)); }
    catch(error) {
      return[]; 
    }
    results.push(result); 
    return results;
  }

  constructor(private http: HttpClient) {

  }
 
  async getDataByIndicatorStart(indicators: string[], obj_keys: string[], level: number) : Promise<any>{
    var searchObjKey = obj_keys[0];

    var url = this.baseApiUrl + '/buildKeyByLevel/' + obj_keys[0] + '/' + level; 
    console.log("hall");
   
    
    var adjKey = await firstValueFrom( this.http.get(url) ); 
    console.log(adjKey);
    

    var url = this.baseApiUrl + '/getDataByIndAndKeyStart';
    var body = {
      "Object_Key": [adjKey],
      "indicators": indicators
  }
  
    var result = await firstValueFrom(this.http.post(url, body)); 
    return result;
      
    
  
    }

    async getChartDataByIndAndkeys(indicators: string[], obj_keys: string[]) : Promise<any>{

      

      var url = this.baseApiUrl + '/getChartDataByIndAndKeys';
      var body = {
        "Object_Key": obj_keys,
        "indicators": indicators
    }
    console.warn("APPPI"); 
    console.log(url); 
    console.log(body);
    
      var result = await firstValueFrom(this.http.post(url, body)); 
      return result;
        
      
    
      }
  
  async getDataByIndicator(indicators: string[], obj_keys: string[]) : Promise<any>{

  var url = this.baseApiUrl + '/getDataByIndAndKeys';
  var body = {
    "Object_Key": obj_keys,
    "indicators": indicators
}
console.log("hello"); 
console.log(url); 

  var result = await firstValueFrom(this.http.post(url, body)); 
  return result;
    
  

  }

  aggregateValues(list: number[]) {

    var counter = 0;
    var sum = 0;
    var max = 0;
    var min = 10000000000;
  


    list.forEach(element => {
      if(element != undefined) {
        counter++;
        sum = sum + element; 

        if(element > max){
          max = element; 
        }
        if(element < min){
          min = element; 
        }
      }
    });

    var obj = 
    {  
      "values" : [max, min, Math.round((sum/counter)*100)/100],
      "labels" : [ "Maximum", "Minimum", "Mittelwert"]
    }

    return obj; 


    
    
  }
   classifyArray(array: any[], numQuantiles: number) {
    // sort the array in ascending order
    array.sort(function(a, b) {
      return a - b;
    });
  
    // divide the array into numQuantiles quantiles
    var quantileSize = Math.floor(array.length / numQuantiles);
    var quantiles = [];
    for (var i = 0; i < numQuantiles - 1; i++) {
      quantiles.push(array[(i + 1) * quantileSize - 1]);
    }
    quantiles.push(array[array.length - 1]);

    console.log(quantiles); 

    

  
    // classify the array values based on the quantiles
    var classifiedArray = [];
    for (var i = 0; i < array.length; i++) {
      var value = array[i];
      var classIndex = 0;
      for (var j = 0; j < quantiles.length; j++) {
        if (value <= quantiles[j]) {
          classIndex = j;
          break;
        }
      }
      classifiedArray.push(classIndex);
    }
    return { quantiles: quantiles,
            class:  classifiedArray}; 
  }



  createChangeChart(chartTmp: any, min: number, max: number) {

    var chart = JSON.parse(JSON.stringify(chartTmp));
    console.log("change"); 
    console.log(chartTmp); 
    console.log(chart); 
    var values: any[] = []; 
    var labels: any[] = []; 

    chart.data.datasets.forEach((element: any) => {
      console.log(min);
      console.log(max); 
      var firstVal = element.data.filter((el: { x: number; }) => el.x == min)[0];
      var secondVal = element.data.filter((el: { x: number; }) => el.x == max)[0];
      console.log(firstVal); 
      console.log(secondVal); 
      console.log(secondVal.y - firstVal.y)
      var val = (( secondVal.y - firstVal.y ) /  firstVal.y ); 
      console.log(val); 
      values.push( {
        x: element.Object_Key,
        y: val
      }) 
 
      labels.push(element.Object_Key)



    }); 

    console.log(values)

    chart.data.datasets[0] = values; 
    chart.data.labels = labels; 

    return chart;  

  }


  createAggChart(layerKey : any, aggregated: any) {

    console.log(aggregated); 

    var aggVals =  aggregated.data.datasets.filter((d: { Object_Key: any; }) =>   this.createShortenedKeyFromLevel(d.Object_Key,4) != layerKey );
    var tarVals =  aggregated.data.datasets.filter((d: { Object_Key: any; }) =>  this.createShortenedKeyFromLevel(d.Object_Key,4) == layerKey );

    console.log(aggVals)
    var avg :number  = 0, min :number = 0, max  :number= 0; 

    aggVals.forEach((element: any) => {
      max = max + element.data[0].y; 
      min = min + element.data[1].y; 
      avg = avg + element.data[2].y; 
    });

    console.log(max); 
    console.log(aggVals.length)
    console.log(max/aggVals.length)

    tarVals.push({
      Object_Key: "*",
      data: [
        {x: 'Maximum', y: min / aggVals.length},
        {x: 'Minimum', y:  max / aggVals.length},
        {x: 'Mittelwert', y:  avg / aggVals.length} ],
        label: "Vergleich"
})

console.log(tarVals)

aggregated.data.datasets = tarVals; 
    return aggregated; 

  }

  createPrognoseChart1(chartTmp: any, min: number, max: number) {

    var chart = JSON.parse(JSON.stringify(chartTmp));
    var newdata : any; 
    var lines: { data: any; label: any; Object_Key: any; }[] = []; 
    var labels: any[] = []; 
    chart.data.datasets.forEach((element: any) => {

      var datapoint = []; 

      var firstVal = element.data.filter((el: { x: number; }) => el.x == min)[0];
      var secondVal = element.data.filter((el: { x: number; }) => el.x == max)[0];
      console.log(firstVal); 
      console.log(secondVal); 
      console.log(secondVal.y - firstVal.y)
      var val = (( secondVal.y - firstVal.y ) /  firstVal.y ); 
      console.log(val); 
      datapoint.push( {
        x: element.label,
        y: val
      }) 
 
        var line = { data: datapoint, label: element.label, Object_Key: element.Object_Key}    
        lines.push(line); 
        labels.push(element.label); 
    });

    chart.data.datasets = lines; 
    chart.data.labels   = labels; 
   


    return chart; 

  }


  createPrognoseChart(chartTmp: any) {
    var chart = JSON.parse(JSON.stringify(chartTmp));
    var newdata : any; 
    var lines: { data: any; label: any; Object_Key: any; }[] = []; 
    chart.data.datasets.forEach((element: any) => {

      var datapoint = []; 
 


      for(var i = 0; i < element.data.length - 1; i++) {
            
       var z =  (element.data[i+1].y / element.data[i].y)- 1;

       console.log(z); 
       var d = {
        y: z,
        x: element.data[i+1].x
       };

       datapoint.push(d); 


      }
        var line = { data: datapoint, label: element.label, Object_Key: element.Object_Key}    
        lines.push(line); 
    });

    chart.data.datasets = lines; 
   


    return chart; 

  }



  createAggregatedChart(chartTmp: any) {
    var chart = JSON.parse(JSON.stringify(chartTmp));
    var newdata : any; 
    var lines: { data: any; label: any; Object_Key: any; }[] = []; 
    chart.data.datasets.forEach((element: any) => {
      console.log(element)
      var counter = 0;
      var sum = 0;
      var max = 0;
      var min = 10000000000;
      element.data.forEach((d: any) => {

        console.log(d); 

        if(d.y > max) {
          max = d.y; 
        }
        if(d.y < min) {
          min = d.y; 
        }
       
        if(d.y != undefined)
        counter++;
        sum = sum + d.y;
      });

      

      newdata= [
        {x: "Maximum", y: max},
        {x: "Minimum", y: min},
        {x: "Mittelwert", y: Math.round((sum/counter)*100)/100}];
        console.log(newdata)
        var line = { data: newdata, label: element.label, Object_Key: element.Object_Key}
        counter = 0;
        sum = 0;
        max = 0;
        min = 10000000000;
        lines.push(line); 
    });

    chart.data.datasets = lines; 
    chart.data.labels        = ["Maximum", "Minimum", "Mittelwert"]; 


    return chart; 

  }
  createShortenedKeyFromLevel(search: string, admin_level: number) {
    var returnKey; 

    switch (Number(admin_level)) {
      case 7: //Ort
        returnKey = search 
        break;
      case 6: //Ortsteil
        returnKey = search.substring(0, 21);
        break;
      case 5: //Gemeinde
        returnKey = search.substring(0, 12);
        break;
      case 4: //Verband
        returnKey = search.substring(0, 9);
        break;
      case 3: //Kreis
        returnKey = search.substring(0, 5);
        break;
      case 2: // Region
        returnKey = search.substring(0, 4);
        break;
     //case 2: // Regierungsbezirk
      //  returnKey = search.substring(0, 3);
       // break;
      case 1: // Bundesland
        returnKey = search.substring(0, 2);
        break;
      default:
        returnKey = search;
  
    }
  
    return returnKey;
  
  }

  async getShapefileById(search: string):  Promise<any[]>{
    var results = [];

    var url = this.baseApiUrl + '/getShapeFileByLevel/' + search; 

    try{

    var result = await firstValueFrom(this.http.get(url)); }
    catch(error) {
      return[]; 
    }
    results.push(result); 
    return results;

  }




}

