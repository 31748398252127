<div class="search-results-container">
    <h2>Search Results for "{{ query }}"</h2>
    
    <div *ngIf="results.length > 0; else noResults">
      <mat-list>
        <mat-list-item *ngFor="let result of results">
          <h3 matLine>{{ result.name }}</h3>
          <p matLine>{{ result.content }}</p>
          <button mat-button (click)="navigateTo(result.path)">Go to {{ result.name }}</button>
        </mat-list-item>
      </mat-list>
    </div>
    
    <ng-template #noResults>
      <p>No results found for "{{ query }}".</p>
    </ng-template>
  </div>
  