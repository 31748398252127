<div class="chat-app">
    <div class="chat-messages">
        <div  *ngIf="!isGroupChat" class="messages-container-main">
            <div class="messages-container" *ngFor="let message of messages"   [ngClass]="{'align-start': message.from !== currentUser.id, 'align-end': message.from === currentUser.id}">
                <div class="message" [ngClass]="{'sender': message.from === currentUser.id, 'receiver': message.from !== currentUser.id}">
                    <div >
                        <div class="message-header">
                            <strong class="nameUser">{{ message.from === currentUser.id ? currentUser.firstName : selectedUser.firstName }}</strong>
                            <div class="timestamp">{{ message.timestamp | date: 'short' }}</div>
                        </div>
                        <div class="message-body" [innerHTML]="message.content"></div>
                    </div>
                </div>
            </div>
        </div>

        <div  *ngIf="isGroupChat" class="messages-container-main">
            <div class="userlistGroup">
                <p style=" font-weight:bold; margin-right:20px">Gruppen Mitglieder</p>
                <div *ngFor="let user of group.users.slice(0, 3)" class="userItemGroup">
                  {{ user.firstName }} {{ user.lastName }}
                </div>
                <div *ngIf="group.users.length > 3" class="moreUsers">
                  <span class="points">...</span>
                  <button mat-button (click)="openDialog()">Alle mitglieder ansehen</button>
                </div>
                
              </div>
            <div class="messages-container" *ngFor="let message of messages"   [ngClass]="{'align-start': message.from !== currentUser.id, 'align-end': message.from === currentUser.id}">
                <div class="message" [ngClass]="{'sender': message.from === currentUser.id, 'receiver': message.from !== currentUser.id}">
                    <div >
                        <div class="message-header">
                            <strong class="nameUser">{{ message.from === currentUser.id ? currentUser.firstName : getUserNameById(message.from) }}</strong>
                            <div class="timestamp">{{ message.timestamp | date: 'short' }}</div>
                        </div>
                        <div class="message-body" [innerHTML]="message.content"></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="message-input-area">
            <mat-form-field class="example-full-width" class="message-input">
                <textarea matInput [(ngModel)]="messageContent" placeholder="Type a message..." ></textarea>
            </mat-form-field>

            <button class="notIcons" style="background-color: #126F62;color: white;margin-right: 4px;" mat-mini-fab (click)="sendMessage()" >
                <mat-icon>send</mat-icon>
            </button>

            <button class="notIcons" style="background-color: #126F62;color: white;margin-right: 4px;" mat-mini-fab  (click)="toggleEmojiPicker()">
                <mat-icon >emoji_emotions</mat-icon>
            </button>
            

            <div *ngIf="showEmojiPicker" >
                <emoji-mart  id="emoji-menu" (emojiClick)="addEmoji($event)"></emoji-mart>
            </div>
            
        </div>
    </div>


    <div class="container">
        <div class="user-list">
            <div class="list-title-U">
            <mat-icon style="transform: scale(2.0);">person_search</mat-icon>
            <input id="search" [(ngModel)]="searchQuery" (input)="filterUsers()" placeholder="Suche nach einem Benutzer...">
            </div>
            <div class="list-section-U">
                <div *ngFor="let user of filteredUsers" class="user-item">
                    <div *ngIf="user?.id !== currentUser?.id" class="user" (click)="onSelectUser(user)" [ngStyle]="{'background-color': selectedUser === user ? '#1565c0' : 'white', 'color': selectedUser === user ? 'white' : 'black'}">
                        <div class="icon-status-container">
                            <div class="icon-circle" [ngStyle]="{'border': selectedUser === user ? '1px solid white':'1px solid #126f62'}">
                                <mat-icon>perm_identity</mat-icon>
                            </div>
                            <span class="status-indicator" [ngClass]="{'online': user.isOnline, 'offline': !user.isOnline}"></span>
                        </div>
                        <span class="user-name">{{ user.firstName }} {{ user.lastName }}</span>
                        <!-- <div class="star-icon" (click)="toggleStar(user)" [ngStyle]="{'color': user.isGolden ? 'gold' : '#ccc'}">
                            <mat-icon>star</mat-icon>
                        </div> -->
                        <div *ngIf="user.notificationsCount !==0 " class="recieved-new" style="margin-right:10px">
                            <mat-icon [matBadge]="user.notificationsCount" matBadgeColor="warn" aria-hidden="false" >announcement</mat-icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="P-list">
            <div class="list-title-P">                
                <button  (click)="openProjectDialog()" class="notIcons" style="background-color: #126F62;color: white;margin-right: 4px;" mat-mini-fab>
                    <mat-icon>add_Box</mat-icon>
                </button>
                Projektgruppe erstellen
            </div>
            <div class="list-section-P">
                <div *ngFor="let project of projects" class="proGruppe-item">
                    <div class="proGruppe" (click)="openChatProject(project)" [ngStyle]="{'background-color': group === project ? '#1565c0' : 'white'}">
                        <mat-icon class="proGruppe-icon" [ngStyle]="{'color': group === project ? 'white' : '#00796b'}">groups</mat-icon>
                        <span class="proGruppe-name" [ngStyle]="{'color': group === project ? 'white' : 'black'}">{{ project.projectName }}</span>
                        <mat-icon class="proGruppe-action-icon" >chevron_right</mat-icon>
                    </div>
                </div>
            </div>
        </div>



    </div>
    
    


</div>
