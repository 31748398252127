import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.css']
})
export class SearchResultsComponent implements OnInit {
  query: string='';
  results: any[] = [];

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.query = params['query'];
      this.searchComponents(this.query);
    });
  }

  searchComponents(query: string) {
    this.results = this.performSearch(query);
  }

  performSearch(query: string): any[] {
    const allComponents = [
      { name: 'Datahub', content: 'Description and content of DatahubComponent', path: '/datahub' },
      { name: 'Main', content: 'Description and content of MainComponent', path: '/start' },
      { name: 'Daseinsvorsorge', content: 'Description and content of DaseinsvorsorgeComponent', path: '/analysen/notfallinfrastruktur' },
      { name: 'Simulation', content: 'Description and content of SimulationComponent', path: '/simulation-section/virusinfektion/covid/simulation' },
      // Add all other components here with relevant content and paths
    ];

    return allComponents.filter(component =>
      component.name.toLowerCase().includes(query.toLowerCase()) ||
      component.content.toLowerCase().includes(query.toLowerCase())
    );
  }

  navigateTo(path: string) {
    this.router.navigate([path]);
  }
}
