import { Component } from '@angular/core';

@Component({
  selector: 'app-mobilitaet',
  templateUrl: './mobilitaet.component.html',
  styleUrls: ['./mobilitaet.component.css']
})
export class MobilitaetComponent {

}
