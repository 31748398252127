

<div> 

<h1 *ngIf="!docPopID"> Bestehendes Ärzteszenario modifizieren oder verändern </h1>
  <h1 *ngIf="docPopID"> Zugesendetes Szenario bearbeiten: </h1>

  <br>
  <br>

  <div style="display: flex; justify-content: space-between;">
  <!--<button mat-fab extended color="primary" style="width: 25%; margin-left: 3%" *ngIf="!searchExtended">
      <mat-icon>add</mat-icon>
      Neues Szenario hinzufügen
    </button>
    

    <button mat-fab extended color="primary" style="width: 5%; margin-left: 3%" *ngIf="searchExtended">
      <mat-icon>add</mat-icon>

    </button>-->



      <input id="nameBox" *ngIf="newExtended" [(ngModel)]="newName" placeholder="Name eingeben..." />


      <button mat-fab extended color="primary" style="width: 25%; " *ngIf="!newExtended" (click)="newExtended = !newExtended">
        <mat-icon>add</mat-icon>
        Neues Szenario hinzufügen
      </button>
      <button mat-fab extended color="primary" *ngIf="newExtended"
        style="width: 15%; position:absolute; top:20; right: 0;" (click)="newExtended = !newExtended; startNewScenario()">
        <mat-icon>save</mat-icon>

      </button>

      <br> 


 


  <div style="margin-left: 10px;margin-right: 10px;" *ngIf="!newExtended">

      <input id="adress2" *ngIf="searchExtended" [(ngModel)]="searchBox" />
      <button mat-fab extended color="primary" *ngIf="!searchExtended" style="width: 150px;"
        (click)="searchExtended = !searchExtended">
        <mat-icon>search</mat-icon>
        Suche
      </button>
      <button mat-fab extended color="primary" *ngIf="searchExtended"
        style="width: 70px; position:absolute; top:20; right: 0;" (click)="searchExtended = !searchExtended">
        <mat-icon>search</mat-icon>

      </button>

    </div>



  </div>

<br> 
<br> 
<br> 
<br> 

<div *ngIf="newExtended"> 
<div *ngIf="!isRente">
  <h2> Fixes Renteneintrittsalter:  <mat-slide-toggle [checked]="isRente" (change)="isRente = $event.checked"></mat-slide-toggle></h2>
</div>
<div *ngIf="isRente" >
  <h2> Fixes Renteneintrittsalter:  {{ rentenAlter }} <mat-slide-toggle [checked]="isRente" (change)="isRente = $event.checked"></mat-slide-toggle></h2>


  <mat-chip-listbox [(ngModel)]="rentenAlter" >
    <div  *ngFor="let item of [].constructor( 101 - 40 ); let i = index"  >
      <mat-chip-option [selected]="true" > {{  (40) +  i }}  </mat-chip-option>
      <br>
      <br>
    </div>
  </mat-chip-listbox>

</div>
</div>


 








  <div style="display: flex; align-items: center;" *ngIf="!newExtended">

    <div id="tile-container">

      <ng-container *ngFor="let scen of existingScenarios">
        <ng-container *ngIf="scen._id == docPopID || !docPopID">

          <div class="tile" (click)="setExistingScenario(scen)"
            *ngIf="scen?.name != 'basis' && ( searchBox == '' || scen?.name?.toLowerCase().includes(searchBox.toLowerCase()) || scen?.createdBy?.toLowerCase().includes(searchBox.toLowerCase()) || checkDate(scen?.createdAt) )">
            <h1> {{ scen.name }} </h1>


            <i class="bi bi-pencil-fill"> von {{ scen.createdBy }} </i> <br>

            <i class="bi bi-calendar-week">
              {{scen.createdAt | date: 'dd-MM-yyyy'}}
            </i> <br>



          </div>
        </ng-container>

      </ng-container>






    </div>


  </div>

</div>