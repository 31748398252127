import { Component, OnDestroy } from '@angular/core';
import { DatahubService } from 'src/app/service/datahubservice/datahubservice.component';
import { Storageservice } from 'src/app/service/storageservice-component/storageservice-component.component';
import * as XLSX from 'xlsx';
import { ReachMatrixComponent } from '../../raum-analysen/reach-matrix/reach-matrix.component';
import {MatSnackBar} from '@angular/material/snack-bar';


@Component({
  selector: 'location-app',
  templateUrl: './location-app.component.html',
  styleUrls: ['./location-app.component.css']
})
export class LocationAppComponent implements OnDestroy{
saveFormData() {
  
if(this.formData.latlng) {
  this.reachComponent.addMarkerWithMapRef(undefined, undefined, this.formData.name, this.formData.address)
  this.formData.name = ''
  this.formData.address = ''
  this.formData.latlng = {}
} else {
  alert("Addresse nicht gefunden!")
}
}
deleteFromMap(marker: any) {
  alert("hallo")

this.reachComponent.deleteCreatedMarker(marker); 
}


uploaded: boolean = false;
upload: any[] = [];
convertToJson: string = '';
formData : any = 
{
  name: "",
  address: "",
  latlng: []
}
importData: any[] = [];
filteredAdressList: any[] = [];
showDropdown: boolean = false;
selectedIndex = 0; 
downloaded: any;
fileName: any;
selectedAddress: any;
checkName: boolean = false;
fileNames: any[] = [];


chooseLocation() {
  
  this.checkName=true;
  this.selectedAddress=undefined
  this.reachComponent.clearUmkreisResults();
  try{
  //@ts-ignore
  document.getElementById("dialog").close();
  //@ts-ignore
  document.getElementById("dialog_mini").close();
   //@ts-ignore
   document.getElementById("mini-dialog").close();
  } catch(e) {
    
  }
  this.reachComponent.addMarker();

}




onFileChange(event: any) {
  this.uploaded = true;
  const file = event.target.files[0];
  const reader = new FileReader();
  this.fileName = file.name;
  this.fileNames.push(file.name);
  reader.readAsBinaryString(file);
  reader.onload = (e: any) => {
    const data = e.target.result;
    const workbook = XLSX.read(data, { type: 'binary' });
    workbook.SheetNames.forEach((sheet) => {
      this.upload = XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);
      console.log(this.upload);


      this.upload.forEach((el: any) => {
         this.reachComponent.addMarkerWithMapRef(undefined, undefined, el.Name, el.Adresse)
      }
      
      
      
      
      
        ); 
      

  
      this.convertToJson = JSON.stringify(this.upload, undefined, 4);
      console.log("JSON", this.convertToJson);
    });
  };

  this._snackBar.open(this.fileName + ' erfolgreich importiert!');
  console.log(this.upload);


 

 
}



async completeDropNew(pos: any, event: any) {

  if(event.key == 'Enter') {
    this.filteredAdressList = [];
    this.saveFormData();
    return; 
  }


  this.filteredAdressList = [];
  this.showDropdown = true; 
  if(pos.address.length < 4) {
    return; 
  }
  var res = await this.datahub.getAutocompleteList(pos.address);
  console.warn("res", res); 
  //@ts-ignore
  this.filteredAdressList = res.features; 
}

addRow() {

  this.formData.push({
    name: '',
    address: '',
    latlng: []
  })
}


exportData() {
  var dataEx = [['Name', 'Adresse']];
  const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(dataEx);
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  XLSX.writeFile(wb, 'eigene_daten.xlsx');
  this.downloaded = true;
}


constructor(private datahub: DatahubService, public storageService: Storageservice, public reachComponent: ReachMatrixComponent, private _snackBar: MatSnackBar){

}
  ngOnDestroy(): void {


    var positions: any[] = []
    positions = positions.concat(this.formData).concat(this.importData).concat(this.storageService.markers).filter(el => el.address != '' && el.address != "Adresse nicht bestimmbar");

    //sanitize input -> check lat lng again
    positions.forEach(async pos => {
      if(pos.address && !pos.latlng ){ //&& !pos.latlng) {
        var resp = await this.datahub.getCoordinatesForAdress(pos.address);
        //@ts-ignore
        if(resp.features[0].geometry.coordinates) {
          //@ts-ignore
          pos.latlng = resp.features[0].geometry.coordinates
        }
      }
    })


 
    this.storageService.positions = positions;

    console.warn(positions); 
  }



}
