import { Component } from '@angular/core';

@Component({
  selector: 'app-raum-main',
  templateUrl: './raum-main.component.html',
  styleUrls: ['./raum-main.component.css']
})
export class RaumMainComponent {

}
