import { Component, Input } from '@angular/core';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-nachfrageberechnung-item',
  templateUrl: './nachfrageberechnung-item.component.html',
  styleUrls: ['./nachfrageberechnung-item.component.css']
})
export class NachfrageberechnungItemComponent {
chart: any;
popChart: any;

@Input() item: any;
  chartObject: Chart<"bar", (number | [number, number] | null)[], unknown> | undefined;
  chartType: string = 'Absolute Nachfrage';
popType: any = 'DOC';
  chartObjectSecond: Chart<"bar", unknown[], string> | undefined;

constructor(){

  setTimeout(() => {
    this.createBarChart('Absolute Nachfrage');
    this.createCategoryBarChart(); 
  }, 500) 


}


createCategoryBarChart() {
  // 1. Zählen der Einrichtungen pro Kategorie
  const categoryCounts: any = {};
  this.item.selectedScenarios[0].pop.forEach((item: { Kategorie: any; }) => {
      const category = item.Kategorie;
      if (category in categoryCounts) {
          categoryCounts[category]++;
      } else {
          categoryCounts[category] = 1;
      }
  });

  // 2. Extrahieren der Kategorien und deren Zählwerte
  const categories = Object.keys(categoryCounts);
  const counts = Object.values(categoryCounts);

  console.warn("XY", categories, counts)


  this.popChart = document.getElementById("PopChart") as HTMLCanvasElement;

  // 4. Rückgabe der Chart-Konfiguration
 // return chartConfig;

 this.chartObjectSecond = new Chart(this.popChart, {
  type: 'bar',
  data: {
      labels: categories,
      datasets: [{
          label: 'Anzahl der Einrichtungen',
          data: counts,
          backgroundColor: 'rgba(54, 162, 235, 0.6)',  // Farbe der Balken
          borderColor: 'rgba(54, 162, 235, 1)',  // Randfarbe der Balken
          borderWidth: 1
      }]
  },
  options: {
      scales: {
          y: {
              beginAtZero: true,
              title: {
                  display: true,
                  text: 'Anzahl'
              }
          },
          x: {
              title: {
                  display: true,
                  text: 'Kategorien'
              }
          }
      },
      plugins: {
          legend: {
              display: true,
              position: 'top'
          }
      }
  }
});
}

 createBarChart(type: any): void {

  this.chartType = type;

 this.chart = document.getElementById("IndiChart") as HTMLCanvasElement;

 if(this.chartObject) {
  this.chartObject.destroy(); 
} 

  if (!this.chart) {
      console.error('Canvas element not found!');
      return;
  }

  var chartInfo : any = {};
  chartInfo.chartData = this.item[type].chartData;
  chartInfo.chartMeta = this.item[type].chartMeta;
  const chartData = {
      labels: chartInfo.chartData.labels,
      datasets: chartInfo.chartData.datasets.map((dataset: { label: any; data: any; backgroundColor: any; borderColor: any; }) => ({
          label: dataset.label,
          data: dataset.data,
          backgroundColor: dataset.backgroundColor,
          borderColor: dataset.borderColor,
          borderWidth: 1,
      }))
  };

  this.chartObject = new Chart(this.chart, {
      type: 'bar',
      data: chartData,
      options: {
          responsive: true,
          plugins: {
              title: {
                  display: true,
                  text: chartInfo.chartMeta.title,
              },
              tooltip: {
                  callbacks: {
                      label: function (context) {
                          return `${context.dataset.label}: ${context.raw} ${chartInfo.chartData.unit}`;
                      }
                  }
              }
          },
          scales: {
              y: {
                  beginAtZero: true,
                  min: chartInfo.chartData.y_min_value,
                  max: chartInfo.chartData.y_max_value,
                  title: {
                      display: true,
                      text: chartInfo.chartData.unit,
                  }
              },
              x: {
                  title: {
                      display: true,
                      text: chartInfo.chartData.label_category,
                  }
              }
          }
      }
  });
}

}
