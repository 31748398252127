<div style="margin-top: 1%; margin-left: 5%; margin-right: 5%; margin-bottom: 1%;">
  <span class="span0"> ÄRZTEBEWEGUNGEN. </span> <span class="span1"> ENTWICKLUNGSPROGNOSEN </span> <br>
  <br>
  <br>


  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.1/font/bootstrap-icons.css" />

  <div class="map-container">
    <div class="map-frame">
     <div id="map">
       <!-- <div class="map-container" style="height: 70vh;" >
          <div class="map" id='map' leaflet [leafletOptions]="options" [leafletLayers]="layers"
            (leafletMapReady)="onMapReady($event)"></div>
        </div>-->

        <div id="chartDisplay" >
       <div id="calenderContainer" (click)="updateChartAndMap(); " >
            <div id="collapseCalender" (click)="toggleCalender()">
              <i id="collapseIconCalender" class="bi bi-arrows-angle-contract"></i>
            </div>
            <div id="calenderDay"> {{ currentYear }}</div>
            <div id="calender-navigation" (click)="updateChartAndMap(); " >
              <div id="scrollable2">
              

                <mat-chip-listbox [(ngModel)]="categoriesSelected" multiple="true">
                  <div *ngFor="let cat of categories" >
                    <mat-chip-option (click)="updateChartAndMap(); "> {{ cat }} </mat-chip-option>
                    <br>
                  </div>
                </mat-chip-listbox>

              </div>
              <div> 
  
              <a style="margin-left: 5px"> Mehr Kategorien hinzufügen </a>
            </div>
  
  
  
  
              </div>
            </div> 
        


           <!--<div class="header">
  
            
              <div id="slideBar">        
                <input id="search"  [(ngModel)]="searchBox2" (keyup)="filterResult()" />
              <div>
              </div>
            </div>
          </div>-->
          <div style="background-color: red;"*ngIf="allDocData && allDocData.length > 0">
   
              <div id="buttonContainer">

                <div style="display: flex; align-items: center; flex-direction: row; width: 200px"> 

                <mat-chip-option selected="true" [(ngModel)]="activeOnly"> aktiv {{ (
                  getTotalNumberForCategory(categoriesSelected, false) ) }}
                </mat-chip-option>

                <mat-chip-option color="warn" [(ngModel)]="inActiveOnly" style='margin-left: 5px' selected="true">
                  gelöscht {{ (
                  getTotalNumberForCategory(categoriesSelected, true) ) }} </mat-chip-option>
              </div>
              <div> 
                
              </div>
                <div style="display: flex; align-items: center; flex-direction: row; width: 100px; margin-right: 10px;">
                 
                  <mat-slider style="width: 50px;" min="0" max="100" step="1" discrete>
                    <input #slider [(ngModel)]="minAge" matSliderStartThumb (click)="updateChartAndMap()">
                    <input #slider [(ngModel)]="maxAge" matSliderEndThumb (click)="updateChartAndMap()">
                  </mat-slider>
                </div>
              </div>


              <div id="slideBar">

                <input id="search" [(ngModel)]="searchBox2" (keyup)="filterResult()" />


              </div>

              <div id="collapseBar" (click)="toggleBarChart()">
                <i id="collapseIconBar" class="bi bi-arrows-angle-contract"></i>
              </div>


              <div id="collapseBar0" (click)="addDoc()">
                <i id="collapseIconBar" class="bi bi-person-plus"></i>
              </div>
    
    
              <div id="collapseBar2"
                (click)="selectedEinrichtung = undefined; selectedEinrichtung.markedForDel = false; "
                *ngIf="selectedEinrichtung">
                <i id="collapseIconBar" class="bi bi-arrow-return-left"></i>
              </div>
    
              <div id="collapseBar3" *ngIf="deleteMode" (click)="deleteMultipleEinrichtung()">
                <i id="collapseIconBar" class="bi bi-trash"></i>
              </div>
     
    



      
            <div>

            <!--

                <div class="scrollable" *ngIf="allDocData.length > 0">
                  <ng-container *ngFor="let einrichtung of allDocData[currentYear - 2024].einrichtung">
                    <ng-container
                      *ngIf="categoriesSelected.indexOf(einrichtung.Typ) > -1 
                      && ((activeOnly && !einrichtung.deleted) || (inActiveOnly && einrichtung.deleted) )
                      && (currentYear - einrichtung.birth) <= maxAge && (currentYear - einrichtung.birth) >= minAge &&
                      ( einrichtung.Name.toLowerCase().includes(searchBox2.toLowerCase())  || searchBox2 == '' || einrichtung.Typ.toLowerCase().includes(searchBox2.toLowerCase()) 
                       || einrichtung.Kategorie.toLowerCase().includes(searchBox2.toLowerCase()) || (currentYear - einrichtung.birth) == searchBox2)  ">
               
                   <div class="list-item" [ngStyle]="{'background-color': getColorForItem(einrichtung) }">

                        <div style="display: flex; align-items: center;">
                          <mat-checkbox style="width:'70px'; height:'70px'" [(ngModel)]="einrichtung.markedForDel">
                          </mat-checkbox>
                        </div>

                        <div *ngIf="!einrichtung.deleted" clickedEinrichtung (click)="clickEinrichtung(einrichtung)"
                          style=" display: flex; justify-content: start; align-items: center; width: 60%;">
                          {{ einrichtung.Name }} <br>
                          {{ einrichtung.Typ }} | {{ einrichtung.Kategorie }} | {{ currentYear - einrichtung.birth }}
                          Jahre
                        </div>

                        <div *ngIf="einrichtung.deleted" clickedEinrichtung (click)="clickEinrichtung(einrichtung)"
                          style=" display: flex; justify-content: start; align-items: center; width: 60%;">
                          <s> {{ einrichtung.Name }} </s> <br>
                          <p> {{ einrichtung.Typ }} | {{ einrichtung.Kategorie }} | {{ currentYear - einrichtung.birth
                            }}
                            Jahre </p>
                        </div>


                        <div>
                          <mat-chip-option clickedEinrichtung disabled (click)="clickEinrichtung(einrichtung)"> {{
                            einrichtung.Typ }} </mat-chip-option>
                        </div>

                      </div>
               

                    </ng-container>
                  </ng-container>
                </div>-->
              </div>


    
              <u *ngIf='checkForDeleteMode() > 0' (click)="deleteMultipleEinrichtung()" style="margin-left: 5px;">
                Auswahl ({{ checkForDeleteMode() }}) löschen </u>
              <br>

              <!--
              <u *ngIf='checkForDeleteMode() > 0' (click)="markAll(false)" style="margin-left: 5px;"> zurücksetzen </u>
              <u (click)="markAll(true)" style="margin-left: 5px;"> alle markieren </u> <u (click)="deleteMode = true"
                style="margin-left: 5px;"> via Karte markieren </u>

              <u (click)="deleteMode = false" *ngIf="deleteMode == true" style="margin-left: 5px;"> via Karte markieren
                beenden </u>-->







        



          </div>



   




        <div id="chartDisplay2">

          <div id="collapse" (click)="toggleChart()">
            <i id="collapseIcon" class="bi bi-arrows-angle-contract"></i>
          </div>
          <div id="pieChartTitle"></div>
          <div class="pieChartDisplay">
            <div id="pieChartContainer" style="height: 250px">
              <canvas id="myChart">{{ chart }}</canvas>
            </div>
            <div id="legende" *ngIf="!renteMode">
              <div> 

                  <p [ngStyle]="{'color': colorPalette[0] }"> < 30 Jahre ({{chart?.data?.datasets[0].data[0] }})</p>
                  <p [ngStyle]="{'color': colorPalette[1] }"> > 30 Jahre ({{chart?.data?.datasets[0].data[1] }})</p>
                  <p [ngStyle]="{'color': colorPalette[2] }"> > 35 Jahre ({{chart?.data?.datasets[0].data[2] }})</p>
                  <p [ngStyle]="{'color': colorPalette[3] }"> > 40 Jahre ({{chart?.data?.datasets[0].data[3] }})</p>

                </div>
                <div> 
                  <p [ngStyle]="{'color': colorPalette[4] }"> > 45 Jahre ({{chart?.data?.datasets[0].data[4] }})</p>
                  <p [ngStyle]="{'color': colorPalette[5] }"> > 50 Jahre ({{chart?.data?.datasets[0].data[5] }})</p>
                  <p [ngStyle]="{'color': colorPalette[6] }"> > 55 Jahre ({{chart?.data?.datasets[0].data[6] }})</p>
                  <p [ngStyle]="{'color': colorPalette[7] }"> > 60 Jahre ({{chart?.data?.datasets[0].data[7] }})</p>
                </div>
            </div>

            <div id="legende" *ngIf="renteMode">
              <div> 

                  <p [ngStyle]="{'color': colorPalette[0] }"> Inaktiv({{chart?.data?.datasets[0].data[0] }})</p>
                  <p [ngStyle]="{'color': colorPalette[1] }"> 1 Jahr ({{chart?.data?.datasets[0].data[1] }})</p>
                  <p [ngStyle]="{'color': colorPalette[2] }"> 2 Jahre ({{chart?.data?.datasets[0].data[2] }})</p>
                  <p [ngStyle]="{'color': colorPalette[3] }"> 3 Jahre ({{chart?.data?.datasets[0].data[3] }})</p>

                </div>
                <div> 
                  <p [ngStyle]="{'color': colorPalette[4] }"> 4 Jahre ({{chart?.data?.datasets[0].data[4] }})</p>
                  <p [ngStyle]="{'color': colorPalette[5] }"> 5 Jahre ({{chart?.data?.datasets[0].data[5] }})</p>
                  <p [ngStyle]="{'color': colorPalette[6] }"> 6 Jahre ({{chart?.data?.datasets[0].data[6] }})</p>
                  <p [ngStyle]="{'color': colorPalette[7] }"> 7 Jahre ({{chart?.data?.datasets[0].data[7] }})</p>
                  <p [ngStyle]="{'color': colorPalette[8] }"> > 8 Jahre ({{chart?.data?.datasets[0].data[8] }})</p>
                </div>
            </div>

          </div>
        </div>


        <div id="chartDisplay3">

          <div id="collapse" (click)="toggleChart()">
            <i id="collapseIcon" class="bi bi-arrows-angle-contract"></i>
          </div>


          <div class="lineChartDisplay">
            <div id="lineChartContainer" >
              <canvas id="myLineChart">{{ lineChart }}</canvas>
            </div>
            <div>
              <mat-chip-listbox [(ngModel)]="graphType" multiple="false">
            <mat-chip-option style="margin: 5px;" (click)="createLineChart()"> Anzahl Einrichtungen </mat-chip-option>
            <mat-chip-option style="margin: 5px;" (click)="createLineChart()"> Durchschnittsalter </mat-chip-option>
          </mat-chip-listbox>
          </div>
          </div>
        </div>





        <div id="btnTest">
          <span id="dayDisplay">Jahr {{ currentYear }}</span>

          <div id="playContainer">
            <button class="zoomBtn" (click)="showStats()">
              <i [ngClass]="'bi bi-graph-down'"></i>
            </button>

            <button class="zoomBtn">
              <i (click)="zoomBLK()" style="font-size: 16px">BLK</i>
            </button>



            <div id="slide-container">

              <input type="range" id="customRange1" value="1" min="2024" max="2034" (change)="updateNextDay($event)" />

              <div class="ticks">


                <ng-container *ngFor="let e of [].constructor(11);
                  let i = index;">

                  <span class="tick" [ngStyle]="{'color': getColorForTicks(i) }"> {{ 2024 + i }} </span>
                </ng-container>


              </div>

            </div>

            <button class="zoomBtn">


              <i (click)="savePop()" [ngClass]=" 'bi bi-floppy-fill'"></i>
            </button>

          </div>
        </div>
      </div>
    </div>
  </div>
  
  <dialog id="dialog">
    <div style="width: 1200px; ">
        <h1> Neuen Arzt anlegen </h1>
  
      <b> Name </b>
      <input id="adress" name="adress" [(ngModel)]="newDocName"/>
      <br>
      <br>
      <b> Alter </b>
      <mat-form-field>
        <mat-label> Eingeben </mat-label>
        <input matInput [(ngModel)]="newBirthYear" >
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <br>
      <br>
      <br>
      <b> Fachrichtung </b>
      <mat-chip-listbox  [(ngModel)]="newDocCat" multiple="false">
        <mat-chip-option *ngFor="let cat of categories" > {{ cat }} </mat-chip-option>
  
      </mat-chip-listbox>



      <br>
      <br>

   
      <b> Standort </b>
      <br>
      <br>
  
  
      <b style="margin-left: 3%"> Geben Sie eine beliebige Adresse ein </b>
  
      <div id="container">
        <div id="inPutContainer">
          <div>
            <input id="adress" name="adress"  style="width: 100%; margin-left: 20px" [(ngModel)]="formData.address" placeholder="Adresse..."
                (keyup)="completeDropNew(formData, $event);" />
        </div>
        <div *ngIf="filteredAdressList.length > 0"
            style="max-height: 100px; width: 100%; overflow-y: scroll; border-bottom-right-radius: 15px; border-bottom-left-radius: 15px; border: 1px solid #10684E; padding-left: 8px;border-top: none; ">
            <div *ngFor="let address of filteredAdressList"
                (click)="formData.address = address.properties.label; filteredAdressList = []; formData.latlng = address.geometry.coordinates;saveFormData(); "
                class="dropAd">
                {{ address.properties.label }} 
            </div>
        </div>
        </div>
      </div> <!--
  
      <div style="display: flex; justify-content: center; flex-direction: row; " id="oder">
        <div>
          <b> oder </b>
        </div>
      </div>
  
      <br>
  
  
      <b style="margin-left: 3%">Wählen Sie einen Punkt auf der Karte aus </b> <br>
  
  
  
     <div style="display: flex; justify-content: center; flex-direction: row; ">
        <div>
          <br>
          <b>Punkt wählen </b>
  
  
  
          <button mat-mini-fab aria-label="Example icon button with a home icon"
            style="background-color: #10684E; color: white" (click)="chooseLocation(); selectedAddress=undefined">
            <mat-icon>my_location </mat-icon>
          </button>
        </div>
      </div> -->
  
      <div style="display: flex; justify-content: center; flex-direction: row; ">
        <div>
          <br>
  
          <br>
  
  
  
  
          <div style=" display: flex; align-items: center; justify-content: center;">
            <div style="width:50%">
  
              <img *ngIf="storageService.imagePath != ''" id="image" [src]="storageService.imagePath" width="100%"
                alt="Place image title" (load)="checkSteetName()">
            </div>
          </div>
          <br>
  
          <br>
        </div>
      </div>
  
 
  
      <button class="zoomBtn" (click)="addNewDoc()">
        <i [ngClass]="'bi bi-floppy-fill'"></i>
      </button>
  
  
  
    </div>
  </dialog>
  
  
<!--<dialog id="dialog2">
  
    <h1> Arztebewegungsmodell speichern </h1>
  
    <b> Name </b>
    <input [(ngModel)]='newDocName' id="adress" name="adress" />
    <br>
    <br>
    <b> Fachrichtung </b>
    <mat-chip-listbox [(ngModel)]="categoriesSelectedForNew">

      <div *ngFor="let cat of categories">
        <mat-chip-option> {{ cat }} </mat-chip-option>
      </div>
    </mat-chip-listbox>
    <br>
    <br>
    <b> Geburtsdatum </b>

    <mat-form-field class="example-form-field">
      <mat-label> </mat-label>
      <input matInput [matDatepicker]="datepicker">
      <mat-hint>MM/DD/YYYY</mat-hint>
      <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
      <mat-datepicker #datepicker>
        <mat-datepicker-actions>
          <button mat-button matDatepickerCancel>Cancel</button>
          <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
        </mat-datepicker-actions>
      </mat-datepicker>
    </mat-form-field>


    <br>
    <br>
    <br>

    <b> Standort </b>
    <br>
    <br>


    <b style="margin-left: 3%"> Geben Sie eine beliebige Adresse ein </b>

    <div id="container">
      <div id="inPutContainer">
        <div>
          <input id="adress" name="adress" [(ngModel)]="selectedAddressStr" 
            placeholder="Adresse..." (keyup)="completeDrop()" />
        </div>
        <div *ngIf="filteredAdressList.length > 0"
          style="max-height: 150px; overflow-y: scroll; border-bottom-right-radius: 15px; border-bottom-left-radius: 15px; border: 1px solid #10684E; padding-left: 8px;border-top: none; ">
          <div *ngFor="let address of filteredAdressList" (click) = "selectedAddress = address; selectedAddressStr = address.properties.label; filteredAdressList = []"
            class="dropAd">
              {{ address.properties.label }}
          
          
          </div>
        </div>
      </div>
    </div>



    <div style="display: flex; justify-content: center; flex-direction: row; " id="oder">
      <div>
        <b> oder </b>
      </div>
    </div>

    <br>


    <b style="margin-left: 3%">Wählen Sie einen Punkt auf der Karte aus </b> <br>



    <div style="display: flex; justify-content: center; flex-direction: row; ">
      <div>
        <br>
        <b>Punkt wählen </b>



        <button mat-mini-fab aria-label="Example icon button with a home icon"
          style="background-color: #10684E; color: white" (click)="chooseLocation(); selectedAddress=undefined">
          <mat-icon>my_location </mat-icon>
        </button>
      </div>
    </div>

    <div style="display: flex; justify-content: center; flex-direction: row; ">
      <div>
        <br>

        <br>




        <div style=" display: flex; align-items: center; justify-content: center;">
          <div style="width:50%">

            <img *ngIf="storageService.imagePath != ''" id="image" [src]="storageService.imagePath" width="100%"
              alt="Place image title" (load)="checkSteetName()">
          </div>
        </div>
        <br>

        <br>
      </div>
    </div>



    <button class="zoomBtn" (click)="saveNewDoc()">
      <i [ngClass]="'bi bi-floppy-fill'"></i>
    </button>



 
</dialog>-->
</div>


<dialog id="dialog2">

  <h1> Arztebewegungsmodell speichern </h1>

  <b> Name </b>

  <input id="adress" name="adress" [(ngModel)]="popName" />



  <br> <br>

  <b> Sichtbarkeit </b>

  <mat-chip-listbox [(ngModel)]="selectedSichtbarkeit">

    <div *ngFor="let cat of ['public', 'protected', 'privat']">
      <mat-chip-option> {{ cat }} </mat-chip-option>
    </div>
  </mat-chip-listbox>


  <br>



  <div *ngIf="selectedSichtbarkeit == 'public'">
    Jeder kann bearbeiten und lesen
  </div>

  <div *ngIf="selectedSichtbarkeit == 'protected'">
    Sie können auswählen, wer welche Rechte besitzt:



    <mat-chip-listbox [multiple]="true">

      <div *ngFor="let user of mockUser" style="display: flex; flex-direction: column;">
        <div>
        <mat-chip-option disabled> {{ user.firstname }} {{ user.lastname }} | {{user.email}} </mat-chip-option>
        <mat-chip-option [selected]="user.canRead" (click)="user.canRead? user.canRead = false:  user.canRead = true">
          darf lesen </mat-chip-option>
        <mat-chip-option [selected]="user.canWrite "
          (click)="user.canWrite? user.canWrite = false:  user.canWrite = true"> darf schreiben </mat-chip-option>
        </div> <br> 
      </div>
    </mat-chip-listbox>



  </div>
  <div *ngIf="selectedSichtbarkeit == 'privat'">
    Nur Sie sehen und bearbeiten {{ popName }}
  </div>


  <button class="zoomBtn" (click)="saveDoc()">
    <i [ngClass]="'bi bi-floppy-fill'"></i>
  </button>
</dialog>


<dialog id="dialog4">

  <h2> Szenario {{ popName }} wurde gespeichert und kann nun für weiterreichende Funktionalitäten verwendet werden. Wie möchten Sie fortfahren?</h2>
<div style="display: flex; flex-direction: column; justify-content: space-around; align-items: center;"> 
  <button mat-fab extended color="primary" style="width: 50%; " (click)="nextStep('/analysen/reachability')">
    <mat-icon>east</mat-icon>
    Erreichbarkeitsanalysen  </button> <br> 
    <br> 

  <button mat-fab extended color="primary" style="width: 50%; "(click)="nextStep('/prognosen/nachfrageberechnung')" >
    <mat-icon>east</mat-icon>
    Nachfrageberechnung med. Infrastruktur  </button>
    <br> 
    <br> 
  <button mat-fab extended color="primary" style="width: 50%; " (click)="nextStep('/datahub2/docs')">
    <mat-icon>south</mat-icon>
    Neues Ärzte-Szenario erstellen   </button>
    <br> 
    <br> 
  </div>


  
</dialog>


<dialog id="dialog3" >
  <doc-popup [docPopID]="docPopID"> </doc-popup>
</dialog>