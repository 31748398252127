<div class="map-container" style="height: 100%">
    <div class="map" id="map"></div>
  </div>
  
  <!-- <div class="gebiet">
    <button (click)="openDialogGebiete()" class="gebiet-btn">
      <i class="bi bi-arrow-clockwise"></i> Gebiete Laden
    </button>
  </div> -->
  
  <div class="grafik">
    <button (click)="showGrafik()" class="grafik-btn">Grafik Anzeigen</button>
  </div>
  
  <div class="save">
    <button class="save-btn">Speichern</button>
  </div>
  
  <div class="share">
    <button class="share-btn">Teilen</button>
  </div>
  
  <div class="schoolIndi">
    <div
      style="font-size: 14px; font-weight: bold; color: black; text-align: center"
    >
      Anzahl der Schüler:
    </div>
  
    <div style="font-size: 24px; font-weight: bold; color: #007bff">
      {{ totalPoints }}
    </div>
  </div>
  
  <div class="grfikChart" style="display: none">
    <canvas id="chartCanvas"></canvas>
  </div>
  