import { Component, OnInit } from '@angular/core';
import { SynthService } from 'src/app/service/synthService/synth.service';
import { Chart } from 'chart.js';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-comparison-prognose',
  templateUrl: './comparison-prognose.component.html',
  styleUrls: ['./comparison-prognose.component.css']
})
export class ComparisonPrognoseComponent implements OnInit {

  constructor(private synthService: SynthService){}

  indicators=['Anteil alte Bevölkerung','Anteil junge Bevölkerung','Anteil pot. Erwerbstaetige','Gesamtbevoelkerung','Herzerkrankte pro 1000 Eiwohner'];
  populations:any;
  chartTitle:string='';
  chart: any;
  radarChart: any;
  selectedPopulations: string[] = [];
  selectedIndicator:string='';
  selectedPopulation:string='';
  indiSpinner=false;
  popSpinner=false;

  async ngOnInit(){
    const res:any = await this.synthService.getPopuationNames();
    this.populations = res.popNames;
    this.selectedPopulations = res.popNamesNewest;
    this.selectedIndicator = 'Anteil alte Bevölkerung'
    this.chartTitle = this.selectedIndicator;

    const initialData: any = await this.synthService.getInitialChartData(this.selectedIndicator,  this.selectedPopulations);
    this.renderChart(initialData);
  }

  async onIndicatorChange() {
    this.indiSpinner=true
    const initialData: any = await this.synthService.getInitialChartData(this.selectedIndicator, this.selectedPopulations);
    setTimeout(() => {
      this.chartTitle = this.selectedIndicator
      this.updateChart(initialData);
      this.indiSpinner=false
    }, 1500);
  }

  onPopulationChange(population: string, event: any) {
    if (event.checked) this.selectedPopulations.push(population);
    else this.selectedPopulations = this.selectedPopulations.filter(pop => pop !== population);
  }

  async changedPopulations(){
    this.popSpinner=true;
    const initialData: any = await this.synthService.getInitialChartData(this.selectedIndicator, this.selectedPopulations);
      setTimeout(() => {
      this.updateChart(initialData);
      this.popSpinner=false
    }, 1500);
  }

  renderChart(data: any) {
    const ctx = document.getElementById('myChart') as HTMLCanvasElement;
    this.chart = new Chart(ctx, {    
      type: 'bar',
      data: {
        labels: data.labels,
        datasets: data.datasets
      },
      options: {
        responsive: true,
        scales: {
          x: {
            title: {
              display: true,
              text: 'Jahre'
            }
          },
          y: {
            beginAtZero: true,
              title: {
              display: true,
              text: data.unit
            }
          }
        }
      }
    });
  }

  updateChart(data: any) {
    console.log(data);
    
    this.chart.data.labels = data.labels;
    this.chart.data.datasets = data.datasets;
    this.chart.options.scales.y.title.text = data.unit;
    this.chart.update();
  }

  /**
   * schows average data so it needs to be fixed
   */
  async renderRadarChart() {
    const radarData: any = await this.synthService.getRadarChartData(this.selectedPopulation);
    console.log(radarData);

    const ctx = document.getElementById('myRadarChart') as HTMLCanvasElement;
    this.radarChart = new Chart(ctx, {
      type: 'radar',
      data: {
        labels: radarData.labels,
        datasets: radarData.datasets
      },
    });
  }
  
}
