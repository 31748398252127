import { Component, OnInit } from '@angular/core';
import { DatahubService } from 'src/app/service/datahubservice/datahubservice.component';
import { KeycloakOperationService } from 'src/app/service/keycloakService/keycloak-operation.service';
import { VorsorgeserviceComponent } from '../vorsorgeservice/vorsorgeservice.component';
import { Chart } from 'chart.js';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { NachfrageberechnungNewComponent } from '../nachfrageberechnung-new/nachfrageberechnung-new.component';

@Component({
  selector: 'app-nachfrageberechnung-select',
  templateUrl: './nachfrageberechnung-select.component.html',
  styleUrls: ['./nachfrageberechnung-select.component.css']
})
export class NachfrageberechnungSelectComponent implements OnInit {

  canRead: Set<number> = new Set();
  canWrite: Set<number> = new Set();
  storedObject: any[] = [];

onSelectionChanged($event: MatCheckboxChange,arg1: any) {
  arg1.IsSelected = $event.checked;

}

toggleUserRead(userId: number, isChecked: boolean) {
  if (isChecked) {
    this.canRead.add(userId);
  } else {
    this.canRead.delete(userId);
  }
}

toggleUserWrite(userId: number, isChecked: boolean) {
  if (isChecked) {
    this.canWrite.add(userId);
  } else {
    this.canWrite.delete(userId);
  }
}
  async saveItem() {

  //at first, get result

  var parsedData: any[] = []
  this.demandTable.chartData.datasets.forEach((d: { data: any[]; label: any; }) => {
var counter = 0;
var values: { Alter: any; Inanspruchnahme_Leistung: any; }[] = [];
d.data.forEach((value: any) => {
  values.push(

    {
      "Alter" :  this.demandTable.chartData.labels[counter],
      "Inanspruchnahme_Leistung": value
    }
  )

  counter++;
});

parsedData.push({
  "Sex": d.label ,
  "data": values
})


    
  });


  var body = {
    
    "admin_level": 3,
    "name_supply_scenario":  this.selectedScenarios,
    "indicator": 4,
    "pop_name": this.selectedPop.map(obj => obj.pop_name), 
    "typ": this.selectedPrognoseTyp,
    "filterKeys": [],
    "focusedKey": "15084",
    "objectKey": "15084",
    "parameters_calculation": parsedData}
    console.warn("body", body); 

  // get result

  var result = this.datahubService.getMedNachfrageResult(body); //todo: delete
  console.warn("RES", result)


  //store item

 

  const canReadString = [...this.canRead].map(num => num.toString());
  const canWriteString = [...this.canWrite].map(num => num.toString());

  result.canRead = canReadString;
  result.canWrite = canWriteString;
  result.visibility = this.selectedSichtbarkeit
  result.name    = this.selectedName;
  result.owner   = this.currentUser.id
  result.demandTable = this.demandTable;
  result.selectedPop = this.selectedPop.map(obj => obj.pop_name); 
  result.selectedScenarios = this.selectedScenarios;

  var resp = await this.datahubService.addNachfrageData(result)
  console.warn("RES", resp)

  this.nachfr.start = false; 
 
}
chartGeneral: any;
indicators: any = []
  selectedInd: any;
  canvas: any;
  demandTable: any = [];
  chartTitle: any;

  startY = {}
  datasetIndex: any = 0;
selectedName: any;
selectedSichtbarkeit: any;
  userList: any;
  currentUser: any;


  async reset() {

    this.changedIndizesF = [];
    this.changedIndizesM = []; 
    this.demandTable = await this.vorsorgeService.getDemandTable("Allgemeinmedizin")
    console.warn("demandTable", this.demandTable)


    this.showChartTable()


  }

  existingScenarios: any[] = [];
  selectedScenarios: any[] = []; 
  selectedPop: any[] = []; 
  userID: any;


pop: any;
changedIndizesF: any = [];
changedIndizesM: any = [];
  async setDocScenario(type: any) {
  if(this.selectedScenarios.length > 0 && this.selectedPop.length > 1) {
    alert("nicht möglich, mehrere Pop erlauben nur eine Scen")
    return; 
  }

  if( this.selectedScenarios.indexOf(type) == -1) {
  this.selectedScenarios.push(type); 
  } else {
    this.selectedScenarios.splice(this.selectedScenarios.indexOf(type) )
  }

  this.demandTable = await this.vorsorgeService.getDemandTable("Allgemeinmedizin")
  console.warn(this.demandTable)
  this.indicators = await this.vorsorgeService.getIndicators("Allgemeinmedizin")
  this.selectedInd = this.indicators[0]

 



}


async showChartTable() {


  var yAxisLabel = this.demandTable.chartDescription['Y-Axis']
  var xAxisLabel = this.demandTable.chartDescription['X-Axis']
  this.chartTitle = this.demandTable.chartDescription.Title
  




  this.chartGeneral?.destroy();
  var chartDisplay = {
    type: 'line',
    data: {
      //@ts-ignore
      labels: this.demandTable.chartData.labels,
      //@ts-ignore
      datasets: this.demandTable.chartData.datasets
    },
    options: {
      scales: {
        y: {
          title: {
            display: true,
            text: yAxisLabel
          }
        },

      },
      plugins: {
        legend: {
          display: false,
        }
      }
    }
  }

  setTimeout(() => {
    this.chartGeneral = new Chart("canvasGeneral", chartDisplay);
    this.canvas = document.getElementById("canvasGeneral");
    //@ts-ignore
    this.canvas.addEventListener("pointerdown", (event) => {
      this.downHandler();
    });


    this.canvas.addEventListener('mouseup', (e: any) => {
      var mousPosY = this.getMousePos(this.canvas, e).y;
      var yAxis = this.chartGeneral.scales.y;
      var value = yAxis.getValueForPixel(mousPosY);
      //@ts-ignore
      this.chartGeneral.data.datasets[this.datasetIndex].data[this.startY.index] = Number(value.toFixed(2));

      if (this.datasetIndex == 0) {
        //@ts-ignore
        this.changedIndizesF.push(this.startY.index)
      } else {
        //@ts-ignore
        this.changedIndizesM.push(this.startY.index)
      }


      this.chartGeneral.update();



      this.startY = {};



    }, 0);

  }, 400);


}


getMousePos(canvas: any, evt: any) {
  var rect = canvas.getBoundingClientRect();
  return {
    x: evt.clientX - rect.left,
    y: evt.clientY - rect.top
  };
}

downHandler() {

  const points = this.chartGeneral.getElementsAtEventForMode(event, 'nearest', { intersect: true }, false);

  
  if (points.length > 0) {



    // grab nearest point, start dragging
    var activePoint = points[0]; 
    this.datasetIndex = activePoint.datasetIndex;


    this.startY = {
      val: activePoint.element.$context.raw,
      y: activePoint.element.$context.element.y,
      index: activePoint.index
    }


    this.canvas.onpointermove = this.moveHandler(event, activePoint);
  };

}
moveHandler(event: Event | undefined, activePoint: { _chart: { data: any; }; _datasetIndex: any; _index: string | number; } | null) {




  // locate grabbed point in chart data
  if (activePoint != null) {



    var chartArea = this.chartGeneral.chartArea;
    var yAxis = this.chartGeneral.scales.y;//["y-axis-0"];



    yAxis.getValueForPixel();



    //@ts-ignore

    return;

  };
};

setPop(pop: any) {
  if(this.selectedScenarios.length > 1 && this.selectedPop.length > 0) {
    alert("nicht möglich, mehrere Seznarien erlauben nur eine Pop")
    return; 
  }



  if(this.selectedPop.indexOf(pop) == -1) {
  this.selectedPop.push(pop); } else {
    this.selectedPop.splice(this.selectedPop.indexOf(pop))

  }
  }
searchBox: any;
navigateForward(arg0: number) {
  this.selectedIndex = arg0
}
navigate(arg0: number) {
throw new Error('Method not implemented.');
}

  selectedIndex = 0;
  selectedPrognoseTyp = '';
setPrognoseTyp(type: string) {
  this.selectedScenarios.push(type); 
}


constructor( private keyCloakService: KeycloakOperationService, private nachfr: NachfrageberechnungNewComponent, 
  private datahubService: DatahubService, private vorsorgeService: VorsorgeserviceComponent) { 

    this.keyCloakService.getUsersList().subscribe((users: any) => {
      console.log(users);
      this.userList=users
    });

    this.keyCloakService.getUserProfile().then((data: any) => {
      this.currentUser= data;
      console.warn("DATA", data)
    });

  }

async ngOnInit(): Promise<void> {

    
  this.keyCloakService.getUserProfile().then(async (data: any) => {
    this.userID = data.id;
  

//@ts-ignore
this.existingScenarios = await this.datahubService.getDocData(this.userID);

this.pop = await this.vorsorgeService.getPopulation();
//@ts-ignore
this.storedObject =  await this.datahubService.getNachfrageData()

console.log("data", this.existingScenarios, this.pop )

console.warn("stored",  this.storedObject); 


});

}
}
