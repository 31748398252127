import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import * as htmlToImage from 'html-to-image';
import html2canvas from 'html2canvas';
import { pdfMake } from 'pdfmake/build/vfs_fonts';

import dayjs from 'dayjs';

declare var require: any
//@ts-ignore
const pdfMake = require('pdfmake/build/pdfmake.js');
import pdfFonts from 'pdfmake/build/vfs_fonts';


@Component({
  selector: 'app-dialog-report',
  templateUrl: './dialog-report.component.html',
  styleUrls: ['./dialog-report.component.css']
})
export class DialogReportComponent implements OnInit{
ind: any = [{
  name:"Test",
  filterName: "Filter",
  pageType: "chart",

}];


public generatePDF() {

  const pages = Array.from(document.getElementsByClassName('reporting-page'));
   if (pages) {
    const pagesContent: any = [];
    pages.forEach((p: any, index: number) => {
      
      html2canvas(p, {
        height: 1200,
        width: 1200,
        scale: 3,
        backgroundColor: null,
        logging: false
      }).then((canvas: any) => {
         pagesContent.push({
          image: canvas.toDataURL(),
          width: 810,
          height: 940
        });
         
        if (index === pages.length-1) {
          pdfMake.vfs = pdfFonts.pdfMake.vfs;

          // Prepare pdf structure
          const docDefinition = {
            content: pagesContent,
            pageOrientation: 'landscape',
            pageSize: 'A4'
          };

          pdfMake.createPdf(docDefinition).open();
        }
      });
    });
  }
}

getDateTime() {
return 'HHH'
}
  imageSource: any;



  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private sanitizer: DomSanitizer
  ) {
    this.ind = data; 
  }






  ngOnInit () {    
    console.warn('data', this.data)

    this.data = this.data.replace('data:image/png;base64,', '')
    console.warn('data', this.data)
    this.imageSource = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${this.data}`);
  }
}
