<mat-vertical-stepper #stepper (selectionChange)="onStepChange($event)">
  <mat-step>
    <ng-template matStepLabel>Wählen Sie die Regione (max. 5)</ng-template>
      <div class="regions">
        <ng-container *ngFor="let l of localData.chartLegend" >
          <mat-checkbox [checked]="!l.disabled" 
                        (change)="toggleSelection(l)"
                        [disabled]="l.forceDisabled">
            {{ l.label }}
          </mat-checkbox>
        </ng-container>
      </div>
  </mat-step>


  <mat-step>
    <ng-template matStepLabel>Wählen Sie die Indikatoren</ng-template>

    <app-indikatorenauswahl
    [regioMetaData]="data.regioMetaData"
    [selectedLevel]="data.selectedLevel"
    >

    </app-indikatorenauswahl>

  </mat-step>


  <mat-step>
    <ng-template matStepLabel>Wählen Sie ein Jahr</ng-template>
    <div  style="margin-top: 10px;">
      <!-- <mat-slider min="{{ data.minYear }}" 
                  max="{{ data.maxYear }}" 
                  step="1" 
                  showTickMarks="true" 
                  discrete="true" 
                  style="width: 400px;">
        <input matSliderThumb value="{{ data.maxYear }}" [(ngModel)]="selectedMaxYearRadar">
      </mat-slider> -->
      <div *ngFor="let indi of selectedIndicators">
        <div style="border-bottom:1px solid #ccc;padding-bottom: 10px;">
           <h4>{{indi.Name}} :</h4>
            <div style="display:flex; justify-content:flex-start; align-items:center; width:100%; gap:5px; flex-wrap: wrap;">
              <div *ngFor="let year of indi.years">
                <button  mat-raised-button  [color]="indi.clickedYear === year ? 'warn' : 'primary' " (click)="setClickedYear(indi,year)">
                  {{year}} 
                </button>
              </div>
            </div>
        </div>
      </div>
    </div>


    <ng-template matStepLabel>Bestätigen</ng-template>
    <p *ngIf="selectedIndicators.length < 3 || selectedIndicators.length > 5">min.3 Indikatoren müssen ausgewählt sein! max.5</p>
    <div class="diaFooter" style="margin-top: 20px;">
      <button (click)="apply()" mat-raised-button color="primary" [disabled]="selectedIndicators.length < 3 || selectedIndicators.length > 5">Radar Erstellen</button>
      <!-- <button (click)="close()" mat-raised-button color="warn">Schließen</button> -->
    </div>
    

  </mat-step>
  
</mat-vertical-stepper>
