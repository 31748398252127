import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-matsim',
  templateUrl: './matsim.component.html',
  styleUrls: ['./matsim.component.css']
})
export class MatsimComponent {

  mode = 0;

  constructor(private router: Router){}

  clickTile(mode: number) {
    this.mode = mode;
    if(mode == 1) this.router.navigateByUrl("/networks"); 
  }


}

