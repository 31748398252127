import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { StreetXpertService } from 'src/app/service/streetXpertService/street-xpert.service';

@Component({
  selector: 'app-create-networkdialog',
  templateUrl: './create-networkdialog.component.html',
  styleUrls: ['./create-networkdialog.component.css']
})
export class CreateNetworkdialogComponent {
  loading = false;
  remainingTime = 120; 
  timer: any;
  networkTitle = '';
  networkDescription = '';
  changeMessages: any[] = []; 
  deleteMessages: any[] = []; 
  step=1;

  constructor(
    private router: Router,
    private streetXpert: StreetXpertService,
    public dialogRef: MatDialogRef<CreateNetworkdialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { uuid: string,globalChanges: any },
  ) {
    this.changeMessages = data.globalChanges.propertyChanges;
    this.deleteMessages = data.globalChanges.deletions;
  }

  CreatNetworkWithMatsim() {
    if (this.loading) return;

    this.startTimer();
    this.loading = true;
    this.streetXpert.postNetwork(this.data.uuid,this.networkTitle, this.networkDescription, this.data.globalChanges).subscribe({
      next: (response:any) => {
        this.loading = false;
        this.stopTimer();
        const simulationUUID=response.newSimulationFolder

        this.router.navigate(['/matsim-simulation', this.data.uuid, simulationUUID]);
        this.dialogRef.close(response.newSimulationFolder);
      },
      error: (error) => {
        console.error('Error occurred:', error);
        this.loading = false;
        this.stopTimer();
      }
    });
  }

  closeAll() {
    this.dialogRef.close();
  }

  startTimer() {
    this.timer = setInterval(() => {
      if (this.remainingTime > 0) {
        this.remainingTime--;
      } else {
        this.stopTimer();
      }
    }, 1000);
  }

  stopTimer() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }
}
