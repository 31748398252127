
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardService } from 'src/app/service/dashboardService/dashboard.service';
import { KeycloakOperationService } from 'src/app/service/keycloakService/keycloak-operation.service';


@Component({
  selector: 'app-dialog-version',
  templateUrl: './dialog-version.component.html',
  styleUrls: ['./dialog-version.component.css']
})
export class DialogVersionComponent {

  dashboardId:string='';
  version!:number;
  versionArray!:number[];  
  selectedVersion: number = 0;
  userNames: { [key: string]: string } = {};
  version1Data: { userId: string; created_At: Date }[] = [];
  otherVersionsData: { version: number; modified_By: string; modified_At: Date }[] = [];
  isLoading = true;

  constructor(
    public dialogRef: MatDialogRef<DialogVersionComponent>,
    private router: Router,
    private route:ActivatedRoute,
    private dashboardService: DashboardService,
    private keyCloakService: KeycloakOperationService,
  ){
    this.route.firstChild!.params.subscribe(
      (params: any) => {
          if (params.hasOwnProperty('version') != '') {
            this.dashboardId=params['id'];
            this.version =params['version'];
            this.dashboardService.getVersions(this.dashboardId).subscribe((data: number[]) => {
              console.log(data);
              this.versionArray = data;
            });
          }
      });

      const collectionName = this.dashboardId;
      this.dashboardService.getDashboardHistory(collectionName).subscribe((response:any) => {  
        console.log(response);
        
        this.version1Data = response.version1Data;
        this.otherVersionsData = response.otherVersionsData;
      
        [...this.version1Data, ...this.otherVersionsData].forEach((item:any) => {
          if (item.userId) {
            this.getUSerBasedOnId(item.userId);
          }
          if (item.modified_By) {
            console.log(item);
            
            this.getUSerBasedOnId(item.modified_By);
          }
        });

        this.isLoading = false;
      });
  }

  formatToGermanDate(timestamp: any) {
    const date = new Date(timestamp);
    const options: any = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
    };
    return new Intl.DateTimeFormat('de-DE', options).format(date).replace(',', ' um');
  }

  onSelectionChange(event: any) {
    console.log('Selected value:', this.selectedVersion);
    this.router.navigateByUrl('/dashboards/' +this.dashboardId+ '/'+ this.selectedVersion); 
    this.dialogRef.close();
  }


  getUSerBasedOnId(userId: any): void {
    console.log(userId);
    
    if (this.userNames[userId]) {
      return; 
    }
    this.keyCloakService.getUsersList().subscribe((users: any) => {
      const foundUser = users.find((user: any) => user.id === userId);
      if (foundUser) {
        const fullName = `${foundUser.firstName} ${foundUser.lastName}`;
        this.userNames[userId] = fullName;
      } else {
        this.userNames[userId] = "Ersteller kann nicht identifiziert werden";
      }
    });
  }

  }
