<div style="display: flex; flex-direction: row; align-items: space-between;">

    <div class="tableContainer">
        <div class="tableHeader" *ngFor="let marker of reachComponent.markersObjRefMap| keyvalue; let i = index">

            <div class="tableCell1">
                <button  mat-icon-button color="warn"
                    (click)="deleteFromMap(marker)">
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
            <div class="tableCell2">
                <input name="name" placeholder="{{ 'Marker ' + i}}"  style="width: 100%; " [(ngModel)]="marker.value.plain.name " />
            </div>
            <div class="tableCell3">
                <input name="adress" style="width: 100%; margin-left: 20px" [(ngModel)]="marker.value.plain.address"
                    placeholder="Beispieladresse..." (click)="completeDropNew(marker.value.plain.address, $event)"
                    (keyup)="completeDropNew(marker.value.plain.address, $event); selectedIndex = i + formData.length"
                    />
                <div>
                    <div *ngIf="filteredAdressList.length > 0 && (i + formData.length )== selectedIndex"
                        style="max-height: 100px; width: 100%; overflow-y: scroll; border-bottom-right-radius: 15px; border-bottom-left-radius: 15px; border: 1px solid #10684E; padding-left: 8px;border-top: none; ">
                        <div *ngFor="let address of filteredAdressList"
                            (click)="marker.value.plain.address = address.properties.label; filteredAdressList = []; marker.value.plain.latlng = address.geometry.coordinates;addRow()"
                            class="dropAd">
                            {{ address.properties.label }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="tableHeader" >
            <div class="tableCell1">
                <button mat-icon-button 
                    (click)="saveFormData()">
                    <mat-icon>save</mat-icon>
                </button>
            </div>
            <div class="tableCell2">
                <input name="name" placeholder="Name..." style="width: 100%; " [(ngModel)]="formData.name " />
            </div>
            <div class="tableCell3">

                <div>
                    <input name="adress" style="width: 100%; margin-left: 20px" [(ngModel)]="formData.address" placeholder="Adresse..."
                        (keyup)="completeDropNew(formData, $event);" />
                </div>
                <div *ngIf="filteredAdressList.length > 0"
                    style="max-height: 100px; width: 100%; overflow-y: scroll; border-bottom-right-radius: 15px; border-bottom-left-radius: 15px; border: 1px solid #10684E; padding-left: 8px;border-top: none; ">
                    <div *ngFor="let address of filteredAdressList"
                        (click)="formData.address = address.properties.label; filteredAdressList = []; formData.latlng = address.geometry.coordinates;saveFormData(); "
                        class="dropAd">
                        {{ address.properties.label }} 
                    </div>
                </div>
            </div>

        </div>

        <div class="tableHeader" >
            <div class="tableCell1">

               <!-- <button mat-mini-fab style="background-color: #10684E; color: white"
                (click)="addRow()">
                <mat-icon >add</mat-icon>
              </button>-->
        
            </div>
            <div class="tableCell2">
            </div>
            <div class="tableCell3">

             
            </div>
        </div>


    </div>


    <div class="controlContainer">
        <div>
            <div class="center">

                <button mat-mini-fab style="background-color: #10684E; color: white"
                    (click)="chooseLocation(); selectedAddress=undefined">
                    <mat-icon>my_location </mat-icon>
                </button> Punkte auf Karte auswählen

            </div>

            <div class="center">

                <b> oder </b> <br>
            </div>

            <div class="center">
                <input style="display: none;" type="file" (change)="onFileChange($event)" accept=".xlsx" #fileUpload
                    class="file-input">


                <button mat-mini-fab style="background-color: #10684E; color: white" (click)="fileUpload.click()">
                    <mat-icon>attach_file </mat-icon> </button> Datei (.csv) hochladen <br>

                <button mat-mini-fab style="background-color: #10684E; color: white" (click)="exportData();">
                    <mat-icon>file_download </mat-icon> </button> Vorlage (.csv) herunterladen

            </div>

        </div>

    </div>

</div>