import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { GlobalVariable } from 'global';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-llama',
  templateUrl: './llama.component.html',
  styleUrls: ['./llama.component.css']
})
export class LlamaComponent {

  private baseApiUrl = GlobalVariable.LLAMA_URL;

  constructor(private http: HttpClient) {

  }
  async addChat(search: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Basic ' + btoa('bunsenuser:babyschnaps')
      })
    };



    search = "Hallo, ich würde gerne alle Allgemeinmediziner im Umkreis von 60 minmit dem AUto herausfinden. Der Startpunkt ist Gustav-Mahler-Straße 1a, Zeitz, ST, Deutschland. "
    var testBody = {


      "model": "llama3.1:8b",
      "messages": [
        {
          "role": "user",
          "content": "Du bist ein Chatbot und deine Aufgabe ist es eine Anfrage die ein user in Textform schreibt in einen API Request zu übersetzen. Wir brauchen folgende Informationen:Einer oder mehrere Typen, das ist die Art einer medizinischen Einrichtung Modus: das ist entweder Fahrrad, Auto oder zu Fuß  Dann brauchen wir Startorte, die bestehen aus einer Adresse Dann müssen wir wissen ob wir alle Einrichtungen innerhalb einer bestimmten Zeit suchen oder ob wir anhand der Luftlinie und einer Distanz suchen. Das Json Format sieht zum Beispiel so aus {‘Gesuchter_Typ’: [‘Physiotherapie’,‘Allgemeinmedizin ],‘Mode’: ‘WALK’,'Position’: [{‘X’: 12.132333,‘Y’: 51.048965,‘Name’: ‘Marker_1’,‘Street_Name’: ‘Klosterkirchhof 2, Zeitz, ST, Deutschland’},],‘Radius’: 30,‘Type’: ‘Umkreissuche’,‘Filter’:’Time’}. Bitte ersetze X und Y mit der jeweiligen Latitude und longitude der adresse. Hier die Anfrage:" + search




        }


      ],
      "stream": false
    }

    var url = this.baseApiUrl;


    var result = await firstValueFrom(this.http.post(url, testBody,  httpOptions ) );
    return result;



  }

}
