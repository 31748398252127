import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Chart } from 'chart.js/auto';
import MapLibreGL, { Popup } from 'maplibre-gl';
import { DatahubService } from 'src/app/service/datahubservice/datahubservice.component';
import { KeycloakOperationService } from 'src/app/service/keycloakService/keycloak-operation.service';
import { SimulationService } from 'src/app/service/simulationservice/simulation.service';
import { Storageservice } from 'src/app/service/storageservice-component/storageservice-component.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import { tileLayer, latLng } from 'leaflet';
import { RegioService } from 'src/app/service/regioservice/regioservice.component';
import L from 'leaflet';
//import * as maplibregl from 'maplibre-gl';

@Component({
  selector: 'app-data-doc',
  templateUrl: './data-doc.component.html',
  styleUrls: ['./data-doc.component.css']
})
export class DataDocComponent {
    

  options = {
    layers: [
      tileLayer('https://m.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '...' })
    ],
    zoom: 12,
    minZoom: 9,
    center: latLng(51.04962, 12.1369)
  };
  markerClusterGroup: any;
  layers: any;
  layerControl: any;
  selectedObject: any;

nextStep( url: string) {
  this.closeModal('dialog4');
  if(url == "/datahub2/docs"){
  window.location.reload();
  } else {


  this.router.navigateByUrl(url); 
  }

  



}
newBirthYear: any;
  sourceID: any = [];
  layerID: any = [];
  rentenAlter: any;
  renteMode: boolean = false;
  formData : any = 
{
  name: "",
  address: "",
  latlng: []
}

onMapReady(map: any) {
  // Do stuff with map
  this.map = map;
  this.markerClusterGroup = L.markerClusterGroup({
    disableClusteringAtZoom: 13
  }

  );
  this.setLayer()

}

async setLayer() {
/*
 
  if (!this.selectedObject) {
    alert("Bitte Landkreis auswählen!")
    return;
  }

  this.layerControl = L.control.layers().addTo(this.map);
  this.map.on('click', async (e: any) => {

    if(this.addMarkerMode) {

      await this.addMarkerWithMapRef(e.latlng.lat, e.latlng.lng, undefined, undefined);}
    }) 
    console.warn("sel", this.selectedObject);*/
  var resMul = [];
  var res = await this.regioService.getShapefileByIdAndLevel(this.selectedObject.Object_Key, 4);
  this.storageService.setCurrentShapes(res[0]);
  console.warn("RES", res);
  var res2 = await this.regioService.getShapefileByIdAndLevel(this.selectedObject.Object_Key, 3);
  this.storageService.setCurrentShapes(res2[0]);
  resMul.push(res)
  resMul.push(res2)

  resMul.forEach((res => {


  //@ts-ignore
  res[0].forEach((element: any) => {
    var l = L.geoJSON(element.geometry,
      {
        style: {
          fillColor: 'none',


          color: 'black'
        },
        onEachFeature: (f, l) => {
          var out: string[] = [];

          out.push("Name : " + element.Object_Nam + "<br>"

          );

          l.bindPopup("<span class='span0'> " + element.Object_Nam + " </span> ");
        }
      });

      l?.on('click', async (e: any) => {
   
   /*
        if(this.addMarkerMode) {
        var icon = L.icon({
          iconUrl: 'assets/loc.svg',
          iconSize: [30, 45], 
        });
        var newMarker = L.marker(e.latlng, { draggable: true, icon: icon });
        newMarker.addTo(this.map);     
        
        var markerObj = await this.storageService.addMarker(newMarker, e.latlng, '');
        this.markersObjRef[markerObj.name] = newMarker
  
  
        const el = document.createElement('div');
        el.innerHTML = "<h3> "+ markerObj.name + "</h3> <b>" + markerObj.address +"</b>";
        var buttonDiv = document.createElement('div');
        buttonDiv.innerHTML = "<button id='test' style='padding: 10px; margin: 3%; height: 10%;  letter-spacing: 3px; border: 3px solid grey; border-radius: 15px;background-color: white;' > Löschen </button>"
        el.appendChild(buttonDiv); 
  
  
      buttonDiv.addEventListener("click", (e) => { 
        this.storageService.deleteMarker(markerObj); 
        this.map.removeLayer(newMarker); 
  
      })
  
        newMarker.bindPopup(el).openPopup();
        newMarker.on('dragend', async (event) => {
    
          var updatedMarker = await this.storageService.updateMarker(markerObj.name, event.target.getLatLng())
          newMarker.bindPopup("<h3> "+ markerObj.name + "</h3> <b>" + updatedMarker.address +"</b>").openPopup();
        });
      } */
  
      
  
        
  
  
      } ) 


      
    this.layers.push(l);
    this.layerControl.addOverlay(l, element.Object_Nam) 
    var bounds = l.getBounds()
    var center = bounds.getCenter()
    this.map.setView(center, 9);
   
  })
}))

}
addMarker() {
throw new Error('Method not implemented.');
}
selectedAddressStr: any = '';
graphType: any = 'Anzahl Einrichtungen';
showStats() {
throw new Error('Method not implemented.');
}







newDocName: any;
selectedSichtbarkeit: any;

mockUser = [ {
  id: "862233ca-4af4-4391-8bd4-be7d26e4f202",
  firstname: "ahmed",
  lastname: "jab",
  email: 'a@jab.de',
  canRead: false,
  canWrite: false, 



}, {
id: "03b316c6-24ec-4c36-b23d-f6cbf8a7b180",
firstname: "Nico",
lastname: "Test",
email: 'nicorud@icloud.com',
canRead: false,
canWrite: false, 
}



]
selectedSichtbarkeit2: any;
  userID: any;
  metaScenData: any;
saveNewDoc() {
 
  var newDoc = {
    Name: this.newDocName,
    Typ: this.categoriesSelectedForNew, 
    Kategorie: "Gesundheit",
    Heimatgemeinde: "15084",
    einrichtungs_id: 999,
    geometry: {
      type: "Point",
      coordinates: this.selectedAddress.geometry.coordinates
    },
    birth: 1945
  }


  //add locally 
  this.allDocData[this.currentYear - 2024].einrichtung.push(newDoc);
  for (var i = this.currentYear + 1; i < 2035; i++) {this.allDocData[i - 2024].einrichtung.push(newDoc); }
 this.updateChartAndMap(); 
  this.closeModal('dialog')
}
categoriesSelectedForNew: any;
sortArray(array: any): any {
  var selectedItems    = array.filter((el: any) => { return this.selectedEinrichtung && el.einrichtungs_id == this.selectedEinrichtung.einrichtungs_id})
  //var toBeDeletedItems = array.filter((el: any) => { return el.markedForDel ==  true})
  //var deletedItems     = array.filter((el: any) => { return el.deleted ==  true})
  var normalItems      = array.filter((el: any) => { return !(this.selectedEinrichtung && el.einrichtungs_id == this.selectedEinrichtung.einrichtungs_id)})

  var sortedArray = selectedItems.concat(normalItems)

  return sortedArray;
}
  colorPalette = [
    "#228B22", // Forest Green
    "#32CD32", // Lime Green
    "#008000", // Green (pure green)
    "#556B2F", // Dark Olive Green
    "#2E8B57", // Sea Green
    "#98FB98", // Pale Green
    "#8FBC8F", // Dark Sea Green
    "#7FFF00"  // Chartreuse
  ];
  markedAll: boolean = false;
  docPopID: any;
modeSelected: any;
activeOnly: any = true;
inActiveOnly: any = true;
markAll(bool : boolean) {
  this.markedAll = bool;

  this.allDocData[this.currentYear - 2024].einrichtung.forEach((einrichtung: any) => {


    if(this.categoriesSelected.indexOf(einrichtung.Typ) > -1 && 
    ( einrichtung.Name.toLowerCase().includes(this.searchBox2.toLowerCase())  || this.searchBox2 == '' || einrichtung.Typ.toLowerCase().includes(this.searchBox2.toLowerCase()) 
     || einrichtung.Kategorie.toLowerCase().includes(this.searchBox2.toLowerCase()) || (this.currentYear - einrichtung.birth) == this.searchBox2)) {
      einrichtung.markedForDel = bool;
      if(einrichtung.deleted && bool == false) {
        einrichtung.deleted = false; 
      }
     }

    
  });
 
}


getFilteredPop(categoriesSelected: any) {
  var filteredPop: any[] = []
  this.allDocData[this.currentYear - 2024].einrichtung.forEach((einrichtung: any) => {
    if(categoriesSelected.indexOf(einrichtung.Typ) > -1 && 
    ( einrichtung.Name.toLowerCase().includes(this.searchBox2.toLowerCase())  || this.searchBox2 == '' || einrichtung.Typ.toLowerCase().includes(this.searchBox2.toLowerCase()) 
     || einrichtung.Kategorie.toLowerCase().includes(this.searchBox2.toLowerCase()) || (this.currentYear - einrichtung.birth) == this.searchBox2) ) {

    var age = this.currentYear - einrichtung.birth;
    if(age <= this.maxAge && age >= this.minAge) {
      if((this.activeOnly && !einrichtung.deleted) || (this.inActiveOnly && einrichtung.deleted) )
      filteredPop.push(einrichtung)
    }     
     }
  });
 return filteredPop; 


}

getTotalNumberForCategory(categoriesSelected: any, isDeleted: boolean) {
  var popDeleted = this.getFilteredPop(categoriesSelected).filter(el => { return el.deleted == true });
  var popActive = this.getFilteredPop(categoriesSelected).filter(el => { 
    if(!el.deleted){
      return true;
    }
    if(el.deleted == true) {
      return false; 
    }

      return true;
    
  
  });
    if(!isDeleted) {
      return popActive.length; 
    } else {
      return popDeleted.length; 
    }
     

}

checkForDeleteMode(){
  var h = this.allDocData[this.currentYear - 2024].einrichtung.filter((el: { markedForDel: any; }) => el.markedForDel == true)
  return h.length; 
}
searchBox2: any = '';
filterResult() {

}
  newDocCat : any; 

  idLayerCounter: number = 0;
  checkName: boolean = false;
  showDropdown: boolean = false;
  selectedAddress: any;
  filteredAdressList: any = [];
  adressList: any = [];
  adressListCor: any = [];
  meta: any; age: any;
  minAge: any = 0;
  maxAge: any = 100;
  popName: any = '';
  existingScenarios: any = [];
  searchExtended: any = false;
  searchBox: any = '';
  public currentYear: any = 2024;
  public map: any;
  public sachsenAnhaltShape: any;
  public burgenlandkreisShape: any;
  public chart: any;
  public lineChart: any;
  public barChart: any;
  einrichtungen: any = [];
  deleteMode: any = false;
  detailMode: any = true;
  selectedEinrichtung: any;
  allDocData: any = [];
  categories: any = [];
categoriesSelected: any;




  async focusOutFunction() {
    /*if(this.selectedAddress.length % 4 == 0 && this.selectedAddress.length > 3) {
    var hh = await this.datahubService.getCoordinatesForAdress(this.selectedAddress);
    console.warn(hh);
    }
    //@ts-ignore
    hh.features[0].geometry.coordinates*/
  }


  savePop() {
    //@ts-ignore
    document.getElementById('dialog2').showModal(); 
  }


  getColorForItem(item: any) {
    if(item == this.selectedEinrichtung) { return '#bfff80'; }
    if(item.markedForDel) {return '#ffb3b3' }
    if(item.deleted) { return 'grey' }
    return 'white'
  }



  async saveDoc() {
    console.log("META", this.metaScenData.name)

   var canRead: string[] = [];
   var canWrite: string[] = []

   this.mockUser.forEach(us => {
    if(us.canRead || us.canWrite) {
      canRead.push(us.id)
    }
    if(us.canWrite) {
      canWrite.push(us.id)
    }

   })


   //permission check:

   if(!this.popName) {
    alert("Name darf nicht leer sein!");
    return;
   }

   if(this.popName == this.metaScenData.name) {
    //we might to check if user has right to do that
    if( !this.metaScenData.canWrite || this.metaScenData.canWrite.indexOf(this.userID) == -1) {
      alert("Keine Rechte " +  this.metaScenData.name + " zu bearbeiten. Bitte anderen Namen wählen" );
      return
    } 
   }


    var data = {
      name: this.popName,
      createdBy: this.userID,  //UserID Nico - fix e61d7714-314e-451a-8e15-10a383010ae5'
      //user Ahmed "862233ca-4af4-4391-8bd4-be7d26e4f202"
      createdAt: Date.now(),
      access: this.selectedSichtbarkeit, 
      canRead: canRead,
      canWrite: canWrite,
      pop: this.allDocData

    }


    await this.datahubService.addDocData(data)
    //@ts-ignore
    document.getElementById('dialog2').close();
     //@ts-ignore
     document.getElementById('dialog4').showModal();
  }
  closeModal(id: string) {


    //@ts-ignore
    document.getElementById(id).close();
  }


  addNewDoc() {
 var docObj = { 
                Kategorie: "Gesundheit",
                Typ: this.newDocCat, 
                Name: this.newDocName,
                Heimatgemeinde: "150845052115", 
                einrichtungs_id: '', 
                birth: this.currentYear - parseInt(this.newBirthYear),
                added: true  }


    //this.allDocData[this.currentYear - 2024].einrichtung.push(docObj); 

    for (var i = this.currentYear; i < 2035; i++) {
      this.allDocData[i- 2024].einrichtung.push(docObj); 
    }
 
    this.closeModal('dialog')
  }
  getLogo(sTyp: any) {
    var icon = '';

    if(this.meta.length = 0) {
      return; 
    }
    this.meta.forEach((bereich: any) => {
      bereich.Typen.forEach((typ: any) => {
        if (typ.Typ == sTyp) {
          icon = typ.Icon;
        }
      })
    });
    return "../../../../assets/icon/" + icon;
  }

  addDoc() {
    //@ts-ignore
    document.getElementById("dialog").showModal();

  }

  checkDate(date: any): any {
    var dateParsed = new Date(date);
    var dateformatted = dateParsed.toLocaleString('en-GB', { timeZone: 'UTC' });
    var tempSearchBox = this.searchBox.replaceAll(".", "/");
    tempSearchBox = tempSearchBox.replaceAll("-", "/");
    return dateformatted.includes(tempSearchBox)
  }


  

  selectedCoordinatesLatLng: any;
  async getCoordinates() {

    this.checkName = false;
    this.showDropdown = false;
    if (this.selectedAddress.length % 4 == 0 && this.selectedAddress.length > 3) {

      var hh = await this.datahubService.getCoordinatesForAdress(this.selectedAddress);

      //@ts-ignore
      hh.features[0].geometry.coordinates

      //@ts-ignore
      if (hh.features.length > 0) {
        this.selectedCoordinatesLatLng = {
          //@ts-ignore
          lat: hh.features[0].geometry.coordinates[1],
          //@ts-ignore
          lng: hh.features[0].geometry.coordinates[0]
        }
      }
    }
    console.log(this.selectedCoordinatesLatLng)
    this.storageService.setSelectedMarker(this.selectedCoordinatesLatLng);
    //this.storageService.setStreetName(this.selectedAddress)
  }

 formatAdress(address : any) {
  var returnString = ''

  var streetName = address.properties.street + ' ' + address.properties.housenumber + ", ";
  var cityName   = address.properties.postalcode + ' ' +  address.properties.county + ' ' + address.properties.Region + + ", ";
  var country    = address.properties.country

  if(streetName) {
    returnString = streetName;
  }

  if(cityName) {
    returnString = returnString + cityName ;
  }

  returnString = returnString + country; 
  return returnString; 
 }

  async completeDrop() {
    this.filteredAdressList = [];
    this.showDropdown = true; 
    if(this.selectedAddressStr.length < 4) {
      return; 
    }
    var res = await this.datahubService.getAutocompleteList(this.selectedAddressStr);

    console.warn(res)
    //@ts-ignore
    this.filteredAdressList = res.features; 
  }
  saveFormData() {
  
    if(this.formData.latlng) {
     // this.reachComponent.addMarkerWithMapRef(undefined, undefined, this.formData.name, this.formData.address)
    //  this.formData.name = ''
     // this.formData.address = ''
      // this.formData.latlng = {}
    } else {
      alert("Addresse nicht gefunden!")
    }
    }
  async completeDropNew(pos: any, event: any) {

    if(event.key == 'Enter') {
      this.filteredAdressList = [];
      this.saveFormData();
      return; 
    }
  
  
    this.filteredAdressList = [];
    this.showDropdown = true; 
    if(pos.address.length < 4) {
      return; 
    }
    var res = await this.datahub.getAutocompleteList(pos.address);
    console.warn("res", res); 
    //@ts-ignore
    this.filteredAdressList = res.features; 
  }

  clickEinrichtung(einrichtung: any) {
    alert("click")
    this.selectedEinrichtung = einrichtung;
    
    this.map?.flyTo({
      center: einrichtung.geometry.coordinates,
      zoom: 16,
    })

   // this.updateMap();
  }

  activateSingleEinrichtung(selEinrichtung: any) { 
    // delete elements after that year also
    for (var i = this.currentYear; i < 2035; i++) {
      var elements = this.allDocData[i - 2024].einrichtung.filter((el: any) => { return el.Name == selEinrichtung.Name && el.Typ == selEinrichtung.Typ })
      if (elements.length > 0)
        elements[0].deleted = false;
    }
this.updateChartAndMap(); 
}

  deleteSingleEinrichtung(selEinrichtung: any) { 
        // delete elements after that year also
        for (var i = this.currentYear; i < 2035; i++) {
          var elements = this.allDocData[i - 2024].einrichtung.filter((el: any) => { return el.Name == selEinrichtung.Name && el.Typ == selEinrichtung.Typ })
          if (elements.length > 0)
            elements[0].deleted = true;
        }
    this.updateChartAndMap(); 
  }


  deleteMultipleEinrichtung() {
    this.allDocData[this.currentYear - 2024].einrichtung.forEach((element: any) => {
      if (element.markedForDel == true) {
        // delete elements after that year also
        for (var i = this.currentYear; i < 2035; i++) {
          var elements = this.allDocData[i - 2024].einrichtung.filter((el: any) => { return el.Name == element.Name && el.Typ == element.Typ })
          if (elements.length > 0)
            elements[0].deleted = true;
          elements[0].markedForDel = false;
        }
        element.deleted = true;
        element.markedForDel = false;
      }
    })

    this.updateChartAndMap(); 
  }
  activateEinrichtung() {
    this.selectedEinrichtung.deleted = false;  
    this.selectedEinrichtung = undefined;
  }




  async checkSteetName() {
    console.log('Überprüfen');
    if (this.checkName) {
      //@ts-ignore
      await this.storageService.setStreetName(this.reachComponent.newMarker?._latlng);
      console.log('Überprüfen');
      if (this.storageService.imagePath !== '') {
        console.log('Überprüfen 2');
        this.selectedAddress = await this.storageService.getStreetName();
        console.log(this.selectedAddress);

        if (this.selectedAddress !== undefined) {
          if (this.selectedAddress == '') {
            this.selectedAddress = 'Straßennamen nicht gefunden'
          }

        }
      }

    }

  }

  async chooseLocation() {
    this.checkName = true;
    this.selectedAddress = undefined
    //@ts-ignore
    document.getElementById("dialog").close();
    //this.reachComponent.addMarker();

  }

  fillList() {
    this.simulationsService.getAdress().subscribe((options: any) => {
      options.features.forEach((feature: any) => {
        const s = feature.properties?.street_name;
        const c = feature.geometry?.coordinates;
        this.adressListCor!.push({
          adress: s,
          coord: c
        });
        this.adressList.push(s);
      });
      this.adressList = Array.from(new Set(this.adressList));
    });
  }


  constructor(private simulationService: SimulationService,  private keyCloakService: KeycloakOperationService, private datahub: DatahubService, private router: Router,
    private regioService: RegioService,
    private datahubService: DatahubService, public storageService: Storageservice, private simulationsService: SimulationService, private route:ActivatedRoute,) { 
      this.selectedObject = this.storageService.getObject()
      this.setLayer();
    }

  async ngOnInit(): Promise<void> {

    
      this.keyCloakService.getUserProfile().then(async (data: any) => {
        this.userID = data.id;
      
    
    

    //UserID Nico - fix e61d7714-314e-451a-8e15-10a383010ae5'
      //user Ahmed "862233ca-4af4-4391-8bd4-be7d26e4f202"
    this.existingScenarios = await this.datahubService.getDocData(this.userID);


    this.fillList();

    this.route.params.subscribe((params:any) => { 
      if (Object.keys(params).length !== 0) {
        this.docPopID = params['id'];
      }



    })

      //@ts-ignore
      document.getElementById('dialog3').showModal();
    this.initMap();
     this.initSachsenAnhalt();
    this.initBurgenlandkreisShape();

  
    this.setPop(this.existingScenarios[0].pop); 

   

  });

  }


  setPop(pop: any){
    //this.currentYear = 2024;
    this.allDocData = pop;  
    var presentCategories:  any[] = []
    
    pop[this.currentYear - 2024].einrichtung.forEach((element: { Typ: any; }) => {
      if(presentCategories.indexOf(element.Typ) == -1) {
        presentCategories.push(element.Typ); 
      }
    });
    console.warn("p", presentCategories); 

    presentCategories.forEach( (el: any) => {


    } )
  

    console.warn("present", presentCategories)

    this.categories = presentCategories;
    this.categoriesSelected = presentCategories; 
    this.createChart();
    this.createLineChart();
    //this.addMarker();
  }




  updateMap() {


  var filteredData = this.getFilteredPop(this.categoriesSelected); 
   filteredData = filteredData.filter(el => el.deleted != true); 
  
   filteredData.forEach((element: any) => {
    this.sourceID.push('I_' + element.einrichtungs_id)
    try{
    this.map?.addSource('I_' + element.einrichtungs_id
      , {
        type: 'geojson',
        data: element.geometry,
      });
      this.layerID.push(`L-Einrichtung` + element.einrichtungs_id)
        this.map?.addLayer({
          id: `L-Einrichtung` + element.einrichtungs_id,
          type: 'circle',
          source: 'I_' + element.einrichtungs_id,
          paint: this.getStyleForLayer(element)

        });
    } catch(e) {

    }

    

     /* if (!element.deleted) {
        if (!this.map?.getSource('I_' + element.einrichtungs_id)) {
          this.sourceID.push('I_' + element.einrichtungs_id)

          this.map?.addSource('I_' + element.einrichtungs_id
            , {
              type: 'geojson',
              data: element.geometry,
            });

        }
        if (this.map?.getLayer(`L-Einrichtung` + element.einrichtungs_id)) {
          this.map?.removeLayer(`L-Einrichtung` + element.einrichtungs_id);
        }
        this.layerID.push(`L-Einrichtung` + element.einrichtungs_id)
        this.map?.addLayer({
          id: `L-Einrichtung` + element.einrichtungs_id,
          type: 'circle',
          source: 'I_' + element.einrichtungs_id,
          paint: this.getStyleForLayer(element)

        });

      }*///else {
        //this.map?.removeLayer(`L-Einrichtung` + element.einrichtungs_id);
//      }

      

      this.map.on("click", `L-Einrichtung` + element.einrichtungs_id, (e: { features: any[]; }) => {
          //this.updateMap();
          if(this.deleteMode) {
            element.markedForDel = true; 
          } else {
            this.clickEinrichtung(element)
            this.selectedEinrichtung = element;
          }       
      });


    });

  }

  goTo() {
    this.map.flyTo({
      center: [12.1500056, 51.0448898],
      zoom: 20,
      pitch: 150,
      bearing: -17.6,
    });

  }

  zoomBLK() {




    this.map.flyTo({
      center: [11.99000, 51.1448898],
      zoom: 9.8,
      pitch: 0,
      bearing: 0,
    });

  }


  ///////////////////////////////////////////////////////////initialisation////////////////////////////////////////////////////////////////////////////

  //morizonKey:eRJsnpLp5PIaYJVCxumc;
  //AhmedKey:Wy4Sp6OuZmREUvltAVFn;
  initMap() {
    this.map = new MapLibreGL.Map({
      container: 'map',
      style:
        'https://api.maptiler.com/maps/streets/style.json?key=eRJsnpLp5PIaYJVCxumc',
      center: [12.1506056, 51.9490063],
      zoom: 7,
      maxBounds: [
        [
          7.46328899335299,
          50.42654727434322
        ],
        [
          16.038967853182015,
          53.496028621118114
        ],
      ]
    });
  }

  initSachsenAnhalt() {
    this.simulationService
      .getSachsenAnhaltShape()
      .subscribe((sachsenAnhaltShape) => {
        this.sachsenAnhaltShape = sachsenAnhaltShape;
        //console.log(this.sachsenAnhaltShape.features);
      
        this.map.on('load', () => {
          this.map?.addSource(`SA-source1`, {
            type: 'geojson',
            data: this.sachsenAnhaltShape,
          });
     
          this.map?.addLayer({
            id: `SA-layer1`,
            type: 'line',
            source: `SA-source1`,
            layout: {},
            paint: {
              'line-color': '#1b8113',
              'line-opacity': 1,
              'line-width': 4,
            },
          });
        });
      });
  }

  initBurgenlandkreisShape() {

    this.simulationService
      .getBurgenlandkreisShape()
      .subscribe((burgenlandkreisShape) => {
        this.burgenlandkreisShape = burgenlandkreisShape;
        //console.log(this.burgenlandkreisShape.features);
        this.map.on('load', () => {
          this.map?.addSource(`B-source1`, {
            type: 'geojson',
            data: this.burgenlandkreisShape,
          });
          this.map?.addLayer({
            id: `B-layer1`,
            type: 'line',
            source: `B-source1`,
            layout: {},
            paint: {
              'line-color': 'black',
              'line-opacity': 1,
              'line-width': 2,
            },
          });
        });
      });
  
  }

  createChartsAndCalender() {
    const chartDisplay = document.getElementById('chartDisplay');
    const calender = document.getElementById('calenderContainer');
    const barDisplay = document.getElementById('barDisplay');

    //this.createChart();
    this.drag(chartDisplay);
    this.drag(calender);
    this.drag(barDisplay);
    //this.fillList(this.startDay, this.dayCounter);
  }

  drag(chartDisplay: any) {
    let offsetX: any,
      offsetY: any,
      isDragging = false;



    document.addEventListener('mousemove', (e) => {
      if (!isDragging) return;
      const newX = e.clientX - offsetX;
      const newY = e.clientY - offsetY;
      chartDisplay!.style.left = newX + 'px';
      chartDisplay!.style.top = newY + 'px';
    });

    document.addEventListener('mouseup', () => {
      isDragging = false;
      chartDisplay!.style.cursor = 'grab';
    });
  }

  createLineChart() {
    if (!this.allDocData[this.currentYear - 2024]) {return;}
    this.lineChart?.destroy(); 
    var lineObj : any = {}; 
    var lineArr : any[] = []; 
   
    if(this.graphType == 'Anzahl Einrichtungen') {

    this.categoriesSelected.forEach((cat: any) => {
      lineObj.label = cat; 
      var dataPerCat = [];

      for (var i = 0; i < this.allDocData.length; i++) {
        dataPerCat.push(this.allDocData[i].einrichtung.filter((el: any) => { return !el.deleted && el.Typ == cat }).length); 



      }
      lineObj.data = dataPerCat; 
      lineArr.push(lineObj)
      lineObj = {}
      dataPerCat = []     
    }); 
    
    } else {
   

    this.categoriesSelected.forEach((cat: any) => {
      lineObj.label = cat; 
      var dataPerCat = [];

      for (var i = 0; i < this.allDocData.length; i++) {
        var filteredCategoryData = this.allDocData[i].einrichtung.filter((el: any) => { return !el.deleted && el.Typ == cat }); 
        var sum = 0;
        var count = 0; 

        filteredCategoryData.forEach((el: any) => {sum = sum + (this.currentYear + i - el.birth); count = count + 1})

        var avgAge = (sum / count); 
        dataPerCat.push(parseFloat(avgAge.toFixed(1)))


      }
      lineObj.data = dataPerCat; 
      lineArr.push(lineObj)
      lineObj = {}
      dataPerCat = []     
    });

  }


    this.lineChart = new Chart('myLineChart', {
      type: 'line',
      
      data: {
        labels: [2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034],
        datasets: lineArr,
      },
      options: {
        plugins: {
            legend: {
                display: true,
                position: 'bottom'
                
            }
        }
    }
    });
  }

  updateLineChartData() {

    if (!this.allDocData[this.currentYear - 2024]) {return;}
      
    
    var lineObj : any = {}; 
    var lineArr : any[] = []; 
    if(!this.rentenAlter) {
      this.rentenAlter = 1000000; 
    }

    if(this.graphType == 'Anzahl Einrichtungen') {
    this.categoriesSelected.forEach((cat: any) => {
      lineObj.label = cat; 
      var dataPerCat = [];

      for (var i = 0; i < this.allDocData.length; i++) {
        dataPerCat.push(this.allDocData[i].einrichtung.filter((el: any) => { return !el.deleted && el.Typ == cat && ((this.rentenAlter - (this.currentYear + i - el.birth)) > 0)  }).length); 
      }
      lineObj.data = dataPerCat; 
      lineArr.push(lineObj)
      lineObj = {}
      dataPerCat = []


      
    }); 
  } else {


    this.categoriesSelected.forEach((cat: any) => {
      lineObj.label = cat; 
      var dataPerCat = [];

      for (var i = 0; i < this.allDocData.length; i++) {
        var filteredCategoryData = this.allDocData[i].einrichtung.filter((el: any) => { return !el.deleted && el.Typ == cat && ((this.rentenAlter - (this.currentYear + 1 - el.birth)) > 0) }); 
        var sum = 0;
        var count = 0; 
        filteredCategoryData.forEach((el: any) => {sum = sum + (this.currentYear + i - el.birth); count = count + 1})

        var avgAge = (sum / count); 
        dataPerCat.push(parseFloat(avgAge.toFixed(1)))



      }
      lineObj.data = dataPerCat; 
      lineArr.push(lineObj)
      lineObj = {}
      dataPerCat = []     
    });}

    if (this.lineChart) {
      this.lineChart.data.datasets =  lineArr;
      this.lineChart.update();
    }
  }

  createChartFix() {
    if (!this.allDocData[this.currentYear - 2024]) {return;}
    var filteredPop = this.getFilteredPop(this.categoriesSelected);
    var alter = 40;
    //xalter =this.docConfig.rentenAlter
    alert("hi")
    
    var under1     = filteredPop.filter((el: any) => { return (this.currentYear - el.birth) - alter <= 1 && !el.deleted});
    var under2    = filteredPop.filter((el: any) => { return (this.currentYear - el.birth) - alter  > 1 && (this.currentYear - el.birth) - alter  <= 2 && !el.deleted});
    var under3     = filteredPop.filter((el: any) => { return (this.currentYear - el.birth) - alter > 2  && (this.currentYear - el.birth) - alter  <= 3 && !el.deleted});
    var under4    = filteredPop.filter((el: any) => { return (this.currentYear - el.birth) - alter > 3  && (this.currentYear - el.birth) - alter  <= 4 && !el.deleted});
    var under5     = filteredPop.filter((el: any) => { return (this.currentYear - el.birth) - alter  > 4  && (this.currentYear - el.birth) - alter <= 5 && !el.deleted});
    var rest       = filteredPop.filter((el: any) => { return(this.currentYear - el.birth) - alter  > 5  &&  !el.deleted});
    var inRente    = filteredPop.filter((el: any) => { return (this.currentYear - el.birth) >= alter && !el.deleted}); 
    

    this.chart = new Chart('myChart', {
      type: 'pie',
      data: {
        datasets: [
          {
            data: [
              under1.length,
              under2.length,
              under3.length,
              under4.length,
              under5.length,
              rest.length,
              inRente.length

            ],
            backgroundColor: this.colorPalette,
          },
        ],
      },
    });
  }

  createChart() {
    if (!this.allDocData[this.currentYear - 2024]) {return;}
    var filteredPop = this.getFilteredPop(this.categoriesSelected);
    var under30     = filteredPop.filter((el: any) => { return (this.currentYear - el.birth) <= 30 && !el.deleted});
    var under35     = filteredPop.filter((el: any) => { return (this.currentYear - el.birth) > 30  && (this.currentYear - el.birth) <= 35 && !el.deleted});
    var under40     = filteredPop.filter((el: any) => { return (this.currentYear - el.birth) > 35  && (this.currentYear - el.birth) <= 40 && !el.deleted});
    var under45     = filteredPop.filter((el: any) => { return (this.currentYear - el.birth) > 40  && (this.currentYear - el.birth) <= 45 && !el.deleted});
    var under50     = filteredPop.filter((el: any) => { return (this.currentYear - el.birth) > 45  && (this.currentYear - el.birth) <= 50 && !el.deleted});
    var under55     = filteredPop.filter((el: any) => { return (this.currentYear - el.birth) > 50  && (this.currentYear - el.birth) <= 55 && !el.deleted});
    var under60     = filteredPop.filter((el: any) => { return (this.currentYear - el.birth) > 55  && (this.currentYear - el.birth) <= 60 && !el.deleted});
    var rest        = filteredPop.filter((el: any) => { return (this.currentYear - el.birth) > 60 && !el.deleted}); 
    

    this.chart = new Chart('myChart', {
      type: 'pie',
      data: {
        datasets: [
          {
            data: [
              under30.length,
              under35.length,
              under40.length,
              under45.length,
              under50.length,
              under55.length,
              under60.length,
              rest.length,

            ],
            backgroundColor: this.colorPalette,
          },
        ],
      },
    });
  }

  updateChartData() {
    var filteredPop = this.getFilteredPop(this.categoriesSelected);
    var inactive = []; 
    if(!this.renteMode) {
    var under30     = filteredPop.filter((el: any) => { return (this.currentYear - el.birth) <= 30 && !el.deleted});
    var under35     = filteredPop.filter((el: any) => { return (this.currentYear - el.birth) > 30 && !el.deleted && (this.currentYear - el.birth) <= 35});
    var under40     = filteredPop.filter((el: any) => { return (this.currentYear - el.birth) > 35  && !el.deleted&& (this.currentYear - el.birth) <= 40});
    var under45     = filteredPop.filter((el: any) => { return (this.currentYear - el.birth) > 40 && !el.deleted && (this.currentYear - el.birth) <= 45});
    var under50     = filteredPop.filter((el: any) => { return (this.currentYear - el.birth) > 45 && !el.deleted && (this.currentYear - el.birth) <= 50});
    var under55     = filteredPop.filter((el: any) => { return (this.currentYear - el.birth) > 50 && !el.deleted && (this.currentYear - el.birth) <= 55});
    var under60     = filteredPop.filter((el: any) => { return (this.currentYear - el.birth) > 55 && !el.deleted && (this.currentYear - el.birth) <= 60});
    var rest        = filteredPop.filter((el: any) => { return (this.currentYear - el.birth) > 60 && !el.deleted}); 
    }else {
      var inactive    = filteredPop.filter((el: any) => { return (this.rentenAlter - (this.currentYear - el.birth)) < 1 || el.deleted});
      var under30     = filteredPop.filter((el: any) => { return (this.rentenAlter - (this.currentYear - el.birth)) == 1 && !el.deleted});
      var under35     = filteredPop.filter((el: any) => { return (this.rentenAlter - (this.currentYear - el.birth)) > 1 && !el.deleted&& (this.rentenAlter - (this.currentYear - el.birth)) <=2});
      var under40     = filteredPop.filter((el: any) => { return (this.rentenAlter - (this.currentYear - el.birth)) > 2 && !el.deleted && (this.rentenAlter - (this.currentYear - el.birth)) <= 3});
      var under45     = filteredPop.filter((el: any) => { return (this.rentenAlter - (this.currentYear - el.birth))> 3 && !el.deleted && (this.rentenAlter - (this.currentYear - el.birth))<= 4});
      var under50     = filteredPop.filter((el: any) => { return (this.rentenAlter - (this.currentYear - el.birth))> 4 && !el.deleted && (this.rentenAlter - (this.currentYear - el.birth)) <= 5});
      var under55     = filteredPop.filter((el: any) => { return (this.rentenAlter - (this.currentYear - el.birth))> 5  && !el.deleted&& (this.rentenAlter - (this.currentYear - el.birth)) <= 6});
      var under60     = filteredPop.filter((el: any) => { return (this.rentenAlter - (this.currentYear - el.birth)) > 6 && !el.deleted&& (this.rentenAlter - (this.currentYear - el.birth)) <= 7} ) ;
      var rest        = filteredPop.filter((el: any) => { return (this.rentenAlter - (this.currentYear - el.birth)) > 7 && !el.deleted}); 
    }




    if (this.chart) {
      this.chart.data.datasets[0].data = [
        inactive.length,
        under30.length,
        under35.length,
        under40.length,
        under45.length,
        under50.length,
        under55.length,
        under60.length,
        rest.length,
      ];
      this.chart.update();
    }
  }



  toggleChart() {
    let chartDisplay = document.getElementById('chartDisplay');
    let pieChartContainer = document.getElementById('pieChartContainer');
    let pieChartDisplay = document.getElementById('pieChartDisplay');
    let legende = document.getElementById('legende');
    let pieChartTitle = document.getElementById('pieChartTitle');
    const icon = document.getElementById('collapseIcon');
    if (icon?.classList.contains('bi-arrows-angle-contract')) {
      icon?.classList.remove('bi-arrows-angle-contract');
      icon?.classList.add('bi-arrows-angle-expand');
      legende!.style.display = 'none';
      pieChartTitle!.style.display = 'none';
      chartDisplay!.style.transition = 'height 1s ease, width 1s ease';
      pieChartContainer!.style.transition = 'height 1s ease, width 1s ease';
      chartDisplay!.style.height = '40px';
      chartDisplay!.style.width = '80px';
      pieChartContainer!.style.height = '40px';
      pieChartContainer!.style.width = '80px';
      chartDisplay!.style.alignItems = 'flex-start';
      pieChartContainer!.style.justifyContent = 'flex-start';
    } else if (icon?.classList.contains('bi-arrows-angle-expand')) {
      icon?.classList.remove('bi-arrows-angle-expand');
      icon?.classList.add('bi-arrows-angle-contract');
      setTimeout(() => {
        legende!.style.display = 'flex';
        pieChartTitle!.style.display = 'flex';
        //pieChartDisplay!.style.alignItems = 'flex-start';
      }, 1000);
      chartDisplay!.style.transition = 'height 1s ease, width 1s ease';
      chartDisplay!.style.height = '310px';
      chartDisplay!.style.width = '550px';
      pieChartContainer!.style.transition = 'height 1s ease, width 1s ease';
      pieChartContainer!.style.height = '250px';
      pieChartContainer!.style.width = '250px';
    }
  }

  toggleBarChart() {
    let barDisplay = document.getElementById('barDisplay');
    let barchart = document.getElementById('myBarChart');
    const icon = document.getElementById('collapseIconBar');
    if (icon?.classList.contains('bi-arrows-angle-contract')) {
      icon?.classList.remove('bi-arrows-angle-contract');
      icon?.classList.add('bi-arrows-angle-expand');
      barDisplay!.style.transition = 'height 1s ease, width 1s ease';
      barDisplay!.style.height = '40px';
      barDisplay!.style.width = '80px';
      barchart!.style.display = 'none';
    } else if (icon?.classList.contains('bi-arrows-angle-expand')) {
      icon?.classList.remove('bi-arrows-angle-expand');
      icon?.classList.add('bi-arrows-angle-contract');
      barDisplay!.style.transition = 'height 1s ease, width 1s ease';
      barDisplay!.style.height = '250px';
      barDisplay!.style.width = '550px';
      barchart!.style.display = 'flex';
    }
  }


  toggleCalender() {
    let calender = document.getElementById('calenderContainer');
    let calenderDay = document.getElementById('calenderDay');
    let calenderNavigation = document.getElementById('calender-navigation');
    const icon = document.getElementById('collapseIconCalender');
    if (icon?.classList.contains('bi-arrows-angle-contract')) {
      icon?.classList.remove('bi-arrows-angle-contract');
      icon?.classList.add('bi-arrows-angle-expand');

      calenderNavigation!.style.display = 'none';
      calenderDay!.style.fontSize = '0';
      calenderDay!.style.transition = 'height 1s ease, width 1s ease';
      calenderDay!.style.height = '40px';
      calenderDay!.style.width = '20px';
      calender!.style.transition = 'height 1s ease, width 1s ease';
      calender!.style.height = '40px';
      calender!.style.width = '80px';
    } else if (icon?.classList.contains('bi-arrows-angle-expand')) {
      icon?.classList.remove('bi-arrows-angle-expand');
      icon?.classList.add('bi-arrows-angle-contract');
      setTimeout(() => {
        calenderNavigation!.style.display = 'flex';
        calenderDay!.style.fontSize = '44px';
      }, 1000);
      calenderDay!.style.transition = 'height 1s ease, width 1s ease';
      calenderDay!.style.height = '180px';
      calenderDay!.style.width = '100px';
      calender!.style.transition = 'height 1s ease, width 1s ease';
      calender!.style.height = '180px';
      calender!.style.width = '450px';
    }
  }


  getStyleForLayer(element: any) {

    var circleColor = this.colorPalette[0];
    var age = this.currentYear - element.birth;
    var breaks = 12; //100/8;
    var index = Math.floor(age/breaks); 
    var circleColor = this.colorPalette[index]

    if(this.selectedEinrichtung && element.einrichtungs_id == this.selectedEinrichtung.einrichtungs_id) {
      return {
        "circle-color": circleColor,
        "circle-stroke-width": 2,
        "circle-stroke-color": "red",
        "circle-radius": ["case", ["get", "cluster"], 10, 10] // 10 pixels for clusters, 5 pixels otherwise
      }
    }

    if (element.deleted || (this.rentenAlter - (this.currentYear - element.birth) == 0)) {
 
      return {
        "circle-color": "red",
        "circle-stroke-width": 1.5,
        "circle-stroke-color": "red",
        "circle-radius": ["case", ["get", "cluster"], 5, 5] // 10 pixels for clusters, 5 pixels otherwise
      }
    }
      return {
        "circle-color": circleColor,
        "circle-stroke-width": 1.5,
        "circle-stroke-color": "white",
        "circle-radius": ["case", ["get", "cluster"], 5, 5] // 10 pixels for clusters, 5 pixels otherwise
      }
  
  }

  //////////////////////////////////////////////////////////////////////select any day using slider//////////////////////////////////////////////////////////////////
  updateProgress(progress: any) {
    const rangeInput = document.getElementById(
      'customRange1'
    ) as HTMLInputElement;
    rangeInput.value = progress;
  }

  async updateNextDay(event: Event): Promise<void> {
    const target = event.target as HTMLInputElement;
    this.currentYear = parseInt(target.value);

    this.updateChartAndMap();
  }


  getColorForTicks(i: number) {



    if ((2024 + i) == this.currentYear ||  !this.allDocData[i]) {
      return 'green';
    }


    var deleted = this.allDocData[i].einrichtung.filter((el: { deleted: boolean; }) => { return el.deleted == true })
    if (deleted.length > 0) {
      return 'red';
    }


    return 'black';



  }



  ///////////////////////////////////////////////////////remove all layers and sources////////////////////////////////////////////////////////////////////
  async removeAllLayersAndSources() {
    const style = this.map.getStyle();
    if (!style || !style.layers) {
      return;
    }

    for (let i = 1; i <= this.idLayerCounter; i++) {
      const layerId = `persons-layer${i}`;
      if (this.map?.getLayer(layerId)) {
        this.map?.removeLayer(layerId);
      }

      const sourceName = `persons-source${i}`;
      if (this.map?.getSource(sourceName)) {
        this.map?.removeSource(sourceName);
      }

      const layerIdDay = `day-layer${i}`;
      if (this.map?.getLayer(layerIdDay)) {
        this.map?.removeLayer(layerIdDay);
      }

      const sourceNameDay = `day-source${i}`;
      if (this.map?.getSource(sourceNameDay)) {
        this.map?.removeSource(sourceNameDay);
      }
    }
  }
  setRentenEintrittsAlter(rentenAlter: number) {
    this.rentenAlter = rentenAlter;
    this.renteMode = true; 
  }
  setExistingScenario(scen: any) {
    this.initSachsenAnhalt();
    this.initBurgenlandkreisShape();
  
    this.metaScenData = scen; 
    this.popName = scen.name; 
    this.allDocData = scen.pop;
    var presentCategories: any[] = []; 

    scen.pop[this.currentYear - 2024].einrichtung.forEach((element: { Typ: any; }) => {
      if(presentCategories.indexOf(element.Typ) == -1) {
        presentCategories.push(element.Typ); 
      }
    });
    console.warn("p", presentCategories); 

    presentCategories.forEach( (el: any) => {


    } )
  

    this.categories = presentCategories;
    this.categoriesSelected = presentCategories; 
    this.updateChartAndMap(); 
    //this.createChartFix()
    this.zoomBLK(); 
    this.closeModal('dialog3')
  }


  updateChartAndMap() {
   

    this.clearMap();

  
    this.updateMap(); 
    this.updateChartData()
    this.updateLineChartData(); 

  }
  clearMap() {
    this.layerID.forEach((element: any) => {
      if (this.map?.getLayer(element) )
      this.map?.removeLayer(element);
    });
    this.sourceID.forEach((element: any) => {
      if (this.map?.getSource(element)) 
      this.map?.removeSource(element);
    });

    this.layerID =[];
    this.sourceID = []; 



  }
 


}