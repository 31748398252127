<div *ngIf="step==1" class="main">  
    <div>
        <h1> Schulentwicklungsplannung-Szenario</h1>
    </div>

    <div class="nav-btn">
        <button mat-fab extended color="primary" style="width: 22%;" (click)="goToParams()">
            <mat-icon>add</mat-icon>
            Neues Szenario erstellen 
        </button>

        <button mat-fab extended color="primary" style="width: 22%;">
            <mat-icon>search</mat-icon>
            Suche 
        </button>
    </div>

    <div class="scenarien">
        <div class="boxScenarien"> 
            <div class="scenario" *ngFor="let scenario of formerSelections">
                {{scenario}}
            </div>
        </div>
    </div>
    
</div>

<div *ngIf="step==2" class="main2">
    <div>
        <p>Wählen Sie eine Bevölkerung aus:</p>

            <mat-radio-group [(ngModel)]="selectedPopulation" class="flexColum" id="populationList">
                <mat-radio-button *ngFor="let pop of populations" [value]="pop">
                    {{ pop }}
                </mat-radio-button>
            </mat-radio-group>
        
    </div>

    <div>
        <p>Wählen Sie eine Schule aus:</p>
            <mat-radio-group [(ngModel)]="selectedSchool" class="flexColum" id="schoolList">
                <mat-radio-button *ngFor="let school of schools" [value]="school">
                    {{ school }}
                </mat-radio-button>
            </mat-radio-group>
    </div>

    <div>
        <p>Wählen Sie ein Jahr aus:</p>
        <mat-slider class="year-slider" [(ngModel)]="selectedYear" min="2024" max="2034" step="1" showTickMarks discrete>
            <input matSliderThumb>
        </mat-slider>
    </div>

    <button mat-fab extended color="primary" (click)="storeSelection()">zu karte</button>
</div>
