import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs/internal/Observable';
import { RadarService } from 'src/app/service/radarService/radar.service';
import { RegioService } from 'src/app/service/regioservice/regioservice.component';
import { Storageservice } from 'src/app/service/storageservice-component/storageservice-component.component';

@Component({
  selector: 'app-indikatorenauswahl',
  templateUrl: './indikatorenauswahl.component.html',
  styleUrls: ['./indikatorenauswahl.component.css']
})
export class IndikatorenauswahlComponent {
  @Input() regioMetaData: any; 
  filteredTopics!: any[];
  filteredIndi!: any[];
  topicFilter: string = '';
  indiFilter: string = '';
  showDropdown = false;
  showDropdown2 = false;

  selectedIndicators: any[] = [];
  disableUncheckedCheckboxes = false;
  combinedArray:any=[];
  yearsArray:any[]=[];
  @Input() selectedLevel: any; 
  
  filteredOptions: Observable<any[]> | undefined;
  myControl = new FormControl('');
  filteredOptions2: Observable<any[]> | undefined;
  myControl2 = new FormControl('');
  

  constructor(
    private storageService:Storageservice,
    private regioService: RegioService,
    private radarService: RadarService
  ){
    setTimeout(async () => {
      await this.startTopic();
      await this.radarService.saveIndicators(this.selectedIndicators);
    }, 1000);  
  }

  toggleIndicators(topic: any) {
    topic.showIndicators = !topic.showIndicators;
  }

  checkIndicators(topic: any) {
    topic.checked = topic.data.some((indi: any) => indi.checked);
  }

  async startTopic() {
    this.regioMetaData.forEach((element:any) => {
      element.data.forEach(async (item: any) => {
        if (item.checked===true) {
          await this.getYearsArray(item);
          this.selectedIndicators.push(item);
        }
      });
    });
    this.filteredTopics = [...this.regioMetaData];
    this.regioMetaData.forEach((element: any) => {
      element.data.forEach((item: any) => {
        item.visible = true;
      });
    });
    this.filteredIndi = [...this.regioMetaData];
  }

  filterTopics() {
    const filterValue = this.topicFilter.toLowerCase();
    this.filteredTopics = this.regioMetaData.filter((topic: any) =>topic.Bereich.toLowerCase().includes(filterValue));
  }

  filterIndi() {
    const filterValue = this.indiFilter.toLowerCase();
    this.filteredIndi.forEach((topic: any) => {
      topic.data.forEach((indi: any) => {
        if (indi.Name.toLowerCase().includes(filterValue)) indi.visible = true;
        else indi.visible = false;
      });
    });
    this.filteredTopics = this.filteredIndi.filter((topic: any) =>topic.data.some((indi: any) => indi.visible));
  }

  doNotShowDropdown() {
    setTimeout(() => {this.showDropdown = false;}, 300);
  }

  doNotShowDropdown2() {
    setTimeout(() => { this.showDropdown2 = false;}, 300);
  }

  async onIndicatorChange(indicator: any, isChecked: boolean) {
    if (isChecked) {
      if (!this.selectedIndicators.includes(indicator)) {
        await this.getYearsArray(indicator);
        this.selectedIndicators.push(indicator);
        await this.radarService.saveIndicators(this.selectedIndicators);
      }
    } else  this.selectedIndicators = this.selectedIndicators.filter(ind => ind !== indicator);
    


    if (this.selectedIndicators.length >= 5) this.disableUncheckedCheckboxes = true;
    else this.disableUncheckedCheckboxes = false;
  }

  async getYearsArray(indicator:any){
    var selObjKey = this.storageService.getObject().Object_Key;
    
    let body={
      oK: selObjKey,
      indi:indicator.Source + indicator.Tabellen_ID,
      Al:parseInt(this.selectedLevel)
    }
    this.regioService.getYearsIndi(body).then((result:any)=>{
      indicator.years=result[0].years            
    })
  }


}
