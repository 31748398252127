//imports
import { Component, OnInit } from '@angular/core';
import * as L from 'leaflet';
import { Feature } from './Model/Feature';
import { Geojson } from './Model/Geojson';
import { Properties } from './Model/Properties';
import 'leaflet-polylinedecorator';
import { StreetXpertService } from 'src/app/service/streetXpertService/street-xpert.service';
import { MatDialog } from '@angular/material/dialog';
import { CreateNetworkdialogComponent } from '../matsim/create-networkdialog/create-networkdialog.component';
import { ActivatedRoute } from '@angular/router';
import _ from 'lodash';


@Component({
  selector: 'app-infrastuktur',
  templateUrl: './infrastuktur.component.html',
  styleUrls: ['./infrastuktur.component.css',
  '../../../../../node_modules/bootstrap/dist/css/bootstrap.min.css'],
  //encapsulation: ViewEncapsulation.None
})
export class InfrastukturComponent implements OnInit{
  //Variablen Deklarieren
  private map:any;
  public geoJsonFeatureElement = new Feature();
  public geoJsonFeatureCollection = new Geojson();
  public openLayerSidebar = false;
  public openStreetSidebar = false;
  public street: any;
  public multiLineString: any;
  public previousLayer: any;
  public selectedLayer: any;
  public freespeed: any;
  public capacity: any;
  public streetLayer = new Feature();
  public findStreet: any = false;
  public resetLayer: any;
  public properties?: Properties = undefined;
  public streetOptions: any = [];
  public endPoint: any;
  public numberStreetName: any;
  public reversedFeature: any;
  public reversedLayer: any;
  public filterModesEnabled = false;
  public filterFreespeedEnabled = false;
  public filterCapacityEnabled = false;
  public modeOptions: any = [];
  public filteredModes: any;
  public filteredFreespeedMin: number = 0;
  public filteredFreespeedMax: number = 0;
  public filteredCapacityMin: any;
  public filteredCapacityMax: any;
  public isBoxVisible: boolean = false;
  public hoveredLayer: any;
  public decoratorHoveredLayer: any;
  public decoratorReversedLAyer: any;
  public Layer: any;
  public isBoxVisibleStreetFinder: boolean = false;
  streetLayerRed: any;
  isModificationBoxVisible: boolean = false;
  modifiedModes!: string;
  modifiedFreespeed!: number;
  modifiedCapacity!: number;
  filtredRemovedLayers:any[]=[];
  showSpinnerWhole=false
  uuid:any
  bounds:any;
  initialProperties: any;
  globalChanges: any = {
    propertyChanges: [],
    deletions: []
  };
  decoratorLayer: any;

  constructor(
    private streetXpert:StreetXpertService,
    public dialog: MatDialog,
    private route: ActivatedRoute
  ) {
    this.uuid = this.route.snapshot.paramMap.get('uuid');
    console.log('Scenario UUID:', this.uuid);
  }


 // Initialisierung der Karte
  ngOnInit(): void {
    this.initMap();
    this.initilisation();
  }

 // Initialisierungsfunktion
  initilisation() {
    this.streetXpert.getGeoJson(this.uuid).subscribe((geoJsonFeatureElement) => {
      this.geoJsonFeatureCollection = geoJsonFeatureElement as Geojson;
      console.log(this.geoJsonFeatureCollection);
      this.initialProperties = _.cloneDeep(this.geoJsonFeatureCollection);
      this.initGeoJsonLayer();
      this.selectList();
      this.fMSearch();
    });
  }

  // Function to track changes
  trackChanges() {
    const newProperties = this.geoJsonFeatureCollection;
    const changes = this.getChanges(this.initialProperties, newProperties);
    
    if (changes) {
      console.log('Changes detected:', changes);
      this.storeChanges(changes); 
    } else {
      console.log('No changes detected');
    }
  }

  storeChanges(changes: any) {
    const propertyChanges: any[] = [];
    const deletions: any[] = [];
    const changedFeatures: Map<string, { ids: Set<string>, propertiesChanges: Map<string, { oldValue: any, newValue: any }>, coordinates: { lat: number, lng: number } }> = new Map();
    const deletedFeatures: Map<string, { ids: Set<string>, coordinates: { lat: number, lng: number } }> = new Map(); // Track deleted IDs by street
    const propertiesToTrack = ['capacity', 'freespeed', 'modes'];
    const units:any = {
      capacity: 'vehicles/hour',
      freespeed: 'km/h',
      modes: '' 
    };
  
    // Track feature changes
    changes.features.newValue.forEach((featureChange: any) => {
      const featureId = featureChange.properties.id; // Use 'id' as the identifier property
      const street = featureChange.properties.Street;
      const lat = featureChange.geometry?.coordinates[0][1];
      const lng = featureChange.geometry?.coordinates[0][0];
  
      if (!changedFeatures.has(street)) {
        changedFeatures.set(street, { ids: new Set(), propertiesChanges: new Map(), coordinates: { lat, lng } });
      }
  
      propertiesToTrack.forEach(propertyName => {
        const oldValue = changes.features.oldValue.find((oldFeature: any) => oldFeature.properties.id === featureId)?.properties[propertyName];
        const newValue = featureChange.properties[propertyName];
        if (oldValue !== newValue) {
          changedFeatures.get(street)?.ids.add(featureId); 
          const changeKey = `${propertyName}_${oldValue}_${newValue}`;
          changedFeatures.get(street)?.propertiesChanges.set(changeKey, { oldValue, newValue });
        }
      });
    });
  
    // Track deletions
    changes.features.oldValue.forEach((oldFeature: any) => {
      const featureId = oldFeature.properties.id;
      const street = oldFeature.properties.Street;
      const lat = oldFeature.geometry?.coordinates[0][1];
      const lng = oldFeature.geometry?.coordinates[0][0];
  
      if (!changes.features.newValue.some((newFeature: any) => newFeature.properties.id === featureId)) {
        if (!deletedFeatures.has(street)) {
          deletedFeatures.set(street, { ids: new Set(), coordinates: { lat, lng } });
        }
        deletedFeatures.get(street)?.ids.add(featureId);
      }
    });
  
    // Store property changes
    changedFeatures.forEach((data, street) => {
      if (data.ids.size > 1) {
        // Store summary for streets with multiple IDs
        data.propertiesChanges.forEach((change, key) => {
          const [propertyName] = key.split('_');
          const unit = units[propertyName] ? ` ${units[propertyName]}` : '';
          propertyChanges.push({
            type: 'summary',
            street: street,
            propertyName: propertyName,
            oldValue: change.oldValue,
            newValue: change.newValue,
            coordinates: data.coordinates, 
            message: `Die ganze Straße ${street} hat sich geändert von ${propertyName} ${change.oldValue}${unit} auf ${change.newValue}${unit}`
          });
        });
      } else {
        // Store individual changes
        data.ids.forEach((featureId) => {
          propertiesToTrack.forEach(propertyName => {
            const change = Array.from(data.propertiesChanges.entries())
              .find(([key]) => key.startsWith(propertyName));
            if (change) {
              const [, oldValue, newValue] = change[0].split('_');
              const unit = units[propertyName] ? ` ${units[propertyName]}` : '';
              propertyChanges.push({
                type: 'individual',
                street: street,
                propertyName: propertyName,
                oldValue: oldValue,
                newValue: newValue,
                id:featureId,
                coordinates: data.coordinates, 
                message: `ein Teil von der Straße ${street} hat sich geändert: ${propertyName} von ${oldValue}${unit} auf ${newValue}${unit}`
              });
            }
          });
        });
      }
    });
  
    // Store deletions
    deletedFeatures.forEach((data, street) => {
      if (data.ids.size > 1) {
        deletions.push({
          type: 'summary',
          street: street,
          coordinates: data.coordinates,  // Add coordinates
          message: 'Die ganze Straße ' + street + ' ist blockiert'
        });
      } else {
        data.ids.forEach(() => {
          deletions.push({
            type: 'individual',
            street: street,
            coordinates: data.coordinates,  // Add coordinates
            message: 'Ein Teil der Straße ' + street + ' ist blockiert'
          });
        });
      }
    });
  
    // Update the global JSON object
    this.globalChanges = {
      propertyChanges: propertyChanges,
      deletions: deletions
    };
    console.log(this.globalChanges);
  }
  

    // Function to compare objects and return changes
    getChanges(oldObj: any, newObj: any): any {
      return _.reduce(oldObj, (result:any, value:any, key:any) => {
        if (_.isPlainObject(value)) {
          const diff = this.getChanges(value, newObj[key]);
          if (!_.isEmpty(diff)) {
            result[key] = diff;
          }
        } else if (!_.isEqual(value, newObj[key])) {
          result[key] = { oldValue: value, newValue: newObj[key] };
        }
        return result;
      }, {});
    }


 // Funktion zu Speicherung der Werte
  saveData() {

    this.geoJsonFeatureElement.properties = this.properties;
 
    this.streetXpert
      .postGeoJson(this.geoJsonFeatureCollection,this.uuid)
      .subscribe((result) => {
        console.log(result);
        this.trackChanges();
      });
  }

  //Funktion für Dropdownsuche
  selectList() {
    this.geoJsonFeatureCollection.features?.forEach((feature: any) => {
      const s = feature.properties?.Street;
      this.streetOptions.push(s);
    });
    this.streetOptions = Array.from(new Set(this.streetOptions));
  }
  

// network.xml erstellen im Beckend
  UpdateNetwork() {
    const dialogRef =this.dialog.open(CreateNetworkdialogComponent,{
      data:{uuid:this.uuid, globalChanges:this.globalChanges},
      width:'700px',
      height:'450px',
    });
    dialogRef.afterClosed().subscribe((result:any) => {
      if (result) {

      }
    });
  }

 // Sidebar schließen
  closeNav() {
    this.openLayerSidebar = false;
  }

  //zweite  Sidebar schließen
  closeStreetSidebar() {
    this.openStreetSidebar = false;
  }

  //Werte der Zweiten Siedebar schließen
  saveDataStreet() {
    this.geoJsonFeatureCollection.features?.forEach((feature: any) => {
      const lineStringString = JSON.stringify(feature.geometry?.coordinates);
      const multiLineStringCoordinates = JSON.stringify(
        this.streetLayer.geometry?.coordinates
      );
      if (multiLineStringCoordinates.includes(lineStringString)) {
        feature.properties.freespeed = this.freespeed;
        feature.properties.capacity = this.capacity;
      }
    });
    this.saveData();
  }

  // parameter freespeed speichern
  saveFreespeed() {
    this.geoJsonFeatureCollection.features?.forEach((feature: any) => {
      const lineStringString = JSON.stringify(feature.geometry?.coordinates);
      const multiLineStringCoordinates = JSON.stringify(
        this.streetLayer.geometry?.coordinates
      );
      if (multiLineStringCoordinates.includes(lineStringString)) {
        feature.properties.freespeed = this.freespeed;
      }
    });
    this.saveData();
  }

  //parameter capacity speichern
  saveCapacity() {
    this.geoJsonFeatureCollection.features?.forEach((feature: any) => {
      const lineStringString = JSON.stringify(feature.geometry?.coordinates);
      const multiLineStringCoordinates = JSON.stringify(
        this.streetLayer.geometry?.coordinates
      );
      if (multiLineStringCoordinates.includes(lineStringString)) {
        feature.properties.capacity = this.capacity;
      }
    });
    this.saveData();
  }

 //StreetFinder reseten
  reset() {
    this.findStreet = false;
    this.map.removeLayer(this.resetLayer);
  }

  // Zweiten GeoJSON Layer erstellen
  OnKeyUp() {
    this.findStreet = true;
    this.streetXpert
    //Service verwenden um multi.json von Beckend in der Karte darzustellen
      .getMultiLineString(this.street,this.uuid)
      .subscribe((multiLineString) => {
        this.multiLineString = multiLineString;
        //gefundener Straßen als Objekte Darstellen
        const filtredStreetLayer = L.geoJSON(this.multiLineString, {
          // gefundene Straßen Stylen
          style: (feature) => ({
            weight: 30,
            opacity: 1,
            color: '#FF0000',
          }),
          // Events für die Straßen implimentieren
          onEachFeature: (feature, layer) => {
            // Ein Click event zu öffnen der Sidebar
            layer.on('click', (e: any) => {
              this.closeNav();
              this.openStreetSidebar = true;
              // Objkte in den Input felder verknüpfen
              this.streetLayer = feature as Feature;
              this.streetLayerRed = layer;
            }),
            // mouseover und mouseout events
              layer.on('mouseover', (e: any) => {
                this.highlightStreet(e);
              }),
              layer.on('mouseout', (e: any) => {
                this.resetFeatureStreet(e);
              });
          },
        });
        // Suchen nach neuen Straßen. Alte Ergebnisse löschen
        if (this.previousLayer) {
          this.map.removeLayer(this.previousLayer);
        }
        //Anzahl der gefundenen Ergebnisse
        this.numberStreetName = filtredStreetLayer.getLayers().length;
        //gefundene Straßen auf der Karte Darstellen
        this.map.addLayer(filtredStreetLayer);
        this.previousLayer = filtredStreetLayer;
        this.resetLayer = filtredStreetLayer;
        // gefundene Straßen als untere schicht platzieren
        filtredStreetLayer.bringToBack();
      //  this.map.flyTo([51.1548898,11.81000], 12, { duration: 1 });
      this.map.flyToBounds(this.bounds, { duration: 1 });
      });
      //name der Straße in der Console ausgeben lassen
    console.log(this.street);
  }

  // zu angecklickten straßen vergrößern
  zoomIn() {
    const lat = this.selectedLayer.feature.geometry?.coordinates[0][1];
    const lng = this.selectedLayer.feature.geometry?.coordinates[0][0];
    console.log(lat);
    this.map.flyTo([lat, lng], 18, { duration: 1 });
  }

  // ganzes Straßennetz beobachten
  zoomOut() {
    this.map.flyToBounds(this.bounds, { duration: 1 });
    //this.map.flyTo([51.533889, 9.935556], 13, { duration: 1 });
  }

  // löschen der angecklickten Straßen
  delete() {
    //if (confirm("Are you sure you want to remove the selected layer?")) {
    this.map.removeLayer(this.selectedLayer);

    if (this.selectedLayer == this.hoveredLayer) {
      this.decoratorHoveredLayer.removeFrom(this.map);
    } else if (this.selectedLayer == this.reversedLayer) {
      this.decoratorReversedLAyer.removeFrom(this.map);
    }

    this.geoJsonFeatureCollection.features?.splice(
      this.geoJsonFeatureCollection.features?.indexOf(
        this.geoJsonFeatureElement
      ),
      1
    );
    this.closeNav();
    this.saveData();
  }

  //löschen der gesuchten Straßen
  deleteRedLayer() {
    this.Layer.eachLayer((layer: any) => {
      const lineStringString = JSON.stringify(
        layer.feature.geometry?.coordinates
      );
      const multiLineStringCoordinates = JSON.stringify(
        this.streetLayer.geometry?.coordinates
      );

      if (multiLineStringCoordinates.includes(lineStringString)) {
        this.map.removeLayer(this.streetLayerRed);
        this.map.removeLayer(layer);
      }
    });

    this.geoJsonFeatureCollection.features =
      this.geoJsonFeatureCollection.features?.filter((feature: any) => {
        const lineStringString = JSON.stringify(feature.geometry?.coordinates);
        const multiLineStringCoordinates = JSON.stringify(
          this.streetLayer.geometry?.coordinates
        );
        return !multiLineStringCoordinates.includes(lineStringString);
      });
    this.saveData();
    this.closeStreetSidebar();
  }

  putLayerBack() {
    this.selectedLayer.bringToBack();
  }
  putLayerFront() {
    this.selectedLayer.bringToFront();
  }

  //OpenStreetMap Daten verwenden
  private initMap(): void {
    const tiles = L.tileLayer(
      'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      //'https://m.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png',
      {
        maxZoom: 18,
        minZoom: 3,
        attribution:
          '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      }
    );

    this.map = L.map('map', {
      center: [51.1548898,11.81000],
      zoom: 13,
    });

    tiles.addTo(this.map);
  }

  // Filter Funktionen
  resetModes() {
    this.map.removeLayer(this.Layer);
    this.initilisation();
  }

  filtredeModesSearch() {

    this.Layer.eachLayer((layer: any) => {
      const modes = layer.feature.properties.modes;
      const freespeedRange = Number(layer.feature.properties.freespeed);
      const capacity = Number(Math.floor(layer.feature.properties.capacity));

      if (
        (this.filterModesEnabled &&
          this.filteredModes !== 'All Modes' &&
          !modes.includes(this.filteredModes)) ||
        (this.filterFreespeedEnabled &&
          (freespeedRange < this.filteredFreespeedMin ||
            freespeedRange > this.filteredFreespeedMax)) ||
        (this.filterCapacityEnabled &&
          (capacity < this.filteredCapacityMin ||
            capacity > this.filteredCapacityMax))
      ) {
        this.map.removeLayer(layer);
        // this.decoratorReversedLAyer.removeFrom(this.map);
        // this.decoratorHoveredLayer.removeFrom(this.map);
      } else {
        //this.map.addLayer(layer);
      }
    });
  }

  filteredFreespeedSearch() {
    this.Layer.eachLayer((layer: any) => {
      const modes = layer.feature.properties.modes;
      const freespeedRange = Number(layer.feature.properties.freespeed);
      const capacity = Number(Math.floor(layer.feature.properties.capacity));

      if (
        (this.filterModesEnabled &&
          this.filteredModes !== 'All Modes' &&
          !modes.includes(this.filteredModes)) ||
        (this.filterFreespeedEnabled &&
          (freespeedRange < this.filteredFreespeedMin ||
            freespeedRange > this.filteredFreespeedMax)) ||
        (this.filterCapacityEnabled &&
          (capacity < this.filteredCapacityMin ||
            capacity > this.filteredCapacityMax))
      ) {
        this.map.removeLayer(layer);
        // this.decoratorReversedLAyer.removeFrom(this.map);
        // this.decoratorHoveredLayer.removeFrom(this.map);
      } else {
        //this.map.addLayer(layer);
      }
    });
  }

  filteredCapacitySearch() {
    this.Layer.eachLayer((layer: any) => {
      const modes = layer.feature.properties.modes;
      const freespeedRange = Number(layer.feature.properties.freespeed);
      const capacity = Number(Math.floor(layer.feature.properties.capacity));

      if (
        (this.filterModesEnabled &&
          this.filteredModes !== 'All Modes' &&
          !modes.includes(this.filteredModes)) ||
        (this.filterFreespeedEnabled &&
          (freespeedRange < this.filteredFreespeedMin ||
            freespeedRange > this.filteredFreespeedMax)) ||
        (this.filterCapacityEnabled &&
          (capacity < this.filteredCapacityMin ||
            capacity > this.filteredCapacityMax))
      ) {
        this.map.removeLayer(layer);
        // this.decoratorReversedLAyer.removeFrom(this.map);
        // this.decoratorHoveredLayer.removeFrom(this.map);
      } else {
        this.map.addLayer(layer);
      }
    });
  }

  fMSearch() {
    this.geoJsonFeatureCollection.features?.forEach((feature: any) => {
      const m = feature.properties?.modes;
      this.modeOptions.push(m);
    });
    this.modeOptions = Array.from(new Set(this.modeOptions));
    this.modeOptions.unshift('All Modes');
    this.filteredModes = 'All Modes';
    //console.log(this.modeOptions)
  }

  setAllModes() {
    this.filteredModes = 'All Modes';
    this.filtredeModesSearch();
  }

  // Funktionnen zu toggeln der filter box
  toggleBox() {
    this.isBoxVisible = !this.isBoxVisible;
    this.isModificationBoxVisible=false
  }

  toggleBoxStreetFinder() {
    this.isBoxVisibleStreetFinder = !this.isBoxVisibleStreetFinder;
  }

  toggleModificationBox() {
    this.isModificationBoxVisible=!this.isModificationBoxVisible
  }

  //Erweiterte Filter Funktionnen
  applyModifications(): void {
    this.Layer.eachLayer((layer: any) => {
      if (this.map.hasLayer(layer)) {
        layer.feature.properties.modes = this.modifiedModes;
        layer.feature.properties.freespeed = this.modifiedFreespeed;
        layer.feature.properties.capacity = this.modifiedCapacity;
      }
    })
    this.saveData()
  }

  applyModifiedModes(){
    this.Layer.eachLayer((layer: any) => {
      if (this.map.hasLayer(layer)) {
        layer.feature.properties.modes = this.modifiedModes;
      }
    })
    this.saveData()
  }

  applyModifiedCapacity(){
    this.Layer.eachLayer((layer: any) => {
      if (this.map.hasLayer(layer)) {
        layer.feature.properties.capacity = this.modifiedCapacity;
      }
    })
    this.saveData()
  }

  applyModifiedFreespeed(){
    this.Layer.eachLayer((layer: any) => {
      if (this.map.hasLayer(layer)) {
        layer.feature.properties.freespeed = this.modifiedFreespeed;
      }
    })
    this.saveData()
  }

  //löschen der gefilterten Ergebnisse
  deleteFiltred(){
    this.Layer.eachLayer((layer: any) => {
      if (this.map.hasLayer(layer)) {
        this.map.removeLayer(layer);
        this.filtredRemovedLayers.push(JSON.stringify(layer.feature))
      }
    })
    this.geoJsonFeatureCollection.features =
      this.geoJsonFeatureCollection.features?.filter((feature) => {
        const removedFeature=JSON.stringify(feature)
        return !this.filtredRemovedLayers.includes(removedFeature)
      });
    this.saveData()
  }
 
  // Funktion zu erstellung des Straßennetzwerks
  private initGeoJsonLayer() {
    this.Layer = L.geoJSON(this.geoJsonFeatureCollection as any, {
      pointToLayer: function (feature, latlng) {
        const geojsonMarkerOptions:any = {
          radius: 1,
          fillColor: "#ff7800",
          color: "#000",
          weight: 1,
          opacity: 1,
          fillOpacity: 0.8
      };
        return L.circleMarker(latlng, geojsonMarkerOptions)}
      ,
      onEachFeature: (feature, layer) => {
        layer.on('click', (e: any) => {
          //get the Features
          this.geoJsonFeatureElement = feature as Feature;
          this.properties = Object.assign({}, feature.properties as Properties);
          console.log(layer)
          //needed for delete and closing sidebars
          this.selectedLayer = layer;
          this.closeStreetSidebar();
          this.openLayerSidebar = true;
          // creating the reversedlayer and the arrowsheads
          const layerColor = (layer as any).options.color;

          if (layerColor == '#7FFF00' && this.hoveredLayer != layer) {
            this.resetSelectedAndReversedLayer();
            this.hoveredLayer = layer;
            this.hoveredLayer.setStyle({
              color: 'RoyalBlue',
              weight: 3,
            });
            //add an arrow
            this.decoratorHoveredLayer = L.polylineDecorator(
              this.hoveredLayer,
              {
                patterns: [
                  // defines a pattern of 10px-wide dashes, repeated every 20px on the line
                  {
                    offset: '0%',
                    repeat: '0',
                    symbol: L.Symbol.arrowHead({
                      pixelSize: 12,
                      polygon: false,
                      pathOptions: {
                        color: 'RoyalBlue',
                        weight: 3,
                        fillOpacity: 1,
                      },
                      headAngle: 270,
                    }),
                  },
                ],
              }
            ).addTo(this.map);

            this.reversedFeature = this.geoJsonFeatureCollection.features?.find(
              (feature) => {
                const coordinates =
                  this.geoJsonFeatureElement.geometry?.coordinates;
                const reversedCoordinates = feature.geometry?.coordinates
                  .slice()
                  .reverse();

                return (
                  JSON.stringify(coordinates) ===
                  JSON.stringify(reversedCoordinates)
                );
              }
            );
            //console.log(this.reversedFeature);
            //get the layer of the reversed element
            if (this.reversedFeature) {
              this.Layer?.eachLayer((layer: any) => {
                if (layer.feature === this.reversedFeature) {
                  this.reversedLayer = layer;
                  this.decoratorReversedLAyer = L.polylineDecorator(
                    this.reversedLayer,
                    {
                      patterns: [
                        // defines a pattern of 10px-wide dashes, repeated every 20px on the line
                        {
                          offset: '0%',
                          repeat: '0',
                          symbol: L.Symbol.arrowHead({
                            pixelSize: 12,
                            polygon: false,
                            pathOptions: {
                              color: 'DarkBlue',
                              weight: 3,
                              fillOpacity: 2,
                            },
                            headAngle: 270,
                          }),
                        },
                      ],
                    }
                  ).addTo(this.map);
                }
              });
              // style the layers
              this.reversedLayer.setStyle({
                color: 'DarkBlue',
                weight: 3,
              });
              this.hoveredLayer.setStyle({
                color: 'RoyalBlue', // Changing the color to purple
                weight: 3, // Reducing the weight of the line
              });
              this.pushFeatureAside(
                this.hoveredLayer,
                0.0005,
                0.0001,
                this.decoratorHoveredLayer
              );
              this.pushFeatureAside(
                this.reversedLayer,
                -0.0005,
                -0.0001,
                this.decoratorReversedLAyer
              );
            }
          }
        }),
          layer.on('mouseover', (e: any) => {
            this.highlightFeature(e);
          });
        layer.on('mouseout', (e: any) => {
          this.resetFeature(e);
        });
      },
    });
    this.map.addLayer(this.Layer);
    this.Layer.bringToFront();
    this.bounds = this.Layer.getBounds();
    this.map.fitBounds(this.bounds);
  }

  private resetSelectedAndReversedLayer() {
    // Reset previous hovereded layer
    if (this.hoveredLayer) {
      this.decoratorHoveredLayer.removeFrom(this.map);
      //this.hoveredLayer.deleteArrowheads(); must still comm
      this.hoveredLayer.setStyle({
        color: '#1e90ff',
        weight: 3,
      });
    }
    if (this.reversedFeature) {
      this.pushFeatureAside(
        this.hoveredLayer,
        -0.0005,
        -0.0001,
        this.decoratorHoveredLayer
      );

      // Reset previous reversed layer
      this.decoratorReversedLAyer.removeFrom(this.map);
      //this.reversedLayer.deleteArrowheads();
      this.reversedLayer.setStyle({
        color: '#1e90ff',
        weight: 3,
      });
      this.pushFeatureAside(
        this.reversedLayer,
        0.0005,
        0.0001,
        this.decoratorReversedLAyer
      );
    }
  }

  private highlightFeature(e:any) {
    const layer = e.target;
    if (
      (layer === this.reversedLayer || layer === this.hoveredLayer) &&
      this.reversedLayer
    ) {
      return;
    }
    layer.setStyle({
      color: '#7FFF00',
      weight: 3,
    });
  }

  private pushFeatureAside(l:any, offsetX:any, offsetY:any, decorator:any) {
    const layer = l;
    // Get the original array of LatLngs
    const originalLatLngs = layer.getLatLngs();
    const pushedLatLngs = originalLatLngs.map((latlng:any, index:any) => {
      // Adjust the value to push the feature aside
      const pushedLatLng = new L.LatLng(
        latlng.lat + offsetY,
        latlng.lng + offsetX
      );
      return pushedLatLng;
    });

    layer.setLatLngs(pushedLatLngs); // Update the layer with the pushed LatLngs
    decorator.setPaths(pushedLatLngs); //update the arrows
  }

  private highlightStreet(e:any) {
    const layer = e.target;

    layer.setStyle({
      color: '#7FFF00',
    });
  }
  private resetFeatureStreet(e:any) {
    const layer = e.target;

    layer.setStyle({
      weight: 30,
      opacity: 1,
      color: '#FF0000',
    });
  }

  private resetFeature(e:any): void {
    const layer = e.target;
    if (layer === this.reversedLayer || layer === this.hoveredLayer) {
      return;
    }
    //this.map.removeLayer(this.endPoint);

    layer.setStyle({
      color: '#1e90ff',
    });
  }

  ////////////////////////////////////////not used functions//////////////////////////////////////
  public ep: any;
  public ed: any;

  private addPointselected(lay:any) {
    const coordi = lay.getLatLngs()[0];

    this.ed = L.circleMarker(coordi, {
      color: 'black',
      fillColor: 'purple',
      fillOpacity: 1,
      radius: 5,
    });
    //this.endPoint.addTo(layer._map);
    this.map.addLayer(this.ed);
  }

  private addPointreversed(lay:any) {
    const coordi = lay.getLatLngs()[0];

    this.ep = L.circleMarker(coordi, {
      color: 'black',
      fillColor: 'purple',
      fillOpacity: 1,
      radius: 5,
    });
    //this.endPoint.addTo(layer._map);
    this.map.addLayer(this.ep);
  }

  private addEndPoint(e:any) {
    const layer = e.target;
    if (layer === this.reversedLayer || layer === this.hoveredLayer) {
      return;
    }
    const coordinates = layer.getLatLngs()[0];
    this.endPoint = L.circleMarker(coordinates, {
      color: 'black',
      fillColor: 'purple',
      fillOpacity: 1,
      radius: 5,
    });
    //this.endPoint.addTo(layer._map);
    this.map.addLayer(this.endPoint);
  }
}
