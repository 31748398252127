import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add-project-dialog',
  templateUrl: './add-project-dialog.component.html',
  styleUrls: ['./add-project-dialog.component.css']
})
export class AddProjectDialogComponent implements OnInit {
  projectName: string = '';
  userSearch: string = '';
  selectedUsers: any[] = [];
  filteredUsers: any[] = [];

  constructor(
    public dialogRef: MatDialogRef<AddProjectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { users: any[] },
  ) {}

  ngOnInit(): void {
    this.filteredUsers = this.data.users;
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    this.dialogRef.close({ projectName: this.projectName, users: this.selectedUsers });
  }

  toggleUserSelection(user: any, isSelected: boolean): void {
    if (isSelected) {
      this.selectedUsers.push(user);
    } else {
      this.selectedUsers = this.selectedUsers.filter(u => u.id !== user.id);
    }
  }

  filterUsers(): void {
    const filterValue = this.userSearch.toLowerCase();
    this.filteredUsers = this.data.users.filter(user =>
      `${user.firstName} ${user.lastName}`.toLowerCase().includes(filterValue)
    );
  }
}
