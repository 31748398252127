<div style="margin-top: 1%; margin-left: 5%; margin-right: 5%; margin-bottom: 1%;">
    <span class="span0"> ÄRZTEBEWEGUNGEN. </span> <span class="span1"> ENTWICKLUNGSPROGNOSEN </span> <br>
    <br>
    <br>


    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.1/font/bootstrap-icons.css" />

    <div class="map-container">
        <div class="map-frame">
            <!--<div id="map">-->
            <div class="map-container" style="height: 100%">
                <div class="map" id='map' leaflet [leafletOptions]="options" [leafletLayers]="layers"
                    (leafletMapReady)="onMapReady($event)"></div>
            </div>






            <div id="barDisplay" *ngIf="docPop && docPop.length > 0">

                <div id="buttonContainer" *ngIf="topContainerExtended">

                    <div
                        style="display: flex; align-items: center; flex-direction: row; width: 500px; justify-content: space-between;">
                        <div>
                            <mat-chip-option selected="true" [(ngModel)]="activeOnly"> aktiv ({{this.docPop.length - chart?.data?.datasets[0].data[0] }})
                            </mat-chip-option>

                            <mat-chip-option color="warn" [(ngModel)]="inActiveOnly" style='margin-left: 5px'
                                selected="true">
                                inaktiv  ({{chart?.data?.datasets[0].data[0] }})
                                  </mat-chip-option>

                   
                        </div>
                        <div>
                            <u *ngIf='checkForDeleteMode() > 0' (click)="deleteMultipleEinrichtung()"
                                style="margin-left: 5px;">
                                Auswahl ({{ checkForDeleteMode() }}) löschen </u>
                        </div>

                        <div>
                            <u style="margin-left: 5px;">
                                alle markieren </u>
                        </div>
                    </div>
                    <div>

                    </div>

                </div>


                <div id="slideBar">

                    <input id="search" [(ngModel)]="searchBox2" (keyup)="filterResult()" />


                </div>

                <div id="collapseBar" (click)="toggleBarChart()">
                    <i id="collapseIconBar" class="bi bi-arrows-angle-contract"></i>
                </div>


                <div id="collapseBar0" (click)="addDoc()">
                    <i id="collapseIconBar" class="bi bi-person-plus"></i>
                </div>


                <div id="collapseBar2"
                    (click)="selectedEinrichtung = undefined;   
                    this.map.removeLayer(this.selectedMarker);selectedEinrichtung.markedForDel = false; "
                    *ngIf="selectedEinrichtung">
                    <i id="collapseIconBar" class="bi bi-arrow-return-left"></i>
                </div>








                <div>



                    <div class="scrollable"
                        *ngIf="topContainerExtended && !selectedEinrichtung && docPop.length > 0">
                        <ng-container *ngFor="let einrichtung of filterActiveDoctorsByTypeAndYear(docPop, categoriesSelected, currentYear)">
                            <ng-container
                                *ngIf="
                        ( einrichtung.Name.toLowerCase().includes(searchBox2.toLowerCase())  || searchBox2 == '' || einrichtung.Typ.toLowerCase().includes(searchBox2.toLowerCase()) 
                         || einrichtung.Kategorie.toLowerCase().includes(searchBox2.toLowerCase()) )  ">

                    <!-- && (currentYear - einrichtung.birth) <= maxAge && (currentYear - einrichtung.birth) >= minAge  || (currentYear - einrichtung.birth) == searchBox2-->



                                <div class="list-item" [ngStyle]="{'background-color': getColorForItem(einrichtung) }">

                                    <div style="display: flex; align-items: center;">
                                        <mat-checkbox style="width:'70px'; height:'70px'"
                                            [(ngModel)]="einrichtung.markedForDel">
                                        </mat-checkbox>
                                    </div>

                                    <div *ngIf="!einrichtung.inactive" clickedEinrichtung
                                        (click)="clickEinrichtung(einrichtung.Name)"
                                        style=" display: flex; justify-content: start; align-items: center; width: 60%;">
                                        {{ einrichtung.Name }} <br>
                                        {{ einrichtung.Typ }} | {{ einrichtung.Kategorie }} 
                                   | {{ currentYear - einrichtung.birth }} Jahre 
                             
                                    </div>

                            


                                    <div>
                                        <mat-chip-option clickedEinrichtung disabled
                                            (click)="clickEinrichtung(einrichtung)"> {{
                                            einrichtung.Typ }} </mat-chip-option>
                                    </div>

                                </div>


                            </ng-container>
                        </ng-container>
                    </div>


                    <div class="scrollable" *ngIf="topContainerExtended && selectedEinrichtung">

                        <div class="list-item" [style]="{'bottom-border': 'none' }">
                            <div id="prop">
                                <input id="attrName" name="adress" value="{{selectedEinrichtung.plain.Name}}"        [(ngModel)]="newDocName" />
                                
                                <span *ngIf="renteMode && calculateRemainingTime(selectedEinrichtung.plain) > 0"> Status: Aktiv  
                                  <span *ngIf="calculateRemainingTime(selectedEinrichtung.plain) > 0 &&
                                    calculateRemainingTime(selectedEinrichtung.plain) != 10000 " >, für {{ calculateRemainingTime(selectedEinrichtung.plain) }}
                                        <span *ngIf = "calculateRemainingTime(selectedEinrichtung.plain) == 1 " > Jahr </span>
                                        <span *ngIf = "calculateRemainingTime(selectedEinrichtung.plain) > 1 "> Jahre  </span> </span> </span>

                                <span *ngIf="renteMode && calculateRemainingTime(selectedEinrichtung.plain) <= 0"> Status: Inaktiv </span>

                                <span *ngIf="!renteMode && selectedEinrichtung.plain.birth"> Alter: {{ currentYear - selectedEinrichtung.plain.birth }} 
                                    
                                    <span *ngIf="!renteMode && calculateRemainingTime(selectedEinrichtung.plain) > 0"> Status: Aktiv  </span>
                                        <span *ngIf="calculateRemainingTime(selectedEinrichtung.plain) <= 0" > Status: Inaktiv  </span>
                                </span>

                            </div>



                            <div>


                            </div>






                        </div>

                        <div
                            style="display: flex;  flex-direction: row; justify-content: space-between;     padding: 2%">
                            <div style="display: flex;  flex-direction: column; justify-content: flex-start;">

                                <div>

                                    <b style="  width: 170px;display: inline-block; margin-left: 10px;"> Adresse </b>
                                    <button mat-mini-fab aria-label="Example icon button with a home icon" *ngIf = "chooseLocationMode"
                                    style="background-color: #10684E; color: white" (click)="chooseLocation(); selectedAddress=undefined">

                               
                                    <mat-icon>my_location </mat-icon>
                                  </button>


                                  <button mat-mini-fab aria-label="Example icon button with a home icon" *ngIf = "!chooseLocationMode"
                                  style="background-color: white; color: #10684E" (click)="chooseLocation(); selectedAddress=undefined">
                                  <mat-icon>my_location </mat-icon>
                                </button>
                                <span  *ngIf = "chooseLocationMode"> Klicken Sie auf die Karte</span>
                                    <!--<input id="attr" name="adress" value="{{selectedEinrichtung.plain.Adresse }}"
                                        [(ngModel)]="newDocAdress" />-->


                                        <div id="inPutContainer">
                                            <div>
                                                <input id="attr" name="adress" style="width: 100%; margin-left: 20px" value="{{selectedEinrichtung.plain.Adresse }}"
                                                    [(ngModel)]="formData.address" placeholder="{{selectedEinrichtung.plain.Adresse }}"
                                                    (keyup)="completeDropNew(formData, $event);" />
                                            </div>
                                            <div *ngIf="filteredAdressList.length > 0"
                                                style="max-height: 100px; width: 100%; overflow-y: scroll; border-bottom-right-radius: 15px; border-bottom-left-radius: 15px; border: 1px solid #10684E; padding-left: 8px;border-top: none; ">
                                                <div *ngFor="let address of filteredAdressList"
                                                    (click)="formData.address = address.properties.label; filteredAdressList = []; formData.latlng = address.geometry.coordinates;saveFormData(); "
                                                    class="dropAd">
                                                    {{ address.properties.label }}
                                                </div>
                                            </div>
                                        </div>


                                </div>


                                <div *ngIf="selectedEinrichtung.plain.birth">

                                    <b style="  width: 170px;display: inline-block; margin-left: 10px;"> Alter </b>
                                    <input id="attr" name="adress"
                                        value="{{ currentYear - selectedEinrichtung.plain.birth }}"
                                        [(ngModel)]="newDocAge" />


                                </div>


                                <div>

                                    <b style="  width: 170px;display: inline-block; margin-left: 10px;"> Versorgungskapazität {{ currentYear }} </b>
                                    <input id="attr" name="adress" value="{{selectedEinrichtung.plain.capacity[2024-currentYear] }}"
                                        [(ngModel)]="newKapa" />


                                </div>

                                

                                <div>
                                    <br>
                                    <br>


 <b style="  width: 170px;display: inline-block; margin-left: 10px;"> Aktivität </b>
 <div style="display: flex; align-items: center; justify-content: center;">
    <mat-chip-listbox  [(ngModel)]="newActivity" multiple="true"> 

        <div *ngFor="let year of zeitRaum">
            <mat-chip-option [value]="year" [selected]="checkIfYear(year, selectedEinrichtung.plain.active)"> {{ year }} </mat-chip-option>
        </div>
    </mat-chip-listbox>

</div>
                                </div>


                                <br>
                                <div style="display: flex; justify-content: space-between;">

                                <button
                                *ngIf="(newDocAge != (currentYear - selectedEinrichtung.plain.birth) && selectedEinrichtung.plain.birth) ||(this.formData.address != selectedEinrichtung.plain.Adresse) ||
                                (newDocName != selectedEinrichtung.plain.Name) || newActivity != selectedEinrichtung.plain.active || newKapa != selectedEinrichtung.plain.capacity ; 
                                
                                "
                                    class="zoomBtn"
                                    (click)="updateDoc(selectedEinrichtung)">
                                    <i [ngClass]="'bi bi-floppy'"></i>
                            </button>
                          

                                <button class="zoomBtn" *ngIf="calculateRemainingTime(selectedEinrichtung.plain) > 0 "
                                    (click)="deactivateLocation(selectedEinrichtung.plain); update()">
                                    <i [ngClass]="'bi bi-trash'"></i>
                                </button>
                            </div>
      

                           
                            </div>
                        </div>
                    </div>
                </div>
            </div>

          <div id="chartDisplay2">
  
            <div id="collapse" (click)="toggleChart()">
              <i id="collapseIcon" class="bi bi-arrows-angle-contract"></i>
            </div>

            

            <div id="pieChartTitle">
                <mat-chip-listbox [(ngModel)]="pieType" multiple="false"  >
                    <mat-chip-option *ngIf="!renteMode" disabled (click)="updatePieChart('Alter')"> Altersstruktur für Einrichtungen </mat-chip-option>
                    <mat-chip-option *ngIf="renteMode"  disabled (click)="updatePieChart('Alter')"> Verbleibende aktive Jahre</mat-chip-option>

                   <!--<mat-chip-option style="margin: 5px;" (click)="updatePieChart('Kapazität')"> Kapazität </mat-chip-option>--> 
                  </mat-chip-listbox> 
            </div>
            
            <div class="pieChartDisplay">

                
              <div id="pieChartContainer" style="height: 250px">
                <h3 *ngIf="!this.pieChartValid"
                > Kein Chart 'Altersstruktur' für ausgewählte Einrichtungsarten verfügbar </h3> 
                <canvas id="myChart">{{ chart }}</canvas>

             
              </div>
              
  
              <div id="legende" *ngIf="renteMode" >
                <div> 
                    <p [ngStyle]="{'color': colorPalette[0] }"> Inaktiv ({{chart?.data?.datasets[0].data[0] }})</p>
                    <p [ngStyle]="{'color': colorPalette[1] }"> {{chart?.data?.labels[1]}} Jahre ({{chart?.data?.datasets[0].data[1] }})</p>
                    <p [ngStyle]="{'color': colorPalette[2] }"> {{chart?.data?.labels[2]}} Jahre ({{chart?.data?.datasets[0].data[2] }})</p>
                    <p [ngStyle]="{'color': colorPalette[3] }"> {{chart?.data?.labels[3]}} Jahre ({{chart?.data?.datasets[0].data[3] }})</p>
                    <p [ngStyle]="{'color': colorPalette[4] }"> {{chart?.data?.labels[4]}} Jahre ({{chart?.data?.datasets[0].data[4] }})</p>

  
                  </div>
                  <div style="margin-left: 5px; "> 
                    <p [ngStyle]="{'color': colorPalette[5] }"> {{chart?.data?.labels[5]}} Jahre ({{chart?.data?.datasets[0].data[5] }})</p>
                    <p [ngStyle]="{'color': colorPalette[6] }"> {{chart?.data?.labels[6]}} Jahre ({{chart?.data?.datasets[0].data[6] }})</p>
                    <p [ngStyle]="{'color': colorPalette[7] }"> {{chart?.data?.labels[7]}} Jahre ({{chart?.data?.datasets[0].data[7] }})</p>
                    <p [ngStyle]="{'color': colorPalette[8] }"> > {{chart?.data?.labels[8]}} Jahre ({{chart?.data?.datasets[0].data[8] }})</p>
                    <p [ngStyle]="{'color': colorPalette[9] }"> > {{chart?.data?.labels[9]}} Jahre ({{chart?.data?.datasets[0].data[9] }})</p>

                  </div>

                  <!--<button button mat-raised-button color="primary"  style="margin: 5px;" (click)="openDialogBreaks()">Breaks</button>-->

              </div>

              <div id="legende" *ngIf="!renteMode && pieType != 'Kapazität'" >
                <div> 
                    <p *ngIf = "chart?.data?.datasets[0].data[0] > 0"[ngStyle]="{'color': reversedColorPalette[0] }"> {{chart?.data?.labels[0]}} Jahre  ({{chart?.data?.datasets[0].data[0] }})</p>
                    <p *ngIf = "chart?.data?.datasets[0].data[1] > 0"[ngStyle]="{'color': reversedColorPalette[1] }"> {{chart?.data?.labels[1]}} Jahre ({{chart?.data?.datasets[0].data[1] }})</p>
                    <p *ngIf = "chart?.data?.datasets[0].data[2] > 0"[ngStyle]="{'color': reversedColorPalette[2] }"> {{chart?.data?.labels[2]}} Jahre ({{chart?.data?.datasets[0].data[2] }})</p>
                    <p *ngIf = "chart?.data?.datasets[0].data[3] > 0"[ngStyle]="{'color': reversedColorPalette[3] }"> {{chart?.data?.labels[3]}} Jahre ({{chart?.data?.datasets[0].data[3] }})</p>
                    <p *ngIf = "chart?.data?.datasets[0].data[4] > 0"[ngStyle]="{'color': reversedColorPalette[4] }"> {{chart?.data?.labels[4]}} Jahre ({{chart?.data?.datasets[0].data[4] }})</p>
                    <p *ngIf = "chart?.data?.datasets[0].data[5] > 0"[ngStyle]="{'color': reversedColorPalette[5] }"> {{chart?.data?.labels[5]}} Jahre ({{chart?.data?.datasets[0].data[5] }})</p>
                    <p *ngIf = "chart?.data?.datasets[0].data[6] > 0"[ngStyle]="{'color': reversedColorPalette[6] }"> {{chart?.data?.labels[6]}} Jahre ({{chart?.data?.datasets[0].data[6] }})</p>
                    <p *ngIf = "chart?.data?.datasets[0].data[7] > 0"[ngStyle]="{'color': reversedColorPalette[7] }"> {{chart?.data?.labels[7]}} Jahre ({{chart?.data?.datasets[0].data[7] }})</p>
                    <p *ngIf = "chart?.data?.datasets[0].data[8] > 0"[ngStyle]="{'color': reversedColorPalette[8] }"> {{chart?.data?.labels[8]}} Jahre ({{chart?.data?.datasets[0].data[8] }})</p>
                    <p *ngIf = "chart?.data?.datasets[0].data[9] > 0"[ngStyle]="{'color': 'black' }"> > {{chart?.data?.labels[9]}}  ({{chart?.data?.datasets[0].data[9] }})</p>

                  </div>

                  <!--<button button mat-raised-button color="primary"  style="margin: 5px;" (click)="openDialogBreaks()">Breaks</button>-->

              </div>

              <div id="legende" *ngIf="!renteMode && pieType == 'Kapazität'" >
                <div> 
                    <p *ngIf = "chart?.data?.datasets[0].data[0] > 0"[ngStyle]="{'color': reversedColorPalette[0] }"> {{chart?.data?.labels[0]}}   ({{chart?.data?.datasets[0].data[0] }})</p>
                    <p *ngIf = "chart?.data?.datasets[0].data[1] > 0"[ngStyle]="{'color': reversedColorPalette[1] }"> {{chart?.data?.labels[1]}}  ({{chart?.data?.datasets[0].data[1] }})</p>
                    <p *ngIf = "chart?.data?.datasets[0].data[2] > 0"[ngStyle]="{'color': reversedColorPalette[2] }"> {{chart?.data?.labels[2]}}  ({{chart?.data?.datasets[0].data[2] }})</p>
                    <p *ngIf = "chart?.data?.datasets[0].data[3] > 0"[ngStyle]="{'color': reversedColorPalette[3] }"> {{chart?.data?.labels[3]}}  ({{chart?.data?.datasets[0].data[3] }})</p>
                    <p *ngIf = "chart?.data?.datasets[0].data[4] > 0"[ngStyle]="{'color': reversedColorPalette[4] }"> {{chart?.data?.labels[4]}}  ({{chart?.data?.datasets[0].data[4] }})</p>
                    <p *ngIf = "chart?.data?.datasets[0].data[5] > 0"[ngStyle]="{'color': reversedColorPalette[5] }"> {{chart?.data?.labels[5]}}  ({{chart?.data?.datasets[0].data[5] }})</p>
                    <p *ngIf = "chart?.data?.datasets[0].data[6] > 0"[ngStyle]="{'color': reversedColorPalette[6] }"> {{chart?.data?.labels[6]}}  ({{chart?.data?.datasets[0].data[6] }})</p>
                    <p *ngIf = "chart?.data?.datasets[0].data[7] > 0"[ngStyle]="{'color': reversedColorPalette[7] }"> {{chart?.data?.labels[7]}}  ({{chart?.data?.datasets[0].data[7] }})</p>
                    <p *ngIf = "chart?.data?.datasets[0].data[8] > 0"[ngStyle]="{'color': reversedColorPalette[8] }"> {{chart?.data?.labels[8]}}  ({{chart?.data?.datasets[0].data[8] }})</p>
                    <p *ngIf = "chart?.data?.datasets[0].data[9] > 0"[ngStyle]="{'color': 'black' }"> > {{chart?.data?.labels[9]}}  ({{chart?.data?.datasets[0].data[9] }})</p>

                  </div>

                  <!--<button button mat-raised-button color="primary"  style="margin: 5px;" (click)="openDialogBreaks()">Breaks</button>-->

              </div>



  
            </div>
          </div>
  
          <div id="chartDisplay3">

            
  
            <div id="collapse" (click)="toggleLineChart()">
              <i id="collapseIconLine" class="bi bi-arrows-angle-contract"></i>
            </div>
  
  
            <div class="lineChartDisplay" [hidden]="hideLineChart && hideBarChart">
              <div [hidden]="hideLineChart" id="lineChartContainer" >
                <canvas id="myLineChart" [hidden]="hideLineChart">{{ lineChart }}</canvas>

              </div>

              <div [hidden]="hideBarChart" id="lineChartContainer" >
                <canvas id="myBarChart" [hidden]="hideBarChart">{{ barChart }}</canvas>

              </div>
              <div style="display: flex; align-items: flex-start; justify-content: center;">
                <div [hidden]="hideLineChart && hideBarChart"> 
                <mat-chip-listbox [(ngModel)]="graphType" multiple="false"  >
              <mat-chip-option style="margin: 5px;" (click)="updateLineChartData()">  Anzahl Einrichtungen </mat-chip-option>
              <mat-chip-option style="margin: 5px;" (click)="updateLineChartData()"> Gesamtkapazität </mat-chip-option>
              <mat-chip-option style="margin: 5px;" (click)="updateLineChartData()">  Durchschnittskapazität </mat-chip-option>
              <mat-chip-option style="margin: 5px;" (click)="updateLineChartData()"> Durchschnittsalter </mat-chip-option>
          
            </mat-chip-listbox> 
        </div>
            </div>
            </div>
          </div>





            <div id="bottom-slide-container">



                <div id="calenderContainer" (click)="update(); ">
                    <div id="collapseCalender" (click)="toggleCalender()">
                        <i id="collapseIconCalender" class="bi bi-arrows-angle-contract"></i>
                    </div>
                    <div id="calenderDay">
                        <div>
                            {{ currentYear }}
                        </div>
                        <div style="   font-size: 14px;" *ngIf="isExtended">
                         <span *ngIf="rentenAlter">   Renteneintrittsalter: {{ rentenAlter }} </span>  <br>


                        von <input id="age-input"  value="1" min="1" max="100" [(ngModel)]="minAge" (change)="update()">  

                     <mat-icon (click) = "minAge = minAge+1" style="width: 20px;height: 20px; " >add</mat-icon> 
                     <mat-icon (click) = "minAge = minAge-1" style="width: 20px;height: 20px; " >remove</mat-icon> 


                         bis <input  id="age-input"  value="1" min="1" max="100" [(ngModel)]="maxAge" (change)="update()"> 

                         <mat-icon  (click) = "maxAge = maxAge+1"style="width: 20px;height: 20px; " >add</mat-icon> 
                         <mat-icon  (click) = "maxAge = maxAge-1" style="width: 20px;height: 20px; " >remove</mat-icon> 
                         <!--
                            Alter von <input id="age-input" type="number" value="1" min="1" max="100" [(ngModel)]="minAge" (change)="update()"> 
                            bis <input id="age-input" type="number" value="1" min="1" max="100" [(ngModel)]="maxAge" (change)="update()"> -->
                        </div>
                    </div>
                    <div id="calender-navigation" (click)="update(); ">
                        <div id="scrollable-cat">
                            <mat-chip-listbox [(ngModel)]="categoriesSelected" multiple="true">
                                <div *ngFor="let cat of categories">
                                    <mat-chip-option (click)="update(); "> {{ cat }} </mat-chip-option>
                                    <br>
                                </div>
                            </mat-chip-listbox>

                        </div>

                    </div>
                </div>


                <div id="playContainer">
                    <button class="zoomBtn" (click)="showStats()">
                        <i [ngClass]="'bi bi-graph-down'"></i>
                    </button>

                    <button class="zoomBtn">
                        <i (click)="zoomBLK()" style="font-size: 16px">BLK</i>
                    </button>



                    <div id="slide-container">

                        <input type="range" id="customRange1" value="1" min="2024" max="2034"
                            (change)="updateNextDay($event)" />

                        <div class="ticks">


                            <ng-container *ngFor="let e of [].constructor(11);
                    let i = index;">

                                <span class="tick" [ngStyle]="{'color': getColorForTicks(i) }"> {{ 2024 + i }} </span>
                            </ng-container>


                        </div>

                    </div>

                    <button class="zoomBtn" *ngIf="!mapDeletionMode">

                        <i (click)="activateDeleteMode()" [ngClass]=" 'bi bi-trash'"></i>
                    </button>

                    <button class="zoomBtn" *ngIf="mapDeletionMode" style="background-color: red;">

                        <i (click)="endDeleteMode()" [ngClass]=" 'bi bi-trash'"></i>
                    </button>

                    <button class="zoomBtn">


                        <i (click)="savePop()" [ngClass]=" 'bi bi-floppy-fill'"></i>
                    </button>

                </div>
                <!--</div>-->
            </div>
        </div>
    </div>

    <dialog id="dialog">
        <div style="width: 1200px; ">

<button style="margin-left:97%; background-color: #10684E; color: white" mat-mini-fab
aria-label="Example icon button with a home icon" (click)="closeDialog()">
<mat-icon>close </mat-icon>
</button>
            <h1> Neuen Arzt anlegen </h1>

            <b> Name </b>
            <input id="adress" name="adress" [(ngModel)]="newDocName" />
            <br>
            <br>
            <b> Alter </b>
            <input id="adress" name="adress" [(ngModel)]="newDocAge" />
            <br>
            <br>
            <br>
            <b> Kapazität</b>
            <input id="adress" name="adress" [(ngModel)]="newDocKapa" />
            <br>
            <br>
            <br>
            <b> Fachrichtung </b>
            <mat-chip-listbox [(ngModel)]="newDocCat" multiple="false">
                <mat-chip-option *ngFor="let cat of categories"> {{ cat }} </mat-chip-option>

            </mat-chip-listbox>



            <br>
            <br>


            <b> Standort </b>
            <br>
            <br>


            <b style="margin-left: 3%"> Geben Sie eine beliebige Adresse ein </b>

            <div id="container">
                <div id="inPutContainer">
                    <div>
                        <input id="adress" name="adress" style="width: 100%; margin-left: 20px"
                            [(ngModel)]="formData.address" placeholder="Adresse..."
                            (keyup)="completeDropNew(formData, $event);" />
                    </div>
                    <div *ngIf="filteredAdressList.length > 0"
                        style="max-height: 100px; width: 100%; overflow-y: scroll; border-bottom-right-radius: 15px; border-bottom-left-radius: 15px; border: 1px solid #10684E; padding-left: 8px;border-top: none; ">
                        <div *ngFor="let address of filteredAdressList"
                            (click)="formData.address = address.properties.label; filteredAdressList = []; formData.latlng = address.geometry.coordinates;saveFormData(); "
                            class="dropAd">
                            {{ address.properties.label }}
                        </div>
                    </div>
                </div>
            </div> 

            <div style="display: flex; justify-content: center; flex-direction: row; ">
                <div>
                  <br>
                  <b>Punkt wählen </b>
          
          
          
                  <button mat-mini-fab aria-label="Example icon button with a home icon"
                    style="background-color: #10684E; color: white" (click)="chooseLocation(); selectedAddress=undefined">
                    <mat-icon>my_location </mat-icon>
                  </button>
                </div>
              </div>
            
            
            

            <div style="display: flex; justify-content: center; flex-direction: row; ">
                <div>
                    <br>

                    <br>




                    <div style=" display: flex; align-items: center; justify-content: center;">
                        <div style="width:50%">

                            <img *ngIf="storageService.imagePath != ''" id="image" [src]="storageService.imagePath"
                                width="100%" alt="Place image title" (load)="checkSteetName()">
                        </div>
                    </div>
                    <br>

                    <br>
                </div>
            </div>



            <button class="zoomBtn" (click)="addNewDoc()">
                <i [ngClass]="'bi bi-floppy-fill'"></i>
            </button>



        </div>
    </dialog>



</div>


<dialog id="deletePop">

   

   <h1> Hinweis </h1>
   <h3> Sie sind im Löschen Modus. Die Einrichtungen die Sie auf der Karte anklicken, werden gelöscht.</h3>
    <button class="zoomBtn" (click)="closeModal('deletePop');">
        ok
    </button>
</dialog>

<dialog id="dialog2">

    <h1> Arztebewegungsmodell speichern </h1>

    <b> Name </b>

    <input id="adress" name="adress" [(ngModel)]="popName" />



    <br> <br>

    <b> Sichtbarkeit </b>

    <mat-chip-listbox [(ngModel)]="selectedSichtbarkeit">

        <div *ngFor="let cat of ['public', 'protected', 'privat']">
            <mat-chip-option> {{ cat }} </mat-chip-option>
        </div>
    </mat-chip-listbox>


    <br>



    <div *ngIf="selectedSichtbarkeit == 'public'">
        Jeder kann bearbeiten und lesen
    </div>

    <div *ngIf="selectedSichtbarkeit == 'protected'">
        Sie können auswählen, wer welche Rechte besitzt:



        <mat-chip-listbox [multiple]="true">

            <div *ngFor="let user of mockUser" style="display: flex; flex-direction: column;">
                <div>
                    <mat-chip-option disabled> {{ user.firstname }} {{ user.lastname }} | {{user.email}}
                    </mat-chip-option>
                    <mat-chip-option [selected]="user.canRead"
                        (click)="user.canRead? user.canRead = false:  user.canRead = true">
                        darf lesen </mat-chip-option>
                    <mat-chip-option [selected]="user.canWrite "
                        (click)="user.canWrite? user.canWrite = false:  user.canWrite = true"> darf schreiben
                    </mat-chip-option>
                </div> <br>
            </div>
        </mat-chip-listbox>



    </div>
    <div *ngIf="selectedSichtbarkeit == 'privat'">
        Nur Sie sehen und bearbeiten {{ popName }}
    </div>


    <button class="zoomBtn" (click)="saveDoc()">
        <i [ngClass]="'bi bi-floppy-fill'"></i>
    </button>
</dialog>


<dialog id="dialog4">

    <h2> Szenario {{ popName }} wurde gespeichert und kann nun für weiterreichende Funktionalitäten verwendet werden.
        Wie möchten Sie fortfahren?</h2>
    <div style="display: flex; flex-direction: column; justify-content: space-around; align-items: center;">
        <button mat-fab extended color="primary" style="width: 50%; " (click)="nextStep('/analysen/reachability')">
            <mat-icon>east</mat-icon>
            Erreichbarkeitsanalysen </button> <br>
        <br>

        <button mat-fab extended color="primary" style="width: 50%; "
            (click)="nextStep('/prognosen/nachfrageberechnung')">
            <mat-icon>east</mat-icon>
            Nachfrageberechnung med. Infrastruktur </button>
        <br>
        <br>
        <button mat-fab extended color="primary" style="width: 50%; " (click)="nextStep('/datahub2/docs')">
            <mat-icon>south</mat-icon>
            Neues Ärzte-Szenario erstellen </button>
        <br>
        <br>
    </div>



</dialog>


<dialog id="dialog3">
    <doc-popup [docPopID]="docPopID"> </doc-popup>
</dialog>