import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StreetXpertService } from 'src/app/service/streetXpertService/street-xpert.service';


@Component({
  selector: 'app-scenario-list',
  templateUrl: './scenario-list.component.html',
  styleUrls: ['./scenario-list.component.css']
})
export class ScenarioListComponent implements OnInit {
  scenarios: any[] = [];
  selectedScenario: any = null;
  simulations: any[] = [];

  constructor(
              private streetXpertService: StreetXpertService,
              private router: Router) {}

  ngOnInit(): void {
    this.loadScenarios();
  }

  loadScenarios(): void {
    this.streetXpertService.getScenarios().subscribe((data: any) => {
      console.log(data);
        this.scenarios = data.scenarios; 
      });
  }

  selectScenario(scenario: any): void {
    this.selectedScenario = scenario;
    this.loadSimulations(scenario.uuid);
  }

  loadSimulations(scenarioUUID: string): void {
    this.streetXpertService.getSimulations(scenarioUUID).subscribe((data: any) => {
      console.log(data);
        this.simulations = data.simulations; 
      });
  }

  goToResult(simulationUUID: string): void {
    this.router.navigate(['/result-network', this.selectedScenario.uuid, simulationUUID]);
  }
}
