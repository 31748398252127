import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private apiUrl 

  constructor(private http:HttpClient,) {
    const useLocal = false;
    this.apiUrl = useLocal ? 'http://localhost:3001/dashboards' : 'https://dashboardservice.cephlabs.de/dashboards';
   }

  postDashboard(body:any){   
    return this.http.post(`${this.apiUrl}/postDashboard`,body)
  }

  getDashboards(userProfileId: any) {
    let url = `${this.apiUrl}/getDashboards?userProfileId=${userProfileId}`;
    return this.http.get(url);
  }

  getDashboard(id: string,version: number) {
    let url = `${this.apiUrl}/getDashboard/${id}/${version}`;
    return this.http.get(url)
  }

  updateCanSeeDashboard(ids: any,dashboardId:any) {
    const uuidArray: string[] = Array.from(ids);
    let url = `${this.apiUrl}/updateCanReadUsers`;
    return this.http.put(url, { uuidArray, dashboardId });
  }

  createNewDashboardVersion(body:any,collectionName:any) {
    return this.http.post(`${this.apiUrl}/postNewVersionDashboard`,{body, collectionName})
  }

  getVersions(collectionName: string){
    return this.http.post<number[]>(`${this.apiUrl}/getVersions`, { collectionName });
  }

  getDashboardHistory(collectionName: string) {
    return this.http.post(`${this.apiUrl}/getDashboardHistory`, { collectionName });
  }


}
