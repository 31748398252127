<div id="mapDialogYear">
    <div *ngIf="data.selectedChartIndex < 2">
    <h3 style="margin-bottom: 5px;">Wählen Sie ein Jahr</h3>
    <mat-slider min="{{ data.minYear }}" max="{{ data.maxYear }}" step="1" showTickMarks discrete  style="width: 400px;">
      <input matSliderThumb   value="{{ data.maxYear }}" [(ngModel)]="selectedMaxYearFromMap" >
    </mat-slider>
  </div>


<div *ngIf="data.selectedChartIndex >= 2">
  <h3 style="margin-bottom: 5px;">Wählen Sie ein Jahr</h3>
  <mat-slider min="{{ data.minYear }}" max="{{ data.maxYear }}" step="1" showTickMarks discrete  style="width: 400px;">
    <input matSliderStartThumb value="{{ data.minYear }}" [(ngModel)]="selectedMinYearFromMap">
    <input matSliderEndThumb value="{{ data.maxYear }}" [(ngModel)]="selectedMaxYearFromMap">
  </mat-slider>
</div>

  <div *ngIf="data.selectedChartIndex < 2">
    <h3 style="margin-bottom: 5px;">Wählen Sie den Wertebereich</h3>
        <mat-slider min="{{ data.minValue }}" max="{{ data.maxValue }}" discrete step="0.1"
      [displayWith]="formatLabel" style="width: 400px;" >
      <input matSliderStartThumb value="{{ data.minValue }}" [(ngModel)]="selectedMinValFromMap" >
      <input matSliderEndThumb value="{{ data.maxValue }}" [(ngModel)]="selectedMaxValFromMap" >
    </mat-slider>
  </div>

  
<div class="diaFooter">
  <button   (click)="closeDialogYear()" mat-raised-button color="primary">Anwenden</button>
  <button  (click)="closeAll()"  mat-raised-button color="warn" >schließen</button>
</div>
