<div id="mapDialogColor">
  
    <div>
      <h3 style="text-align: center;">Default</h3>
  
      <div  class="flexBox" style="width: 100%;">
        <div style="width: 100%;  display: flex;  justify-content: center;  align-items: center;
        gap: 20px;">
          <div *ngFor="let color of data.viridisCustom" [style.background]="color" class="color-block"></div>
        </div>
        <button  mat-raised-button  (click)="closePassDefault()">Default anwenden</button>
      </div>
    </div>
  
    <div class="flexBox" style=" gap: 20px;">
  
      <div style="background-color: #ccc;height: 1px; width: 400px;"></div>
      <div>Oder</div>
      <div style="background-color: #ccc;height: 1px;width: 400px;"></div>
  
    </div>
  
    <div style="width: 100%;">
          <h3 style="text-align: center;">Wählen Sie Ihr Farbschema aus</h3>
  
      <div  class="flexBox" style="width: 100%;">
        <div class="flexBox" style="flex-direction: column;gap:10px">
          <label for="favcolor1">Anfangsfarbe</label>
          <input id="favcolor1" type="color" [(ngModel)]="data.color1" >
        </div>
        <div class="flexBox" style="flex-direction: column;gap:10px">
          <label for="favcolor2">Endfarbe</label>
          <input id="favcolor2" type="color" [(ngModel)]="data.color2" >
        </div>
        <button  mat-raised-button  (click)="generateColorScheme()">Vorschau</button>
      </div>
  
      <div style="width: 100%;  display: flex;  justify-content: center;  align-items: center;
      gap: 20px; margin-top: 35px;">
        <div *ngFor="let color of data.colorScheme" [style.background]="color" class="color-block"></div>
      </div>
    </div>
  
      <div class="diaFooter">
        <button   mat-raised-button color="primary" (click)="drawAfternewColors()" >Neues Farbschema anwenden</button>
        <button  (click)="closeAll()"  mat-raised-button color="warn" >schließen</button>
      </div>
      
  </div>
