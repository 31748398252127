import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SynthService } from 'src/app/service/synthService/synth.service';

@Component({
  selector: 'app-gibiet-params-dialog',
  templateUrl: './gibiet-params-dialog.component.html',
  styleUrls: ['./gibiet-params-dialog.component.css']
})
export class GibietParamsDialogComponent implements OnInit {

  step = 1;
  populations: any;
  schools = ['Grundschule', 'Sekundarschule', 'BerufsbegleitendeSchule (BBS)'];
  formerSelections = [
    "Population A, Grundschule, 2024",
    "Population B, Sekundarschule, 2025",
    "Population C, BerufsbegleitendeSchule (BBS), 2026",
    "Population D, Grundschule, 2027",
    "Population E, Sekundarschule, 2028"
  ];
  
  selectedPopulation: string = '';
  selectedSchool: string = '';
  selectedYear: number = 2024;

  constructor(
    public dialogRef: MatDialogRef<GibietParamsDialogComponent>,
    private synthService: SynthService
  ) {}

  async ngOnInit(): Promise<void> {
    const res: any = await this.synthService.getPopuationNames();
    this.populations = res.popNames;
  }

  goToParams() {
    this.step = 2;
  }

  storeSelection() {
    const selectedData = {
      population: this.selectedPopulation,
      school: this.selectedSchool,
      year: this.selectedYear
    };
    console.log('Selected Data:', selectedData);
    this.dialogRef.close();
  }
}
