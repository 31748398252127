<div class="layout">
  <div class="header">
    <button mat-icon-button (click)="toggleDrawer()">
      <mat-icon>{{ drawer.opened ? "close" : "menu" }}</mat-icon>
    </button>
    <h3 id="DigiZwi" (click)="backLogin()">Digitaler Gesundheitszwilling</h3>
    <div class="img-container"  style="position: relative;" (mouseenter)="mouseEnter()" (mouseleave)="mouseLeave()">
      <div *ngIf="selectedObject && !selectedObject.Logo_URL && !routeCheck">
        <button mat-raised-button style="background-color: #d4e5c6; color: black" (click)="click('/start')"*ngIf="selectedObject">
          Landkreis festlegen
        </button>
      </div>

      <div *ngIf="selectedObject" >
        <img src="{{ selectedObject.Logo_URL }}" width="40px" />
      </div>

      <div *ngIf="selectedObject" class="landkreis" >
        {{ selectedObject.Object_Name }}

      </div>

      <button  style="position: absolute; top:0 ;right:0px" *ngIf="iconVisible" mat-mini-fab color="primary" aria-label="Example icon button with a filter list icon"  (click)="changeLandkreis()">
        <mat-icon>edit_location_alt</mat-icon>
      </button>

    </div>
  </div>

  <div style="display: flex;justify-content: center;align-items: center;gap: 10px;">
    <div style="display: flex;justify-content: center;align-items: center;flex-direction: column; margin-top: 0px;margin-right: 28px;padding-right: 20px;border-right: 1px solid #ccc;">
      <img  id="fixSA"src="https://upload.wikimedia.org/wikipedia/commons/5/53/Wappen_Sachsen-Anhalt.svg" width="40px"/>
      <h3 style="margin-top: 0px; font-weight:400 ; margin-block-start: 0em;margin-block-end: 0em;">Hallo {{ userName }}</h3>
    </div>
    <div>
      <a style="display: flex; justify-content: center; align-items: center" (click)="logout()" class="btn" >
        <mat-icon>logout</mat-icon> logout
      </a>
    </div>
  </div>
</div>
