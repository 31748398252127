import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
// @ts-ignore: Ignore TypeScript error for polar client import
import client from '@polar/client-generic';
import { StreetXpertService } from '../service/streetXpertService/street-xpert.service';

@Component({
  selector: 'app-polar',
  templateUrl: './polar.component.html',
  styleUrls: ['./polar.component.css']
})
export class PolarComponent implements OnInit {

  geoJsonFeatureCollectionOriginal:any
  @ViewChild('mapContainer', { static: true }) mapContainer!: ElementRef;
  map: any;

  constructor(    private streetXpert: StreetXpertService,) { }

  async ngOnInit(): Promise<void> {
    this.geoJsonFeatureCollectionOriginal= await this.streetXpert.getOriginalGeojsonWithHRS('b44c6cde-aef9-4efa-8124-4378c60f4e09', '74c4b857-09c3-4b82-9fa7-cb7fb0738e5c')
    console.log(this.geoJsonFeatureCollectionOriginal);
    
    await this.initializeMap();
  }

  async initializeMap(): Promise<void>  {
    const geoJsonArray = [this.geoJsonFeatureCollectionOriginal];
     this.map = await client.createMap({
      services: "../assets/testWFS.json",

      data:[],
      mapConfiguration: {
        epsg: "EPSG:25832",
        layers: [
          { id: "453", visibility: true, type: "background" },
          // { id: "1711", visibility: true, type: "mask" }
        ],
        stylePath: "../assets/polar-client.css",
        language: "en",
        center: {
          x: 392135.57,  // X coordinate in EPSG:25832 (for Berlin)
          y: 5815791.75, // Y coordinate in EPSG:25832 (for Berlin)
          zoom: 12        // Initial zoom level
        },
        // addressSearch: {
        //   displayComponent: true,
        //   addLoading: "plugin/loadingIndicator/addLoadingKey",
        //   removeLoading: "plugin/loadingIndicator/removeLoadingKey",
        //   searchMethods: [
        //     {
        //       queryParameters: {
        //         searchAddress: true,
        //         searchStreets: true,
        //         searchHouseNumbers: true
        //       },
        //       type: "mpapi",
        //       url: "https://www.geodatenportal.sachsen-anhalt.de/wss/service/ST_LVermGeo_DTK_WMS_OpenData/guest"
        //     }
        //   ],
        //   minLength: 3,
        //   waitMs: 300,
        //   focusAfterSearch: true
        // },
        // loadingIndicator: {
        //   displayComponent: true
        // },
        // pins: {
        //   coordinateSource: "plugin/addressSearch/chosenAddress",
        //   toZoomLevel: 7,
        //   movable: "drag"
        // },
        // reverseGeocoder: {
        //   url: "https://geodienste.hamburg.de/HH_WPS",
        //   addLoading: "plugin/loadingIndicator/addLoadingKey",
        //   removeLoading: "plugin/loadingIndicator/removeLoadingKey",
        //   zoomTo: 7,
        //   coordinateSource: "plugin/pins/transformedCoordinate",
        //   addressTarget: "plugin/addressSearch/selectResult"
        // },
        // draw: {
        //   selectableDrawModes: ["Circle", "LineString", "Point", "Polygon", "Text"],
        //   textStyle: { font: { size: [10, 20, 30], family: "Arial" } },
        //   style: {
        //     fill: { color: "rgba(255, 255, 255, 0.5)" },
        //     stroke: { color: "#e51313", width: 2 },
        //     circle: { radius: 7, fillColor: "#e51313" }
        //   },

        //   polygon: {
        //     showArea: true,  
        //     measurementUnit: "sq meters",
        //     precision: 2,
        //   }
        // },
        // export: {
        //   showPng: true,
        //   showJpg: false,
        //   showPdf: false
        // },
        // extendedMasterportalapiMarkers: {
        //   layers: ["1711"],
        //   defaultStyle: { stroke: "#FFFFFF", fill: "#005CA9" },
        //   hoverStyle: { stroke: "#46688E", fill: "#8BA1B8" },
        //   selectionStyle: { stroke: "#FFFFFF", fill: "#E10019" },
        //   clusterClickZoom: true,
        //   dispatchOnMapSelect: ["plugin/iconMenu/openMenuById", "gfi"]
        // },
        // locales: [
        //   {
        //     type: "en",
        //     resources: {
        //       plugins: {
        //         filter: {
        //           layerName: { "1711": "Hospital" },
        //           category: {
        //             "1711": {
        //               title: { "traegerschaft": "Sponsorship" },
        //               traegerschaft: {
        //                 privat: "Private",
        //                 öffentlich: "Public",
        //                 freigemeinnützig: "Non-profit"
        //               }
        //             }
        //           }
        //         }
        //       }
        //     }
        //   }
        // ],
        // filter: {
        //   layers: {
        //     "1711": {
        //       categories: [
        //         {
        //           selectAll: true,
        //           targetProperty: "traegerschaft",
        //           knownValues: ["privat", "öffentlich", "freigemeinnützig"]
        //         }
        //       ]
        //     }
        //   }
        // },
        // gfi: {
        //   mode: "bboxDot",
        //   activeLayerPath: "plugin/layerChooser/activeMaskIds",
        //   renderType: "iconMenu",
        //   featureList: {
        //     mode: "visible",
        //     pageLength: 3,
        //     text: ["name", "adresse"],
        //     bindWithCoreHoverSelect: true
        //   },
        //   layers: {
        //     "1711": {
        //       geometry: false,
        //       window: true,
        //       properties: { name: "Name", adresse: "Street", ort: "County" }
        //     }
        //   }
        // }
      },
      containerId: this.mapContainer.nativeElement.id,
      enabledPlugins: [
        // "address-search",
        // "loading-indicator",
        // "pins",
        // "reverse-geocoder",
        // "draw",
        // "export",
        // "icon-menu",
        // "toast",
        // "filter",
        // "gfi"
      ]
    });
      console.log('Map Component:', this.map);

      // Assuming the map component has methods to update the center
      if (this.map && this.map.setCenter) {
        this.map.setCenter({ 
          x: 392135.57,  // X coordinate for Berlin
          y: 5815791.75, // Y coordinate for Berlin
          zoom: 12        // Initial zoom level
        });
      }
  }

}
