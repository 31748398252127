<div class="layout">

  <div class="navbar">
    <app-navbar [drawer]="drawer"> </app-navbar>
  </div>

  <div class="content">

    <mat-drawer-container class="drawer-container" autosize>
      <mat-drawer #drawer class="drawer" mode="over" position="start">
          <app-sidenav [drawer]="drawer"></app-sidenav>
      </mat-drawer>
      <div class="content">
        <router-outlet>
          <notifier-container></notifier-container>
        </router-outlet>
      </div>
    </mat-drawer-container>
    


  </div>

  <div class="footer">

  </div>




</div>