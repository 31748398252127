<div style="display: flex; justify-content: space-around; border-bottom: 3px solid gray; padding-bottom: 1vh; ">

    <div class="left">

        <div style="display: flex; justify-content: center; align-items: center;">
            <div class="pad2" style= "border-right: none"  (click)= "popType  = 'DOC'"[style.background-color]="popType  == 'DOC' ? 'lightgray' : '#D4E5C6'"  >   Ärzteszenario  
            </div>
            <div class="pad2"  (click)="popType  = 'POP' "[style.background-color]="popType  == 'POP' ? 'lightgray' : '#D4E5C6'" > Populationen
            </div>
        </div>

        <canvas id="PopChart">
           
            {{ popChart }}
          </canvas>
    </div>

    <div class="right">

   <div style="display: flex;">
            <div class="pad2" style= "border-right: none"  [style.background-color]="chartType  == 'Absolute Nachfrage' ? 'lightgray' : '#D4E5C6'" (click)="createBarChart('Absolute Nachfrage')" >   Absolute Nachfrage   
            </div>
            <div class="pad2"  style=   "border-right: none" [style.background-color]="chartType  == 'Nachfrage je Einwohner' ? 'lightgray' : '#D4E5C6'"(click)="createBarChart('Nachfrage je Einwohner')" >Nachfrage/Einwohner
            </div>

            <div class="pad2"  style=   "border-right: none"  [style.background-color]="chartType  =='Nachfrage je lokale Einrichtung'? 'lightgray' : '#D4E5C6'"(click)="createBarChart('Nachfrage je lokale Einrichtung')" >Nachfrage/Einrichtung
            </div>

            <div class="pad2"   value="pop" [style.background-color]="chartType  == 'Einwohner je lokale Einrichtung' ? 'lightgray' : '#D4E5C6'"  (click)="createBarChart('Einwohner je lokale Einrichtung')" >Einwohner/Einrichtung
            </div>
            
   
        </div>

    

          <div style=" width: 40vw; height: 35vh; display: flex; align-items: center; justify-content: center;" > 

          <div  style="width: 100%; height: 100%" >
            <canvas id="IndiChart">
           
              {{ chart }}
            </canvas>
            </div>
          </div>

          







    </div>




</div>