<div class="folder-sticker">
    <span class="mainDatahub"> Simulation.Hitzesimulation.</span>
  </div>

<div style="display: flex; flex-direction: row;">

    <div style=" width: 75%; height: 100%;"  > 


<div id="map"></div>
</div>


<div id="action-container"> 
   <div> <h1> Urban Heat Island Simulator </h1> 
   </div>
   <div id="action-container2">

  <button mat-fab extended color="primary" style="width: 50%; " (click)="showShadow = !showShadow" *ngIf="!showShadow">
    <mat-icon> foggy       </mat-icon>
    Schatten simulieren
  </button> 

  <button mat-fab extended color="white" style="width: 50%; " (click)="showShadow = !showShadow"  *ngIf="showShadow">
    <mat-icon> foggy       </mat-icon>
    Schatten simulieren
  </button> 

  <div *ngIf="showShadow"> 
    <br> 
    <div>
        <label for="time">Uhrzeit:</label>
        <input id="time" type="range" min="0" max="23" [(ngModel)]="time" (change)="onTimeChange()" (input)="onTimeChange()" class="accent">
        <span>{{ timeRangeLabel }}</span>
      </div>
      
      <div>
        <label for="date">Datum:</label>
        <input  id="date" type="range" min="1" max="365" [(ngModel)]="date" (change)="onDateChange()" (input)="onDateChange()" class="accent">
        <span>{{ dateRangeLabel }}</span>
      </div>
    
    </div>
  
  
  
  <br>


  <button mat-fab extended color="primary" style="width: 50%; " (click) = "showShadow = false">
    <mat-icon>grass</mat-icon>
    Grünfläche hinzufügen
  </button> <br>


  <button mat-fab extended color="primary" style="width: 50%; " (click) = "showShadow = false">
    <mat-icon>
        nature
        </mat-icon>
    Bäume hinzufügen/entfernen
  </button> <br>
  <button mat-fab extended color="primary" style="width: 50%; " (click) = "showShadow = false">
    <mat-icon>     splitscreen_portrait </mat-icon>
    Oberflächenmaterial ändern
  </button> <br>

  <button mat-fab extended color="primary" style="width: 50%; " (click) = "showShadow = false">
    <mat-icon>location_city</mat-icon>
    Gebäude hinzufügen/entfernen
  </button> <br>

  <button mat-fab extended color="primary" style="width: 50%; " (click) = "showShadow = false">
    <mat-icon> device_thermostat </mat-icon>
    Temparaturparameter
  </button> <br>
  <br>

  <div>
    <br>
    <br>
  <button mat-fab extended color="primary" style="width: 100%; ">
    <mat-icon>start</mat-icon>
    Urbane Hitzesimulation starten
  </button> <br>

</div>

</div>
</div>
</div>

